import React, { Fragment, PureComponent } from "react";
import { wire } from "react-hot-wire";
import { MDBBtn } from "mdbreact";

import { QueueService } from "../../../services/queue/Queue.service";

import Spinner from "../../common/spinner/Spinner";
import { QueueStatus } from "../../../models/queue/QueueStatus";
import { JobsStatus } from "./JobsStatus";

export interface QueueStatusState {
  loading: boolean;
  queuesStatues: QueueStatus[];
}

export class QueueStatusInfo extends PureComponent<
  {
    queueService: QueueService;
  },
  QueueStatusState
> {
  public state: QueueStatusState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      queuesStatues: []
    };
  }

  componentDidMount(): void {
    this.fetchStatues();
  }

  public render() {
    return (
      <Fragment>
        <div className="mb-5">
          <h2>Queue</h2>
        </div>
        {this.state.loading ? (
          <div>
            <Spinner small />
          </div>
        ) : (
          <Fragment>
            {this.state.queuesStatues.map(queue => (
              <div key={queue.name}>
                <h4>Klucz: {queue.name}</h4>
                <div className="p-2">
                  <JobsStatus statues={queue} />
                </div>
                <div className="d-flex">
                  <MDBBtn
                    color="primary"
                    size="sm"
                    className="button"
                    onClick={() => this.empty(queue.name)}
                  >
                    Wyczyść kolejke
                  </MDBBtn>
                </div>
              </div>
            ))}
          </Fragment>
        )}
      </Fragment>
    );
  }

  private async fetchStatues() {
    this.setState({
      loading: true
    });

    const queuesStatues = await this.props.queueService.getAllQueuesStatus();

    this.setState({
      loading: false,
      queuesStatues
    });
  }

  private async empty(queueName: string) {
    this.setState({
      loading: true
    });

    await this.props.queueService.emptyQueue(queueName);

    this.fetchStatues();
  }
}
export default wire(["queueService"], QueueStatusInfo);
