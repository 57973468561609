import React, { CSSProperties, Fragment, PureComponent } from "react";
import * as Yup from "yup";
import { wire } from "react-hot-wire";

import { DeviceBrandService } from "../../../../services/device/brand/DeviceBrand.service";
import { PartnerService } from "../../../../services/partner/Partner.service";
import {
  NotificationMessageType,
  NotificationService
} from "../../../../services/application/Notification.service";

import SimpleForm, {
  SimpleFormProps
} from "../../../common/form/simple-form/SimpleForm";
import Spinner from "../../../common/spinner/Spinner";

import { Partner } from "../../../../models/partner/Partner";
import { CreateDeviceBrandDTO } from "models/device/brand/DeviceBrand";

export interface CreateDeviceBrandState {
  loading: boolean;
  partners: Partner[];
}

export interface CreateDeviceBrandFormInterface {
  name: string;
  systemMargin: string;
  [key: string]: string;
}

const partnerMarginsStyle: CSSProperties = {
  maxHeight: "200px",
  overflowX: "auto"
};

export class CreateDeviceBrand extends PureComponent<{
  deviceBrandService: DeviceBrandService;
  partnerService: PartnerService;
  notificationService: NotificationService;
  simpleFormProps: SimpleFormProps;
}> {
  public state: CreateDeviceBrandState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      partners: []
    };
  }

  public componentDidMount() {
    this.fetchPartners();
  }

  public render() {
    return (
      <SimpleForm
        title="Tworzenie marki"
        submitButton="Utwórz markę"
        submittingButton="Trwa tworzenie marki..."
        onSubmit={(values: CreateDeviceBrandFormInterface) =>
          this.props.deviceBrandService
            .create({
              name: values.name,
              systemMargin: Number(values.systemMargin),
              partnerMargins: this.state.partners
                .map(partner =>
                  values[`partner_${partner.id}`]
                    ? {
                        partnerId: partner.id,
                        margin: Number(values[`partner_${partner.id}`])
                      }
                    : null
                )
                .filter(_ => _)
            } as CreateDeviceBrandDTO)
            .then(() =>
              this.props.notificationService.notify({
                message: "Marka utworzona pomyślnie",
                type: NotificationMessageType.SUCCESS
              })
            )
            .catch((error: any) => {
              console.error(error);
              this.props.notificationService.notify({
                message: "Błąd przy tworzeniu marki",
                type: NotificationMessageType.ERROR
              });
            })
        }
        resetFields={["name"]}
        fields={[
          {
            type: "text",
            name: "name",
            label: "Nazwa marki",
            placeholder: "Wpisz nazwę marki",
            validator: Yup.string()
              .max(2, "too-short")
              .max(255, "too-long")
              .required("required")
          },
          {
            type: "text",
            name: "systemMargin",
            label: "Marża systemowa (%)",
            placeholder: "Wpisz marżę"
          },
          ...this.state.partners.map(partner => ({
            type: "text",
            name: `partner_${partner.id}`,
            label: partner.email,
            placeholder: "Wpisz marżę"
          }))
        ]}
        render={({ name, systemMargin, ...rest }: any) => (
          <Fragment>
            {name()}
            {systemMargin()}
            <div>
              <b>Marże partnerów</b>
              <div style={partnerMarginsStyle}>
                {this.state.loading ? (
                  <div>
                    <Spinner small /> Wczytywanie listy partnerów...
                  </div>
                ) : (
                  this.state.partners.map(partner =>
                    rest[`partner_${partner.id}`]()
                  )
                )}
              </div>
            </div>
          </Fragment>
        )}
        {...this.props.simpleFormProps}
      />
    );
  }

  private async fetchPartners() {
    this.setState({
      partners: await this.props.partnerService.listFetched(),
      loading: false
    });
  }
}

export default wire(
  ["deviceBrandService", "partnerService", "notificationService"],
  CreateDeviceBrand
);
