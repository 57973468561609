import React, { PureComponent } from "react";

import SimpleList from "./SimpleList.template";

export interface SimpleListColumn {
  [index: string]: any;
}

export interface SimpleListRow {
  [index: string]: any;
}

export interface SimpleListProps {
  title: string;
  description?: string;
  createButtonText?: string;
  filters?: string[];
  createHandler?: Function;
  previewHandler?: Function;
  editHandler?: Function;
  deleteHandler?: Function;
  columns: SimpleListColumn;
  pageCount?: number;
  fetchList: (
    filterValues: {
      [key: string]: string;
    },
    page: number
  ) => Promise<SimpleListRow[]>;
}

export interface SimpleListState {
  rows: SimpleListRow[];
  isLoading: boolean;
  filterValues: {};
  page: number;
}

export class SimpleListComponent extends PureComponent<
  SimpleListProps,
  SimpleListState
> {
  public state: SimpleListState;

  constructor(props: SimpleListProps) {
    super(props);

    this.state = {
      rows: [],
      isLoading: false,
      filterValues: {},
      page: 1
    };
  }

  public async componentDidMount() {
    if (!this.props.filters) {
      await this.fetchList();
    }
  }

  public render() {
    const {
      previewHandler,
      createHandler,
      editHandler,
      deleteHandler
    } = this.props;
    const simpleListProps = {
      onFilterChange: (name: string, value: string) =>
        this.onFilterChange(name, value),
      onPageChange: (page: number) => this.onPageChange(page),
      previewHandler:
        previewHandler &&
        ((row: SimpleListRow) => this.previewHandler({ row })),
      createHandler:
        createHandler && ((row: SimpleListRow) => this.createHandler({ row })),
      editHandler:
        editHandler && ((row: SimpleListRow) => this.editHandler({ row })),
      deleteHandler:
        deleteHandler && ((row: SimpleListRow) => this.deleteHandler({ row }))
    };

    return <SimpleList {...this.props} {...simpleListProps} {...this.state} />;
  }

  private onFilterChange(name: string, value: string) {
    const filterValues = {
      ...this.state.filterValues,
      [name]: value
    };

    this.setState({ filterValues }, () => this.fetchList());
  }

  private onPageChange(page: number) {
    this.setState({ page }, () => this.fetchList());
  }

  private previewHandler({ row }: { row: SimpleListRow }) {
    const { previewHandler } = this.props;

    if (previewHandler) {
      previewHandler({ row });
    }
  }

  private createHandler({ row }: { row: SimpleListRow }) {
    const { createHandler } = this.props;

    if (createHandler) {
      createHandler({
        onCreate: async () => await this.fetchList(),
        row
      });
    }
  }

  private editHandler({ row }: { row: SimpleListRow }) {
    const { editHandler } = this.props;

    if (editHandler) {
      editHandler({
        onEdit: async () => await this.fetchList(),
        row
      });
    }
  }

  private deleteHandler({ row }: { row: SimpleListRow }) {
    const { deleteHandler } = this.props;

    if (deleteHandler) {
      deleteHandler({
        onDelete: async () => await this.fetchList(),
        row
      });
    }
  }

  private async fetchList() {
    this.setState({ isLoading: true });

    setTimeout(async () => {
      this.setState({
        rows: await this.props.fetchList(
          this.state.filterValues,
          this.state.page
        ),
        isLoading: false
      });
    }, 500);
  }
}

export default SimpleListComponent;
