import { Machine } from 'xstate';

const MACHINE_ID = 'repair';

export const LOCATION_OR_LOCATION_EXPRESS_OR_COURIER =
  'location-or-location-express-or-courier';
export const COURIER = 'courier';
export const LOCATION_EXPRESS = 'location-express';

// on-site constants
export const ON_SITE_CLIENT = 'on-site-client';
export const ON_SITE_DEVICE = 'on-site-device';
export const ON_SITE_SERVICES = 'on-site-services';
export const ON_SITE_ACCEPTANCE_INFO = 'on-site-acceptance-info';
export const ON_SITE_NOTES = 'on-site-notes';

// express constants
export const EXPRESS_CLIENT = 'express-client';
export const EXPRESS_DEVICE = 'express-device';
export const EXPRESS_SERVICES = 'express-services';
export const EXPRESS_ACCEPTANCE_INFO = 'express-acceptance-info';
export const EXPRESS_NOTES = 'express-notes';

// courier constants
export const COURIER_CLIENT = 'courier-client';
export const COURIER_DEVICE = 'courier-device';
export const COURIER_SERVICES = 'courier-services';
export const COURIER_ACCEPTANCE_INFO = 'courier-acceptance-info';
export const COURIER_NOTES = 'courier-notes';

const commonOnSiteSteps = [
  ON_SITE_CLIENT,
  ON_SITE_DEVICE,
  ON_SITE_SERVICES,
  ON_SITE_ACCEPTANCE_INFO,
  ON_SITE_NOTES,
];
const commonExpressSteps = [
  EXPRESS_CLIENT,
  EXPRESS_DEVICE,
  EXPRESS_SERVICES,
  EXPRESS_ACCEPTANCE_INFO,
  EXPRESS_NOTES,
];
const commonCourierSteps = [
  COURIER_CLIENT,
  COURIER_DEVICE,
  COURIER_SERVICES,
  COURIER_ACCEPTANCE_INFO,
  COURIER_NOTES,
];

export const steps: { [key: string]: string[] } = {
  [LOCATION_OR_LOCATION_EXPRESS_OR_COURIER]: [
    LOCATION_OR_LOCATION_EXPRESS_OR_COURIER,
  ],
  [LOCATION_EXPRESS]: [
    LOCATION_OR_LOCATION_EXPRESS_OR_COURIER,
    LOCATION_EXPRESS,
  ],
  [COURIER]: [LOCATION_OR_LOCATION_EXPRESS_OR_COURIER, COURIER],
};

// on-site steps
steps[ON_SITE_CLIENT] = [
  ...steps[LOCATION_OR_LOCATION_EXPRESS_OR_COURIER],
  ...commonOnSiteSteps,
];
steps[ON_SITE_DEVICE] = [
  ...steps[LOCATION_OR_LOCATION_EXPRESS_OR_COURIER],
  ...commonOnSiteSteps,
];
steps[ON_SITE_SERVICES] = [
  ...steps[LOCATION_OR_LOCATION_EXPRESS_OR_COURIER],
  ...commonOnSiteSteps,
];
steps[ON_SITE_ACCEPTANCE_INFO] = [
  ...steps[LOCATION_OR_LOCATION_EXPRESS_OR_COURIER],
  ...commonOnSiteSteps,
];
steps[ON_SITE_NOTES] = [
  ...steps[LOCATION_OR_LOCATION_EXPRESS_OR_COURIER],
  ...commonOnSiteSteps,
];
// express steps
steps[EXPRESS_CLIENT] = [...steps[LOCATION_EXPRESS], ...commonExpressSteps];
steps[EXPRESS_DEVICE] = [...steps[LOCATION_EXPRESS], ...commonExpressSteps];
steps[EXPRESS_SERVICES] = [...steps[LOCATION_EXPRESS], ...commonExpressSteps];
steps[EXPRESS_ACCEPTANCE_INFO] = [
  ...steps[LOCATION_EXPRESS],
  ...commonExpressSteps,
];
steps[EXPRESS_NOTES] = [...steps[LOCATION_EXPRESS], ...commonExpressSteps];
// courier steps
steps[COURIER_CLIENT] = [...steps[COURIER], ...commonCourierSteps];
steps[COURIER_DEVICE] = [...steps[COURIER], ...commonCourierSteps];
steps[COURIER_SERVICES] = [...steps[COURIER], ...commonCourierSteps];
steps[COURIER_ACCEPTANCE_INFO] = [...steps[COURIER], ...commonCourierSteps];
steps[COURIER_NOTES] = [...steps[COURIER], ...commonCourierSteps];

export const machine = Machine({
  id: MACHINE_ID,
  initial: LOCATION_OR_LOCATION_EXPRESS_OR_COURIER,
  on: {
    [LOCATION_OR_LOCATION_EXPRESS_OR_COURIER]: LOCATION_OR_LOCATION_EXPRESS_OR_COURIER,
    [LOCATION_EXPRESS]: LOCATION_EXPRESS,
    [COURIER]: COURIER,
    [ON_SITE_CLIENT]: ON_SITE_CLIENT,
    [ON_SITE_DEVICE]: ON_SITE_DEVICE,
    [ON_SITE_SERVICES]: ON_SITE_SERVICES,
    [ON_SITE_ACCEPTANCE_INFO]: ON_SITE_ACCEPTANCE_INFO,
    [ON_SITE_NOTES]: ON_SITE_NOTES,
    [EXPRESS_CLIENT]: EXPRESS_CLIENT,
    [EXPRESS_DEVICE]: EXPRESS_DEVICE,
    [EXPRESS_SERVICES]: EXPRESS_SERVICES,
    [EXPRESS_ACCEPTANCE_INFO]: EXPRESS_ACCEPTANCE_INFO,
    [EXPRESS_NOTES]: EXPRESS_NOTES,
    [COURIER_CLIENT]: COURIER_CLIENT,
    [COURIER_DEVICE]: COURIER_DEVICE,
    [COURIER_SERVICES]: COURIER_SERVICES,
    [COURIER_ACCEPTANCE_INFO]: COURIER_ACCEPTANCE_INFO,
    [COURIER_NOTES]: COURIER_NOTES,
  },
  states: {
    [LOCATION_OR_LOCATION_EXPRESS_OR_COURIER]: {},
    [LOCATION_EXPRESS]: {
      on: {
        PREVIOUS: LOCATION_OR_LOCATION_EXPRESS_OR_COURIER,
        NEXT: EXPRESS_CLIENT,
      },
    },
    [COURIER]: {
      on: {
        PREVIOUS: LOCATION_OR_LOCATION_EXPRESS_OR_COURIER,
        NEXT: COURIER_CLIENT,
      },
    },
    // on-site states
    [ON_SITE_CLIENT]: {
      on: {
        PREVIOUS: LOCATION_OR_LOCATION_EXPRESS_OR_COURIER,
        NEXT: ON_SITE_DEVICE,
      },
    },
    [ON_SITE_DEVICE]: {
      on: { PREVIOUS: ON_SITE_CLIENT, NEXT: ON_SITE_SERVICES },
    },
    [ON_SITE_SERVICES]: {
      on: { PREVIOUS: ON_SITE_DEVICE, NEXT: ON_SITE_ACCEPTANCE_INFO },
    },
    [ON_SITE_ACCEPTANCE_INFO]: {
      on: { PREVIOUS: ON_SITE_SERVICES, NEXT: ON_SITE_NOTES },
    },
    [ON_SITE_NOTES]: { on: { PREVIOUS: ON_SITE_ACCEPTANCE_INFO } },
    // express states
    [EXPRESS_CLIENT]: {
      on: { PREVIOUS: LOCATION_EXPRESS, NEXT: EXPRESS_DEVICE },
    },
    [EXPRESS_DEVICE]: {
      on: { PREVIOUS: EXPRESS_CLIENT, NEXT: EXPRESS_SERVICES },
    },
    [EXPRESS_SERVICES]: {
      on: { PREVIOUS: EXPRESS_DEVICE, NEXT: EXPRESS_ACCEPTANCE_INFO },
    },
    [EXPRESS_ACCEPTANCE_INFO]: {
      on: { PREVIOUS: EXPRESS_SERVICES, NEXT: EXPRESS_NOTES },
    },
    [EXPRESS_NOTES]: { on: { PREVIOUS: EXPRESS_ACCEPTANCE_INFO } },
    // courier states
    [COURIER_CLIENT]: { on: { PREVIOUS: COURIER, NEXT: COURIER_DEVICE } },
    [COURIER_DEVICE]: {
      on: { PREVIOUS: COURIER_CLIENT, NEXT: COURIER_SERVICES },
    },
    [COURIER_SERVICES]: {
      on: { PREVIOUS: COURIER_DEVICE, NEXT: COURIER_ACCEPTANCE_INFO },
    },
    [COURIER_ACCEPTANCE_INFO]: {
      on: { PREVIOUS: COURIER_SERVICES, NEXT: COURIER_NOTES },
    },
    [COURIER_NOTES]: { on: { PREVIOUS: COURIER_ACCEPTANCE_INFO } },
  },
});
