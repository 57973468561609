import { ApiService } from '../../Api.service';

import {
  SalesIdea,
  AddSalesIdeaDTO,
  SalesIdeaDTO,
} from '../../../models/sales/idea/sales-idea';

export class SalesIdeaService {
  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async getSalesIdeas(): Promise<SalesIdea[]> {
    return await this._apiService
      .get<SalesIdeaDTO[]>('/sales/idea/list')
      .then((response) =>
        Array.isArray(response)
          ? response.map((idea) => new SalesIdea(idea))
          : [],
      );
  }

  public async addSalesIdea({ idea }: { idea: AddSalesIdeaDTO }) {
    return await this._apiService.post<void>('/sales/idea', idea);
  }
}
