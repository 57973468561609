import React from 'react';
import { useFormikContext } from 'formik-latest';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { StepComponentType } from '../index';
import { TextField } from '../../../form/field/text-field/input';
import { RepairValues } from '../../create';

export const AcceptanceInfo: StepComponentType = ({ send }) => {
  const { values, errors } = useFormikContext<RepairValues>();

  return (
    <div className="my-4">
      <Typography>Dane kontaktowe:</Typography>
      <TextField
        name="contact.email"
        label="E-mail *"
        placeholder="Wpisz e-mail..."
      />
      <TextField
        name="contact.phone"
        label="Telefon *"
        placeholder="Wpisz telefon..."
      />
      <hr />
      <div className="mb-3">
        <TextField
          name="note_device"
          label="Opis stanu urządzenia"
          placeholder="Podaj opis stanu urządzenia..."
          multiline
          rows={4}
        />
      </div>
      <div className="mb-3">
        <TextField
          name="note_accessories"
          label="Dodatkowe akcesoria"
          placeholder="Podaj dodatkowe akcesoria..."
          multiline
          rows={4}
        />
      </div>
      <div className="mt-4">
        <Button
          color="primary"
          className="mr-3"
          onClick={() => send('PREVIOUS')}
        >
          wstecz
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={
            !!(
              (!values.contact!.email && !values.contact!.phone) ||
              errors.contact?.email ||
              errors.contact?.phone
            )
          }
          onClick={() => send('NEXT')}
        >
          dalej
        </Button>
      </div>
    </div>
  );
};
