import React, { PureComponent } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { wire } from "react-hot-wire";
import { RouteComponentProps } from "react-router";

import { AuthService } from "../../../services/auth/Auth.service";

import Spinner from "../../common/spinner/Spinner";
import ResetPasswordContainer, {
  textStyles,
  titleStyles
} from "./ResetPasswordContainer";

import Reset from "./ResetPassword.template";

const schema = Yup.object().shape({
  password: Yup.string().required(),
  password_confirm: Yup.string().required()
});

export interface ResetPasswordState {
  loading: boolean;
  error: boolean;
  success: boolean;
  emailAddress?: string;
  isActive?: boolean;
}

export interface ResetPasswordProps
  extends RouteComponentProps<{ hash: string }> {
  authService: AuthService;
}

export class ResetPasswordComponent extends PureComponent<ResetPasswordProps> {
  public state: ResetPasswordState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      success: false
    };
  }

  public componentDidMount() {
    this.checkHash();
  }

  render() {
    if (this.state.loading || !this.state.emailAddress) {
      return (
        <ResetPasswordContainer
          header={this.state.loading ? <Spinner /> : <p>Nieprawidłowy kod</p>}
        />
      );
    }

    if (this.state.error || this.state.success) {
      return (
        <ResetPasswordContainer
          header={
            this.state.error ? this.getErrorView() : this.getSuccessView()
          }
        />
      );
    }

    return (
      <Formik
        initialValues={{}}
        validationSchema={schema}
        onSubmit={values => this.onSubmit(values)}
        render={(props: FormikProps<object>) => (
          <Reset {...props} isActive={this.state.isActive || false} />
        )}
      />
    );
  }

  private getErrorView() {
    return (
      <div>
        <p style={titleStyles} className="mt-3">
          Wystąpił błąd
        </p>
        <p style={textStyles} className="mt-3">
          Spróbuj ponownie później. <br />
        </p>
      </div>
    );
  }

  private getSuccessView() {
    return (
      <div>
        <p style={titleStyles} className="mt-3">
          {this.state.isActive
            ? "Twoje hasło zostało zmienione"
            : "Twoje konto zostało aktywowane!"}
        </p>
        <p style={textStyles} className="mt-3">
          Aby się zalogować wejdź do aplikacji mobilnej
        </p>
      </div>
    );
  }

  async onSubmit(values: { [key: string]: string }) {
    this.setState({
      loading: true
    });

    if (
      await this.props.authService.resetPassword({
        hash: this.props.match.params.hash,
        newPassword: values.password,
        confirmNewPassword: values.password_confirm
      })
    ) {
      this.setState({
        loading: false,
        success: true
      });
    } else {
      this.setState({
        loading: false,
        success: false,
        error: true
      });
    }
  }

  async checkHash() {
    const { hash } = this.props.match.params;

    if (!hash) {
      this.setState({
        loading: false
      });
    } else {
      const data = await this.props.authService.checkResetPasswordHash(hash);

      if (data && typeof data === "object" && data.emailAddress) {
        this.setState({
          loading: false,
          emailAddress: data.emailAddress,
          isActive: data.isActive
        });
      } else {
        this.setState({
          loading: false
        });
      }
    }
  }
}

export default wire(["authService"], ResetPasswordComponent);
