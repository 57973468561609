import { createMuiTheme } from '@material-ui/core';

import indigo from '@material-ui/core/colors/indigo';

export const theme = createMuiTheme({
  palette: {
    primary: indigo,
  },
  overrides: {
    MuiPickersToolbar: {},
    MuiFormControl: {
      root: {
        display: 'flex',
      },
    },
    MuiFormControlLabel: {
      root: {
        margin: 0,
      },
    },
    MuiInputLabel: {
      formControl: {
        background: '#FFF',
        padding: '0 0.5rem',
      },
    },
  },
  zIndex: {
    modal: 3000,
  },
});
