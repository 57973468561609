import React, { Fragment } from 'react';
import { wire } from 'react-hot-wire';
import { useFormikContext } from 'formik-latest';

import MUIButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { COURIER, LOCATION_EXPRESS, ON_SITE_CLIENT } from '../../machine';
import { StepComponentType } from '../index';
import { Select } from '../../../form/field/select/select';
import { RepairService } from '../../../../../services/repair/Repair.service';
import { ApplicationService } from '../../../../../services/application/Application.service';
import { COURIER_DEFAULT, RepairValues } from '../../create';
import paths from './path';

const LocationOrLocationExpressOrCourier: StepComponentType<{
  repairService: RepairService;
  applicationService: ApplicationService;
}> = ({ send, repairService, applicationService }) => {
  const { values, setFieldValue } = useFormikContext<RepairValues>();

  if (values.path) {
    const Path = paths[values.path];

    return (
      <Path
        send={send}
        values={values}
        reset={() => {
          setFieldValue('path', undefined);
          setFieldValue('location', undefined);
          setFieldValue('location_express', undefined);
          setFieldValue('courier', COURIER_DEFAULT);
        }}
      />
    );
  }

  return (
    <div className="d-flex flex-column my-4">
      <Select<RepairValues>
        name="location.id"
        label="Wybierz punkt naprawy zwykłej"
        onChange={() => {
          send(ON_SITE_CLIENT);
          setFieldValue('path', 'location');
        }}
        fetch={() => repairService.locationList()}
      />
      <Typography align="center" className="my-3">
        lub
      </Typography>
      <Select<RepairValues>
        name="location_express.id"
        label="Wybierz punkt naprawy ekspresowej"
        onChange={() => {
          send(LOCATION_EXPRESS);
          setFieldValue('path', 'location_express');
        }}
        fetch={() => repairService.locationExpressList()}
      />
      {applicationService.config!.isRepairCourierEnabled && (
        <Fragment>
          <Typography align="center" className="my-3">
            lub
          </Typography>
          <MUIButton
            color="primary"
            variant="contained"
            onClick={() => {
              send(COURIER);
              setFieldValue('path', 'courier');
            }}
          >
            Zamów kuriera po odbiór urządzenia we wskazanym miejscu
          </MUIButton>
        </Fragment>
      )}
    </div>
  );
};

export default wire(
  ['repairService', 'applicationService'],
  LocationOrLocationExpressOrCourier,
);
