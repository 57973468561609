import React, { PureComponent, Fragment } from "react";
import { wire } from "react-hot-wire";

import DeviceField from "./Device.template";
import { CreateDevice } from "../../../../../device/create/Create";
import { FieldProps } from "..";
import { DrawerService } from "../../../../../../services/drawer/Drawer.service";

export class DeviceComponent extends PureComponent<
  FieldProps & { drawerService: DrawerService }
> {
  public render() {
    const { formikProps, field } = this.props;

    return (
      <Fragment>
        <DeviceField
          {...this.props}
          createDevice={() =>
            this.props.drawerService.activate("create-device", {
              modalProps: {
                position: "right",
                fullHeight: true
              },
              onSubmit: (value: CreateDevice) =>
                formikProps.setFieldValue(field.name, value)
            })
          }
        />
      </Fragment>
    );
  }
}

export default wire(["drawerService"], DeviceComponent);
