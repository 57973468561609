import { Weekday } from '../../common/Weekday';

export interface LocationFastRepairWorkHoursDTO {
  id: string;
  weekday: Weekday;
  start: number;
  end: number;
}

export interface CreateLocationFastRepairWorkHoursDTO {
  weekday: Weekday;
  start: number;
  end: number;
}

export class LocationFastRepairWorkHours {
  private readonly _id: string;
  private _weekday: Weekday;
  private _start: number;
  private _end: number;

  constructor(locationFastRepairWorkHours: LocationFastRepairWorkHoursDTO) {
    this._id = locationFastRepairWorkHours.id;
    this._weekday = locationFastRepairWorkHours.weekday;
    this._start = locationFastRepairWorkHours.start;
    this._end = locationFastRepairWorkHours.end;
  }

  public get id(): string {
    return this._id;
  }

  public get weekday(): Weekday {
    return this._weekday;
  }

  public get start(): number {
    return this._start;
  }

  public get end(): number {
    return this._end;
  }
}
