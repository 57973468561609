import React, { PureComponent, CSSProperties } from 'react';
import { wire } from 'react-hot-wire';

import { ServiceService } from '../../../services/service/Service.service';
import { DeviceBrandService } from '../../../services/device/brand/DeviceBrand.service';
import { BrandModelService } from '../../../services/device/brand/model/BrandModel.service';
import { DeviceTypeService } from '../../../services/device/DeviceType.service';
import { DrawerService } from '../../../services/drawer/Drawer.service';
import { UserService } from '../../../services/user/User.service';
import { ApplicationService } from '../../../services/application/Application.service';

import {
  SimpleListColumn,
  SimpleListProps,
} from '../../common/list/simple-list/SimpleList';

import SimpleList, {
  SimpleListRow,
} from '../../common/list/simple-list/SimpleList';
import { PartnerMarginDTO } from '../../../models/partner/PartnerMargin';
import { UserRoles } from '../../../models/user/User';
import { WarehouseItem } from '../../../models/warehouse/WarehouseItem';

const customCellStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
};

const customColStyles: CSSProperties = {
  padding: '.7rem',
  ...customCellStyles,
};

export class ServiceList extends PureComponent<{
  serviceService: ServiceService;
  brandModelService: BrandModelService;
  deviceBrandService: DeviceBrandService;
  deviceTypeService: DeviceTypeService;
  drawerService: DrawerService;
  userService: UserService;
  applicationService: ApplicationService;
}> {
  public render() {
    const {
      userService: { user },
    } = this.props;

    const props: SimpleListProps = {
      title: 'Lista usług',
      description: ' ',
      createButtonText: 'dodaj usługę',
      filters: ['brandModel'],
      columns: this.getColumns(),
      fetchList: (filterValues: any) =>
        this.props.serviceService
          .list({
            modelId: filterValues.brandModel,
          })
          .then(list =>
            list.map(service => {
              return {
                id: service.id,
                name: service.name,
                model: service.model,
                priceNet: (
                  <span data-value={service.priceNet} style={customColStyles}>
                    {service.priceNet}
                  </span>
                ),
                priceGross: (
                  <span data-value={service.price} style={customColStyles}>
                    {service.price}
                  </span>
                ),
                tax: (
                  <span data-value={service.tax} style={customColStyles}>
                    {service.tax}
                  </span>
                ),
                duration: (
                  <span data-value={service.duration} style={customColStyles}>
                    {typeof service.duration === 'number'
                      ? service.duration
                      : '-'}
                  </span>
                ),
                margin: (
                  <span data-value={service.margin} style={customColStyles}>
                    {service.margin || 0}
                  </span>
                ),
                systemMargin: (
                  <span
                    data-value={service.systemMargin}
                    style={customColStyles}
                  >
                    {typeof service.systemMargin === 'number'
                      ? service.systemMargin
                      : '-'}
                  </span>
                ),
                partnerMargins: service.partnerMargins,
                warehouseItems: service.warehouseItems,
              };
            }),
          ),
    };

    if (user!.isAdmin()) {
      props.editHandler = ({
        onEdit,
        row,
      }: {
        onEdit: Function;
        row: SimpleListRow;
      }) => {
        this.props.drawerService.activate('edit-service', {
          simpleFormProps: {
            initialValues: {
              id: row.id,
              name: row.name,
              priceNet: row.priceNet.props['data-value'],
              duration: row.duration.props['data-value'],
              margin: row.margin.props['data-value'],
              systemMargin: row.systemMargin.props['data-value'],
              tax: String(row.tax.props['data-value']),
              modelId: row.model.id,
              brandId: row.model.brand.id,
              typeId: row.model.type.id,
              warehouseItemsIds: row.warehouseItems
                .map((item: WarehouseItem) => item.externalId)
                .join(', '),
              ...(row.partnerMargins || []).reduce(
                (
                  obj: { [key: string]: number },
                  partnerMargin: PartnerMarginDTO,
                ) => {
                  obj[`partner_${partnerMargin.id}`] = partnerMargin.margin;

                  return obj;
                },
                {},
              ),
            },
            onResolved: onEdit,
          },
        });
      };
      props.deleteHandler = ({
        onDelete,
        row,
      }: {
        onDelete: Function;
        row: SimpleListRow;
      }) => this.props.serviceService.remove(row.id).then(() => onDelete());

      props.createHandler = ({ onCreate }: { onCreate: Function }) =>
        this.props.drawerService.activate('create-service', {
          simpleFormProps: {
            onResolved: onCreate,
          },
        });
    }

    return <SimpleList {...props} />;
  }

  private getColumns(): SimpleListColumn[] {
    let columns: SimpleListColumn[] = [
      {
        label: <span style={{ ...customColStyles }}>Usługa</span>,
        name: 'name',
      },
    ];

    columns = [
      ...columns,
      {
        label: <span style={customCellStyles}>Cena netto (zł)</span>,
        name: 'priceNet',
      },
      {
        label: <span style={customCellStyles}>Cena brutto (zł)</span>,
        name: 'priceGross',
      },
      {
        label: <span style={customCellStyles}>Podatek (%)</span>,
        name: 'tax',
      },
      {
        label: <span style={customColStyles}>Czas trwania</span>,
        name: 'duration',
      },
    ];

    if (this.shouldUseMargin()) {
      columns = [
        ...columns,
        {
          label: <span style={customCellStyles}>Marża (zł)</span>,
          name: 'margin',
        },
      ];
    }

    return columns;
  }

  private shouldUseMargin() {
    return (
      this.props.applicationService.config!.isServiceMarginEnabled &&
      this.props.userService.user!.hasOneOfRoles([
        UserRoles.admin,
        UserRoles.partner,
      ])
    );
  }
}

export default wire(
  [
    'serviceService',
    'deviceTypeService',
    'deviceBrandService',
    'brandModelService',
    'drawerService',
    'userService',
    'applicationService',
  ],
  ServiceList,
);
