import React, { FC, MouseEvent, useState, useEffect, useMemo } from 'react';
import { wire } from 'react-hot-wire';
import {
  CellClassParams,
  CellParams,
  CellValue,
  Columns,
  DataGrid,
  ValueGetterParams,
} from '@material-ui/data-grid';
import { Box, Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useSheetsQueue } from '@rootsher/use-sheets-queue';
import { makeStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';

import { RepairService } from '../../../services/repair/Repair.service';

import { Repair } from '../../../models/repair/Repair';
import { User } from '../../../models/user/User';
import { CustomerProfile } from '../../../models/customer/CustomerProfile';
import { Device } from '../../../models/device/Device';

import RepairDetails from '../details/repair-details';
import { BrandModel } from '../../../models/device/brand/model/BrandModel';
import { ListResponse } from '../../../models/common/ListResponse';
import { UserService } from '../../../services/user/User.service';
import { LocationService } from '../../../services/location/Location.service';
import { Location } from '../../../models/location/Location';
import { ServiceService } from '../../../services/service/Service.service';
import NewPagination from '../../common/pagination/new-pagination';
import RepairListTableHeader from './components/RepairListTableHeader';
import {
  RepairStatus,
  RepairStatusTranslation,
} from '../../../models/repair/RepairStatus';
import { RepairAssignedService } from '../../../models/repair/RepairAssignedService';

export interface RepairListProps {
  userService: UserService;
  repairService: RepairService;
  locationService: LocationService;
  serviceService: ServiceService;
}

const useStyles = makeStyles({
  root: {
    height: 400,
    width: '100%',
    '& .repairList_yellow': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
    },
    '& .repairList_green': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
    },
    '& .repairList_red': {
      backgroundColor: '#d47483',
    },
  },
  gridContainer: {
    height: '100%',
  },
});

export const RepairList: FC<RepairListProps> = ({
  repairService,
  userService,
  locationService,
  serviceService,
}) => {
  const pageSize = 20;
  const [push] = useSheetsQueue();
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [statusValue, setStatusValue] = useState<RepairStatus | undefined>();
  const [location, setLocation] = useState<string | undefined>();
  const [technician, setTechnician] = useState<string | undefined>();
  const pageCount = total > 0 ? Math.ceil(total / pageSize) : 1;
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    setRepairs([]);
    repairService
      .listAll({
        page,
        location,
        technician,
        limit: pageSize,
        searchTerm: searchValue,
        status: statusValue,
      })
      .then((response: ListResponse<Repair>) => {
        setRepairs(response.items);
        setTotal(response.total);
        setLoading(false);
      });
  }, [repairService, page, searchValue, statusValue, location, technician]);

  const onSearchValueChange = debounce(function (value: string) {
    if (value.length >= 3) {
      setSearchValue(value);
      setPage(1);
    } else {
      if (searchValue.length >= 3) {
        setPage(1);
      }

      setSearchValue('');
    }
  }, 1000);

  const CustomPagination = useMemo(
    () => () => (
      <NewPagination page={page} pageCount={pageCount} setPage={setPage} />
    ),
    [page, pageCount],
  );

  const columns: Columns = React.useMemo<Columns>(() => {
    const openDetails = (
      e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
      id: CellValue,
    ) => {
      e.stopPropagation();

      push(
        RepairDetails({
          repairService,
          userService,
          locationService,
          serviceService,
          repairId: id as string,
        }),
        {
          side: 'right',
          size: 50,
        },
      );
    };

    return [
      {
        field: 'details',
        headerName: 'Szczegóły',
        sortable: false,
        width: 130,
        renderCell: (params: CellParams) => (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => openDetails(e, params.row.id)}
          >
            Szczegóły
          </Button>
        ),
      },
      {
        field: 'number',
        headerName: 'Numer',
        sortable: false,
      },
      {
        field: 'createdAt',
        headerName: 'Data utworzenia',
        sortable: false,
        width: 200,
        valueGetter: (params: ValueGetterParams) =>
          (params.value as Date).toLocaleString(),
      },
      {
        field: 'type',
        headerName: 'Typ naprawy',
        width: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams) => {
          if (params.row.courierOrder) {
            return 'Kurier';
          }

          if (params.row.express) {
            return 'Ekspresowa';
          }

          if (params.row.returnAddress) {
            return 'Przesyłka';
          }

          return 'Standardowa';
        },
        cellClassName: (params: CellClassParams) =>
          params.getValue('type') !== 'Standardowa' ? 'font-weight-bold' : '',
      },
      {
        field: 'customer',
        headerName: 'Klient',
        width: 200,
        sortable: false,
        valueGetter: (params: ValueGetterParams) => {
          const user =
            (params.value as CustomerProfile) ||
            (params.row as Repair).guestCustomer;

          if (!user) {
            return '-';
          }

          return `${user.firstname} ${user.surname}`;
        },
      },
      {
        field: 'device',
        headerName: 'Urządzenie',
        width: 200,
        sortable: false,
        valueGetter: (params: ValueGetterParams) => {
          const model = (params.row.device as Device).model as BrandModel;

          return `${model.brand.name} - ${model.name}`;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 275,
        sortable: false,
        renderCell: (params: CellParams) => {
          const missingParts = (params.row
            .services as RepairAssignedService[])?.some((service) =>
            service.warehouseItems?.some((item) => !item.quantity),
          );

          return (
            <div className="MuiDataGrid-cell p-0">
              {missingParts && <WarningIcon color="error" className="mr-1" />}{' '}
              {RepairStatusTranslation[params.getValue('status') as string]}
            </div>
          );
        },
      },
      {
        field: 'assignedTechnician',
        headerName: 'Przypisany technik',
        width: 180,
        sortable: false,
        valueGetter: (params: ValueGetterParams) => {
          const user = params.value as User;

          return user ? user.profile.viewName : '-';
        },
      },
      {
        field: 'location',
        headerName: 'Punkt',
        width: 275,
        sortable: false,
        valueGetter: (params: ValueGetterParams) => {
          const model = params.value as Location;

          return model ? model.name : '-';
        },
      },
    ];
  }, [locationService, push, repairService, serviceService, userService]);

  return (
    <Box className={classes.root}>
      <Box mb={4}>
        <RepairListTableHeader
          onSearchValueChange={onSearchValueChange}
          onStatusValueChange={(status: RepairStatus | undefined) => {
            setStatusValue(status);
            setPage(1);
          }}
          onLocationChange={(location: string | undefined) => {
            setLocation(location);
            setPage(1);
          }}
          onTechnicianChange={(technician: string | undefined) => {
            setTechnician(technician);
            setPage(1);
          }}
          page={page}
          pageCount={pageCount}
          setPage={setPage}
        />
      </Box>

      <Box my={6} className={classes.gridContainer}>
        <DataGrid
          rows={repairs}
          columns={columns}
          pageSize={pageSize}
          page={1}
          rowCount={repairs.length}
          loading={loading}
          components={{
            pagination: CustomPagination,
          }}
          disableSelectionOnClick
          autoHeight
        />
      </Box>
    </Box>
  );
};

export default wire(
  ['repairService', 'userService', 'locationService', 'serviceService'],
  RepairList,
);
