import React from 'react';
import { Formik, Form } from 'formik-latest';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';
import { useMutation } from 'react-query';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { TextField } from '../../form/field/text-field/input';

import { RepairService } from '../../../../services/repair/Repair.service';

import { Repair } from '../../../../models/repair/Repair';

export interface RepairAddNoteProps {
  repair: Repair;
  repairService: RepairService;
  onNoteAdded?: (value: FormValues) => void;
}

export interface FormValues {
  content: string;
  sendToClient: boolean;
}

const RepairAddNoteSchema = Yup.object().shape({
  content: Yup.string().min(1).max(10000).required(),
});

export const RepairAddNote = ({
  repair,
  onNoteAdded,
  repairService,
}: RepairAddNoteProps) => {
  const { isLoading, error, mutateAsync } = useMutation<
    void,
    Error,
    FormValues
  >(
    ({ content, sendToClient }) =>
      repairService.addRepairNote({
        repairId: repair.id,
        note: {
          content,
          sendToClient,
        },
      }),
    {
      onSuccess: (data, values) => onNoteAdded?.(values),
    },
  );

  return (
    <Box>
      {error && (
        <Box>
          <Typography>Wystąpił błąd</Typography>
          <pre>
            {JSON.stringify(error.message ? error.message : error, null, 2)}
          </pre>
        </Box>
      )}
      <Formik
        initialValues={{
          content: '',
          sendToClient: false,
        }}
        validationSchema={RepairAddNoteSchema}
        onSubmit={(values: FormValues, formikHelpers) => {
          !isLoading &&
            mutateAsync(values).then(() => formikHelpers.resetForm());
        }}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <TextField
                name="content"
                label="Notatka"
                placeholder="Zawartość notatki"
                multiline
                autoFocus
                rows={4}
              />
              <Box mb={2}>
                <FormControlLabel
                  control={
                    <MuiCheckbox
                      size="small"
                      name="sendToClient"
                      disabled={!repair.contactEmail}
                      checked={values.sendToClient}
                      onChange={(event, checked) =>
                        setFieldValue('sendToClient', checked)
                      }
                    />
                  }
                  label="Wyślij tę notatke jako wiadomość do klient poprzez email"
                />
              </Box>
              <Button color="primary" variant="contained" type="submit">
                {isLoading ? (
                  <CircularProgress size={24} style={{ color: grey[50] }} />
                ) : (
                  'Wyślij'
                )}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default RepairAddNote;
