import { merge } from "lodash";
import { SimpleFormProps } from "../../components/common/form/simple-form/SimpleForm";
import { MDBModalProps } from "../../definitions/mdbreact";

export interface DrawerProps {
  simpleFormProps?: SimpleFormProps;
  modalProps?: MDBModalProps;
  onSubmit?: Function;
}

export class Drawer {
  private _name: string;
  private _props: DrawerProps;

  constructor(name: string, props: DrawerProps) {
    this._name = name;
    this._props = props;
  }

  get name(): string {
    return this._name;
  }

  get props(): DrawerProps {
    return this._props;
  }

  public close() {
    this._props = merge(this._props, { modalProps: { isOpen: false } });
  }
}
