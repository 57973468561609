import { User, UserDTO } from '../user/User';

export interface RepairNoteDTO {
  id: string;
  content: string;
  sendToClient: boolean;
  user: UserDTO;
  created_at: string;
  updated_at: string;
}

export interface AddRepairNoteDTO {
  content: string;
  sendToClient: boolean;
}

export class RepairNote {
  private _id: string;
  private _content: string;
  private _sendToClient: boolean;
  private _user: User;
  private _createdAt: Date;
  private _updatedAt: Date;

  constructor(repairNote: RepairNoteDTO) {
    this._id = repairNote.id;
    this._content = repairNote.content;
    this._sendToClient = repairNote.sendToClient;
    this._user = new User(repairNote.user);
    this._createdAt = new Date(repairNote.created_at);
    this._updatedAt = new Date(repairNote.updated_at);
  }

  public get id(): string {
    return this._id;
  }

  public get content(): string {
    return this._content;
  }

  public get sendToClient(): boolean {
    return this._sendToClient;
  }

  public get user(): User {
    return this._user;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }
}
