import React, { FC } from 'react';
import { useMachine } from '@xstate/react';
import { Formik, Form } from 'formik-latest';
import * as Yup from 'yup';
import { wire } from 'react-hot-wire';
import { useSheetsQueue } from '@rootsher/use-sheets-queue';
import moment, { Moment } from 'moment';

import MUIStepper from '@material-ui/core/Stepper';
import MUIStep from '@material-ui/core/Step';
import MUIStepLabel from '@material-ui/core/StepLabel';
import MUIStepContent from '@material-ui/core/StepContent';

import { machine, steps } from './machine';
import stepsComponents from './steps';
import translations from './translations';
import { RepairService } from '../../../services/repair/Repair.service';
import { UserService } from '../../../services/user/User.service';
import { AutocompleteOption } from '../form/field/autocomplete/autocomplete';

export type RepairValues = {
  path?: 'location' | 'location_express' | 'courier';
  location?: { id: string };
  location_express?: { id: string; time: string | number };
  courier: {
    date: Moment;
    address: string;
    time?: {
      start?: Moment;
      end?: Moment;
    };
  };
  client?: AutocompleteOption;
  device?: string;
  services?: AutocompleteOption[];
  contact: {
    email: string;
    phone: string;
  };
  note_device?: string;
  note_accessories?: string;
  note?: string;
  createdAt?: Moment;
};

const RepairSchema = Yup.object().shape({
  path: Yup.mixed()
    .oneOf(['location', 'location_express', 'courier'])
    .required(),
  location: Yup.object().when('path', {
    is: 'location',
    then: Yup.object().shape({
      id: Yup.string().required(),
    }),
  }),
  location_express: Yup.object().when('path', {
    is: 'location_express',
    then: Yup.object().shape({
      id: Yup.string().required(),
      time: Yup.string().required(),
    }),
  }),
  courier: Yup.object().when('path', {
    is: 'courier',
    then: Yup.object().shape({
      date: Yup.string().required(),
      address: Yup.string().required(),
      time: Yup.object().shape({
        start: Yup.string().required(),
        end: Yup.string()
          .test(
            '3-hours',
            'Zakres min. 3h',
            (end?: any, context?: any): any => {
              return (
                moment
                  .duration(moment(end).diff(context?.parent?.start))
                  .asHours() >= 3
              );
            },
          )
          .required(),
      }),
    }),
  }),
  contact: Yup.object().shape({
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
  }),
});

export const COURIER_DEFAULT = {
  date: moment().add(1, 'day'),
  address: '',
};

export const Box: FC = ({ children }) => (
  <div className="p-3" style={{ backgroundColor: '#fff8c8' }}>
    {children}
  </div>
);

export const ErrorBox: FC = ({ children }) => (
  <div
    className="px-3 py-2"
    style={{ backgroundColor: '#ea5f5f', color: '#fff' }}
  >
    {children}
  </div>
);

export const RepairCreate = ({
  repairService,
  userService,
}: {
  repairService: RepairService;
  userService: UserService;
}) => {
  const [, pop] = useSheetsQueue();
  const [current, send] = useMachine(machine);
  const Step = stepsComponents[current.value as string];

  function onSubmit(values: RepairValues) {
    return repairService.create(values).then(pop);
  }

  return (
    <Formik
      initialValues={{
        courier: COURIER_DEFAULT,
        services: [],
        contact: {
          email: '',
          phone: '',
        },
      }}
      validationSchema={RepairSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <MUIStepper
          activeStep={steps[current.value as string].indexOf(
            current.value as string,
          )}
          orientation="vertical"
        >
          {steps[current.value as string].map((step, index) => {
            return (
              <MUIStep key={index}>
                <MUIStepLabel onClick={() => send(step)}>
                  {translations.repair.stepper.step[step]}
                </MUIStepLabel>
                <MUIStepContent>
                  {step === current.value && (
                    <Step send={send} user={userService.user} />
                  )}
                </MUIStepContent>
              </MUIStep>
            );
          })}
        </MUIStepper>
      </Form>
    </Formik>
  );
};

export default wire(['repairService', 'userService'], RepairCreate);
