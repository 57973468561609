import React from 'react';

import MUIButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { COURIER } from '../../../machine';
import { PathProps } from './index';
import { Box } from '../../../create';

export const CourierPath = ({ send, reset }: PathProps) => {
  return (
    <div className="d-flex flex-column my-4">
      <Box>
        <Typography>
          Kurier przyjedzie na wskazany adres w następnym kroku.
        </Typography>
      </Box>
      <div className="mt-4">
        <MUIButton color="primary" className="mr-3" onClick={reset}>
          Zmień wybór
        </MUIButton>
        <MUIButton
          color="primary"
          variant="contained"
          onClick={() => send(COURIER)}
        >
          Dalej
        </MUIButton>
      </div>
    </div>
  );
};
