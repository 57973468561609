import React from 'react';
import { useFormikContext } from 'formik-latest';
import { wire } from 'react-hot-wire';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useSheetsQueue } from '@rootsher/use-sheets-queue';

import { StepComponentType } from '../index';
import ClientCreate from './client-create';
import { RepairValues } from '../../create';
import ClientPreview from './client-preview';
import { RepairService } from '../../../../../services/repair/Repair.service';
import { Customer } from '../../../../../models/customer/Customer';

import { Autocomplete } from '../../../form/field/autocomplete/autocomplete';

export const Client: StepComponentType<{ repairService: RepairService }> = ({
  send,
  repairService,
}) => {
  const { values, setFieldValue } = useFormikContext<RepairValues>();
  const [push] = useSheetsQueue();

  if (values.client) {
    return (
      <ClientPreview
        send={send}
        setFieldValue={setFieldValue}
        reset={() => {
          setFieldValue('client', undefined);
          setFieldValue('contact.email', undefined);
          setFieldValue('contact.phone', undefined);
        }}
        values={values}
      />
    );
  }

  return (
    <div className="d-flex flex-column my-4">
      <Autocomplete
        name="client"
        label="Wyszukaj klienta..."
        fetch={(value) => repairService.clients(value)}
      />
      <Typography align="center" className="my-3">
        lub
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={() =>
          push(
            ClientCreate,
            {
              side: 'right',
              size: 50,
              onSubmit: (customer: Customer) =>
                setFieldValue('client', customer.id),
            },
            { side: 'right', size: 80 },
          )
        }
      >
        utwórz klienta
      </Button>
      <div className="mt-4">
        <Button
          color="primary"
          className="mr-3"
          onClick={() => send('PREVIOUS')}
        >
          wstecz
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!values.client}
          onClick={() => send('NEXT')}
        >
          dalej
        </Button>
      </div>
    </div>
  );
};

export default wire(['repairService'], Client);
