import React, { FC, useState, useEffect, useMemo } from 'react';
import { wire } from 'react-hot-wire';
import {
  CellParams,
  Columns,
  DataGrid,
  ValueGetterParams,
} from '@material-ui/data-grid';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';

import { User, UserRoles } from '../../../models/user/User';
import { UserProfile } from '../../../models/user/Profile/UserProfile';

import { UserService } from '../../../services/user/User.service';
import { DrawerService } from '../../../services/drawer/Drawer.service';

import NewPagination from '../../common/pagination/new-pagination';
import { PartnerProfile } from '../../../models/user/Profile/PartnerProfile';
import UserListTableHeader from './components/user-list-table-header';

export interface RepairListProps {
  drawerService: DrawerService;
  userService: UserService;
}

const useStyles = makeStyles({
  root: {
    height: 400,
    width: '100%',
  },
});

export const UserList: FC<RepairListProps> = ({
  drawerService,
  userService,
}) => {
  const pageSize = 20;
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [location, setLocation] = useState<string | undefined>();
  const [role, setRole] = useState<string | undefined>();
  const pageCount = total > 0 ? Math.ceil(total / pageSize) : 1;
  const classes = useStyles();

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);

      return;
    }

    setLoading(true);
    setUsers([]);
    userService
      .list({
        page,
        role,
        locationId: location,
        searchTerm: searchValue,
        limit: pageSize,
      })
      .then((data) => {
        setTotal(data.total);
        setUsers(data.items);
        setLoading(false);
      });
  }, [userService, page, searchValue, location, role, shouldRefresh]);

  const onSearchValueChange = debounce(function (value: string) {
    if (value.length >= 3) {
      setSearchValue(value);
      setPage(1);
    } else {
      if (searchValue.length >= 3) {
        setPage(1);
      }

      setSearchValue('');
    }
  }, 1000);

  const CustomPagination = useMemo(
    () => () => (
      <NewPagination page={page} pageCount={pageCount} setPage={setPage} />
    ),
    [page, pageCount],
  );

  const columns: Columns = React.useMemo<Columns>(() => {
    const showEditModal = (row: User) => {
      drawerService.activate('edit-user', {
        simpleFormProps: {
          initialValues: {
            id: row.id,
            role: row.role,
            employeeRole: row.roles[1],
            email: row.email,
            nickname: row.profile.nickname,
            firstname: row.profile.firstname,
            surname: row.profile.surname,
            phone: row.profile.phone,
            externalId: (row.profile as PartnerProfile).externalId,
            location: row.location ? row.location.id : null,
          },
          onResolved: () => setShouldRefresh(true),
        },
      });
    };

    const showPasswordChangeModal = (user: User) => {
      drawerService.activate('edit-user-password', {
        simpleFormProps: {
          initialValues: {
            id: user.id,
            email: user.email,
          },
        },
      });
    };

    return [
      {
        field: 'edit',
        headerName: 'Edytuj',
        sortable: false,
        width: 120,
        renderCell: (params: CellParams) => (
          <Button
            color="primary"
            variant="contained"
            className="text-uppercase"
            onClick={() => showEditModal(params.row as User)}
          >
            Edytuj
          </Button>
        ),
      },
      {
        field: 'changePassword',
        headerName: 'Hasło',
        width: 150,
        sortable: false,
        renderCell: (params: CellParams) => (
          <Button
            variant="contained"
            className="text-uppercase"
            onClick={() => showPasswordChangeModal(params.row as User)}
          >
            Zmień hasło
          </Button>
        ),
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: false,
        width: 300,
      },
      {
        field: 'type',
        headerName: 'Typ',
        width: 220,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Nazwa',
        width: 300,
        sortable: false,
        valueGetter: (params: ValueGetterParams) => {
          const profile = (params.row as User).profile as UserProfile;

          return profile.viewName;
        },
      },
      {
        field: 'location',
        headerName: 'Lokacja',
        width: 400,
        sortable: false,
        valueGetter: (params: ValueGetterParams) => {
          const location = (params.row as User).location;

          return location ? location.name : '-';
        },
      },
      {
        field: 'delete',
        headerName: 'Usuń',
        sortable: false,
        renderCell: (params: CellParams) => (
          <Box className="w-100" display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              className="text-uppercase"
              onClick={() =>
                window.confirm(
                  `Usunąć użytkownika "${params.getValue('email')}" ?`,
                ) &&
                userService
                  .remove({ id: (params.row as User).id })
                  .then(() => setShouldRefresh(true))
              }
            >
              Usuń
            </Button>
          </Box>
        ),
      },
    ];
  }, [drawerService, userService]);

  return (
    <Box className={classes.root}>
      <Box mb={4}>
        <UserListTableHeader
          onSearchValueChange={onSearchValueChange}
          onTypeValueChange={(status: UserRoles | undefined) => {
            setRole(status);
            setPage(1);
          }}
          onLocationChange={(location: string | undefined) => {
            setLocation(location);
            setPage(1);
          }}
          onCreateClick={() =>
            drawerService.activate('create-user', {
              simpleFormProps: {
                onResolved: () => setShouldRefresh(true),
              },
            })
          }
          page={page}
          pageCount={pageCount}
          setPage={setPage}
        />
      </Box>

      <Box my={6}>
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={pageSize}
          page={1}
          rowCount={users.length}
          loading={loading}
          components={{
            pagination: CustomPagination,
          }}
          disableSelectionOnClick
          autoHeight
        />
      </Box>
    </Box>
  );
};

export default wire(['drawerService', 'userService'], UserList);
