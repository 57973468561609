import React from "react";
import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption
} from "mdbreact";
import _ from "lodash";
import classnames from "classnames";

import { FieldProps } from "../index";
import ErrorMessage from "../../../ErrorMessage";

export default ({
  field: { name, placeholder, options = [], disabled, ...rest },
  formikProps: { values, setFieldValue }
}: FieldProps) => {
  const selected = _.find(options, option => option.value === values[name]);
  return (
    <MDBSelect
      getValue={(value: Array<string>) => setFieldValue(name, value[0])}
      {...rest}
    >
      <MDBSelectInput
        className={classnames({ "grey-text": disabled })}
        selected={(selected && selected.content) || placeholder}
      >
        <ErrorMessage name={name} />
      </MDBSelectInput>
      <MDBSelectOptions>
        {options.map(option => (
          <MDBSelectOption key={option.value} value={option.value}>
            {option.content}
          </MDBSelectOption>
        ))}
      </MDBSelectOptions>
    </MDBSelect>
  );
};
