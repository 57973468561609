import React from 'react';
import { useQuery } from 'react-query';
import { Box as MuiBox, CircularProgress, Paper } from '@material-ui/core';

import { RepairService } from '../../../../services/repair/Repair.service';
import { UserService } from '../../../../services/user/User.service';

import { RepairNote } from '../../../../models/repair/RepairNote';

import { BlueBox } from '../../../common/box/BlueBox';

export interface RepairDetailsListProps {
  repairId: string;
  repairService: RepairService;
  userService: UserService;
}

export const RepairNotesList = ({
  repairId,
  repairService,
  userService,
}: RepairDetailsListProps) => {
  const { isLoading, error, data } = useQuery<RepairNote[], Error>(
    `repair-${repairId}-notes`,
    () =>
      repairService
        .getRepairNotes(repairId)
        .then((list) =>
          list.sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf()),
        ),
    { cacheTime: 5 * 1000, refetchInterval: 10000 },
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <div>
        <p>
          <b>Wystąpił błąd</b>
        </p>
        <pre>
          {JSON.stringify(error.message ? error.message : error, null, 2)}
        </pre>
      </div>
    );
  }

  if (!data) {
    return <div>Brak notatek</div>;
  }

  return (
    <MuiBox display="flex" flexDirection="column">
      {data.map((note) => {
        const isCurrentUser = userService.user.id === note.user.id;
        const Box = isCurrentUser ? BlueBox : MuiBox;

        return (
          <MuiBox
            key={note.id}
            width="75%"
            alignSelf={isCurrentUser ? 'flex-end' : 'flex-start'}
          >
            {note.sendToClient && (
              <MuiBox>
                <strong>Wiadomość oznaczona do wysłania do klienta:</strong>
              </MuiBox>
            )}
            <Box
              component={Paper}
              p={1}
              m={1}
              display="flex"
              flexDirection="column"
            >
              <MuiBox style={{ whiteSpace: 'pre-wrap' }}>{note.content}</MuiBox>
              <MuiBox alignSelf="flex-end">
                <strong>{note.user.profile.viewName}</strong>{' '}
                {note.createdAt.toLocaleString()}
              </MuiBox>
            </Box>
          </MuiBox>
        );
      })}
    </MuiBox>
  );
};

export default RepairNotesList;
