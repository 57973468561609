import { MaybeNull } from "../../../definitions/MaybeNull";
import {
  CreatePartnerMarginDTO,
  PartnerMarginDTO
} from "../../partner/PartnerMargin";

export interface DeviceBrandDTO {
  id: string;
  name: string;
  systemMargin: MaybeNull<number>;
  partnerMargins: MaybeNull<PartnerMarginDTO[]>;
}

export interface CreateDeviceBrandDTO {
  name: string;
  systemMargin: MaybeNull<number>;
  partnerMargins: CreatePartnerMarginDTO[];
}

export interface UpdateDeviceBrandDTO extends CreateDeviceBrandDTO {
  id: string;
}

export class DeviceBrand {
  private _id: string;
  private _name: string;
  private _systemMargin: MaybeNull<number>;
  private _partnerMargins: MaybeNull<PartnerMarginDTO[]>;

  constructor(brand: DeviceBrandDTO) {
    this._id = brand.id;
    this._name = brand.name;
    this._systemMargin = brand.systemMargin;
    this._partnerMargins = brand.partnerMargins;
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get systemMargin(): MaybeNull<number> {
    return this._systemMargin;
  }

  public get partnerMargins(): MaybeNull<PartnerMarginDTO[]> {
    return this._partnerMargins;
  }

  public set id(id: string) {
    this._id = id;
  }

  public set name(name: string) {
    this._name = name;
  }

  public set systemMargin(systemMargin: MaybeNull<number>) {
    this._systemMargin = systemMargin;
  }

  public set partnerMargins(partnerMargins: MaybeNull<PartnerMarginDTO[]>) {
    this._partnerMargins = partnerMargins;
  }
}
