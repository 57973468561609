import React from 'react';
import { Box as MuiBox, CircularProgress, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';

import { Repair } from '../../../models/repair/Repair';
import { RepairAssignedService } from '../../../models/repair/RepairAssignedService';

import { RepairService } from '../../../services/repair/Repair.service';

export interface RepairPartsTabProps {
  repair: Repair;
  repairService: RepairService;
}

export const RepairPartsTab = ({
  repair,
  repairService,
}: RepairPartsTabProps) => {
  const { isLoading, error, data } = useQuery<RepairAssignedService[], Error>(
    `repair-${repair.id}-parts`,
    () => repairService.getRepairParts({ repairId: repair.id }),
    { cacheTime: 15 * 1000 },
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <div>
        <p>
          <b>Wystąpił błąd</b>
        </p>
        <pre>
          {JSON.stringify(error.message ? error.message : error, null, 2)}
        </pre>
      </div>
    );
  }

  if (!data || !data.length) {
    return <div>Brak przypisanych usług</div>;
  }

  const servicesWithParts = data.filter(
    (service) => service.warehouseItems.length,
  );

  if (!servicesWithParts.length) {
    return <div>Brak przypisanych części</div>;
  }

  return (
    <MuiBox>
      <ul>
        {servicesWithParts.map((service) => (
          <li key={service.id}>
            <Typography>{service.name}</Typography>
            <ul>
              {service.warehouseItems.map((item) => (
                <li
                  key={item.id}
                  style={{ color: !item.quantity ? 'red' : '' }}
                >
                  {item.name} (Dostępna ilość: {item.quantity})
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </MuiBox>
  );
};

export default RepairPartsTab;
