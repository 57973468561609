import React from 'react';
import { Field, FieldProps } from 'formik-latest';
import moment, { Moment } from 'moment';

import { TimePicker as MuiTimePicker } from '@material-ui/pickers';
import { TimeValidationProps } from '@material-ui/pickers/_helpers/time-utils';
import TextField from '@material-ui/core/TextField';

export type PickerProps = {
  name: string;
  label: string;
  placeholder: string;
  defaultValue?: Moment;
};

export function TimePicker<T>(props: PickerProps & TimeValidationProps) {
  return (
    <Field name={props.name}>
      {({ field, meta, form }: FieldProps<T>) => {
        return (
          <MuiTimePicker
            {...field}
            value={field.value || props.defaultValue}
            ampm={false}
            onChange={(value) => {
              form.setFieldTouched(field.name);
              form.setFieldValue(field.name, value && moment(value));
            }}
            minutesStep={15}
            minTime={props.minTime}
            maxTime={props.maxTime}
            disableIgnoringDatePartForTimeValidation={false}
            renderInput={(params) => (
              <TextField
                {...params}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                variant="outlined"
              />
            )}
          />
        );
      }}
    </Field>
  );
}
