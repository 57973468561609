import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import { BrandModelService } from '../../../../../services/device/brand/model/BrandModel.service';
import { DeviceBrandService } from '../../../../../services/device/brand/DeviceBrand.service';
import { DeviceTypeService } from '../../../../../services/device/DeviceType.service';
import { DrawerService } from '../../../../../services/drawer/Drawer.service';
import { UserService } from '../../../../../services/user/User.service';

import SimpleList, {
  SimpleListProps,
} from '../../../../common/list/simple-list/SimpleList';

import { BrandModel } from '../../../../../models/device/brand/model/BrandModel';
import { PartnerMarginDTO } from '../../../../../models/partner/PartnerMargin';

export class BrandModelList extends PureComponent<{
  brandModelService: BrandModelService;
  deviceBrandService: DeviceBrandService;
  deviceTypeService: DeviceTypeService;
  drawerService: DrawerService;
  userService: UserService;
}> {
  public render() {
    const {
      userService: { user },
    } = this.props;

    const props: SimpleListProps = {
      title: 'Lista modeli',
      description: 'Tutaj możesz zarzadząć modelami',
      createButtonText: 'dodaj model',
      filters: ['deviceType', 'brand'],
      columns: [
        {
          label: 'Typ',
          name: 'type.name',
        },
        {
          label: 'Marka',
          name: 'brand.name',
        },
        {
          label: 'Model',
          name: 'name',
        },
        {
          label: 'Marża systemowa (%)',
          name: 'systemMargin',
        },
      ],
      fetchList: (filterValues: any) => {
        return this.props.brandModelService
          .list({
            typeId: filterValues.deviceType,
            brandId: filterValues.brand,
          })
          .then(list =>
            list.map(model => ({
              id: model.id,
              name: model.name,
              type: model.type ? model.type : { name: '' },
              brand: model.brand ? model.brand : { name: '' },
              shopItemsIds: model.shopItemsIds,
              systemMargin:
                typeof model.systemMargin === 'number'
                  ? model.systemMargin
                  : '-',
            })),
          );
      },
    };

    if (user!.isAdmin()) {
      props.editHandler = ({
        onEdit,
        row,
      }: {
        onEdit: Function;
        row: BrandModel;
      }) => {
        this.props.drawerService.activate('edit-device-model', {
          simpleFormProps: {
            initialValues: {
              id: row.id,
              brandId: row.brand.id,
              typeId: row.type && row.type.id,
              name: row.name,
              shopItemsIds: row.shopItemsIds.join(', '),
              systemMargin: row.systemMargin,
              ...(row.partnerMargins || []).reduce(
                (
                  obj: { [key: string]: number },
                  partnerMargin: PartnerMarginDTO,
                ) => {
                  obj[`partner_${partnerMargin.id}`] = partnerMargin.margin;

                  return obj;
                },
                {},
              ),
            },
            onResolved: onEdit,
          },
        });
      };

      props.deleteHandler = ({
        onDelete,
        row,
      }: {
        onDelete: Function;
        row: BrandModel;
      }) => this.props.brandModelService.remove(row.id).then(() => onDelete());

      props.createHandler = ({ onCreate }: { onCreate: Function }) =>
        this.props.drawerService.activate('create-brand-model', {
          simpleFormProps: {
            onResolved: onCreate,
          },
        });
    }

    return <SimpleList {...props} />;
  }
}

export default wire(
  [
    'deviceTypeService',
    'deviceBrandService',
    'brandModelService',
    'drawerService',
    'userService',
  ],
  BrandModelList,
);
