export interface QueueJobDTO {
  name: string;
  opts: {
    attempts: number;
    delay: number;
    timestamp: number;
  };
  data: any;
  _progress: number;
  delay: number;
  returnvalue: any;
  attemptsMade: number;
  id: string;
  finishedOn: number;
  processedOn: number;
}

export class QueueJob {
  private _name: string;
  private _opts: {
    attempts: number;
    delay: number;
    timestamp: number;
  };
  private _data: any;
  private _progress: number;
  private _delay: number;
  private _returnvalue: any;
  private _attemptsMade: number;
  private _id: string;
  private _finishedOn: number;
  private _processedOn: number;

  constructor(data: QueueJobDTO) {
    this._name = data.name;
    this._opts = data.opts;
    this._data = data.data;
    this._progress = data._progress;
    this._delay = data.delay;
    this._returnvalue = data.returnvalue;
    this._attemptsMade = data.attemptsMade;
    this._id = data.id;
    this._finishedOn = data.finishedOn;
    this._processedOn = data.processedOn;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get opts(): QueueJobDTO["opts"] {
    return this._opts;
  }

  get data(): any {
    return this._data;
  }

  get progress(): number {
    return this._progress;
  }

  get delay(): number {
    return this._delay;
  }

  get returnvalue(): string {
    return this._returnvalue;
  }

  get attemptsMade(): number {
    return this._attemptsMade;
  }

  get finishedOn(): number {
    return this._finishedOn;
  }

  get processedOn(): number {
    return this._processedOn;
  }
}
