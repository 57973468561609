import { ApiService } from '../Api.service';

import {
  EditUserPasswordDTO,
  User,
  UserDTO,
  UserRoles,
} from '../../models/user/User';
import { CustomerService } from '../customer/Customer.service';
import { BusinessCustomerService } from '../businessCustomer/BusinessCustomer.service';
import { PartnerService } from '../partner/Partner.service';
import { EmployeeService } from '../employee/Employee.service';
import { ListResponse } from '../../models/common/ListResponse';

export class UserService {
  private _user: User | null;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _partnerService: PartnerService,
    private readonly _customerService: CustomerService,
    private readonly _businessCustomerService: BusinessCustomerService,
    private readonly _employeeService: EmployeeService,
  ) {
    this._user = null;
  }

  public get user(): User {
    return this._user as User;
  }

  public async fetchDetails() {
    return await this._apiService
      .get<UserDTO>('/user/details')
      .then((user) => new User(user))
      .then((user) => (this._user = user));
  }

  public async register(userType: UserRoles, values: any): Promise<any> {
    const data = {
      ...values,
      nickname: values.nickname ? values.nickname : null,
      firstname: values.firstname ? values.firstname : null,
      surname: values.surname ? values.surname : null,
      phone: values.phone ? values.phone : null,
      emailAddress: values.email,
      password: values.password ? values.password : null,
      confirmPassword: values.confirmPassword ? values.confirmPassword : null,
      locationId: values.location,
    };

    switch (userType) {
      case UserRoles.admin:
        return this._apiService.post('/auth/register/admin', data);
      case UserRoles.employee:
        return this._employeeService.register(data);
      case UserRoles.partner:
        return this._partnerService.register(data);
      case UserRoles.customer:
        return this._customerService.register(data);
      case UserRoles.business_customer:
        return this._businessCustomerService.register(data);
      default:
        throw new Error('Unknown user type or type can not be registered');
    }
  }

  public async update(userType: UserRoles, values: any): Promise<void> {
    const data = {
      ...values,
      id: values.id,
      nickname:
        values.nickname && values.nickname.length ? values.nickname : null,
      firstname:
        values.firstname && values.firstname.length ? values.firstname : null,
      surname: values.surname && values.surname.length ? values.surname : null,
      emailAddress: values.email,
      phone: values.phone,
    };

    switch (userType) {
      case UserRoles.admin:
        return this._apiService.put(`/user/${values.id}`, data);
      case UserRoles.employee:
        return this._employeeService.update(data);
      case UserRoles.partner:
        return this._partnerService.update(data);
      case UserRoles.customer:
        return this._customerService.update(data);
      case UserRoles.business_customer:
        return this._businessCustomerService.update(data);
      default:
        return this._apiService.put(`/user/${values.id}`, data);
    }
  }

  public async updateUserPassword(values: EditUserPasswordDTO): Promise<void> {
    return await this._apiService.put(`/user/${values.id}/password`, values);
  }

  public async remove({ id }: { id: string }) {
    return await this._apiService.delete(`/user/${id}`);
  }

  public async list(
    {
      page,
      limit,
      searchTerm,
      locationId,
      role,
    }: {
      page: number;
      limit: number;
      searchTerm?: string;
      locationId?: string;
      role?: string;
    } = { page: 1, limit: 25 },
  ): Promise<ListResponse<User>> {
    let url = `/b2b/user/listing?limit=${limit}&offset=${(page - 1) * limit}`;

    if (searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }

    if (locationId) {
      url += `&locationId=${locationId}`;
    }

    if (role) {
      url += `&role=${role}`;
    }

    return await this._apiService
      .get<ListResponse<UserDTO>>(url)
      .then((response) => ({
        ...response,
        items: response.items.map((user) => new User(user)),
      }));
  }

  public async getAllTechnicians(): Promise<User[]> {
    return (await this._apiService
      .get<ListResponse<UserDTO>>('/employee/technician/list')
      .then((response) => response.items.map((user) => new User(user)))
      .catch((err) => {
        console.error(err);

        return [];
      })) as User[];
  }
}
