import React, { Fragment, PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { MDBBtn } from 'mdbreact';

import { UserService } from '../../../../services/user/User.service';
import { ApplicationService } from '../../../../services/application/Application.service';
import { DrawerService } from '../../../../services/drawer/Drawer.service';

import { UserRoles } from '../../../../models/user/User';

import Spinner from '../../../common/spinner/Spinner';

export interface SettingsState {
  loading: boolean;
}

export class Settings extends PureComponent<
  {
    userService: UserService;
    applicationService: ApplicationService;
    drawerService: DrawerService;
  },
  SettingsState
> {
  public state: SettingsState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount(): void {
    this.fetchConfig();
  }

  public render() {
    return (
      <Fragment>
        <div>
          <h2>Ustawienia</h2>
        </div>
        {this.state.loading ? (
          <div>
            <Spinner small />
          </div>
        ) : (
          <Fragment>
            <div>
              <b>Nazwa:</b> {this.props.applicationService.config!.name}
            </div>
            <div>
              <b>Logo:</b> {this.props.applicationService.config!.logo}
            </div>
            <div>
              <b>System margin (%):</b>{' '}
              {this.props.applicationService.config!.systemMargin}
            </div>
            <hr />
            <h2>Naprawy</h2>
            <div>
              <b>Tworzenie nowych napraw: </b>
              {this.props.applicationService.config!.isNewRepairEnabled ? (
                'Włączone'
              ) : (
                <span className="red-text font-weight-bold">WYŁĄCZONY</span>
              )}
            </div>
            <div>
              <b>Kurier: </b>
              {this.props.applicationService.config!.isRepairCourierEnabled ? (
                'Włączony'
              ) : (
                <span className="red-text font-weight-bold">WYŁĄCZONY</span>
              )}
            </div>
            <hr />
            <h2>Aplikacja mobilna</h2>
            <div>
              <b>Włączona: </b>
              {this.props.applicationService.config!.isMobileAppEnabled ? (
                'Tak'
              ) : (
                <span className="red-text font-weight-bold">Nie</span>
              )}
            </div>
            <div>
              <b>Rejestracja: </b>
              {this.props.applicationService.config!
                .isMobileAppRegisterEnabled ? (
                'Tak'
              ) : (
                <span className="red-text font-weight-bold">Nie</span>
              )}
            </div>
            <div>
              <b>Logowanie: </b>
              {this.props.applicationService.config!.isMobileAppLoginEnabled ? (
                'Tak'
              ) : (
                <span className="red-text font-weight-bold">Nie</span>
              )}
            </div>
            <div>
              <b>Resetowanie hasła: </b>
              {this.props.applicationService.config!
                .isMobileAppPasswordResetEnabled ? (
                'Tak'
              ) : (
                <span className="red-text font-weight-bold">Nie</span>
              )}
            </div>
            {this.props.userService.user!.hasRole(UserRoles.su) ? (
              <Fragment>
                <hr />
                <h2>SU</h2>
                <div>
                  <b>Service margin: </b>
                  {this.props.applicationService.config!.isServiceMarginEnabled
                    ? 'Tak'
                    : 'Nie'}
                </div>
                <div>
                  {' '}
                  <b>Enabled Modules:</b>
                  <ul>
                    {this.props.applicationService.config!.enabledModules.map(
                      moduleName => (
                        <li>{moduleName}</li>
                      ),
                    )}
                  </ul>
                </div>
              </Fragment>
            ) : null}
            <MDBBtn
              color="primary"
              className="p-3"
              onClick={() =>
                this.props.drawerService.activate('edit-application-settings', {
                  simpleFormProps: {
                    initialValues: {
                      name: this.props.applicationService.config!.name,
                      logo: this.props.applicationService.config!.logo,
                      systemMargin: this.props.applicationService.config!
                        .systemMargin,
                      enabledModules: this.props.applicationService.config!.enabledModules.join(
                        ',',
                      ),
                      serviceMargin: this.props.applicationService.config!
                        .isServiceMarginEnabled,
                      enableMobileApp: this.props.applicationService.config!
                        .isMobileAppEnabled,
                      enableMobileAppRegister: this.props.applicationService
                        .config!.isMobileAppRegisterEnabled,
                      enableMobileAppLogin: this.props.applicationService
                        .config!.isMobileAppLoginEnabled,
                      enableMobileAppPassReset: this.props.applicationService
                        .config!.isMobileAppPasswordResetEnabled,
                      enableNewRepair: this.props.applicationService.config!
                        .isNewRepairEnabled,
                      enableRepairCourier: this.props.applicationService.config!
                        .isRepairCourierEnabled,
                    },
                    onResolved: () => this.fetchConfig(),
                  },
                })
              }
            >
              Edit
            </MDBBtn>
          </Fragment>
        )}
      </Fragment>
    );
  }

  private async fetchConfig() {
    this.setState({
      loading: true,
    });
    await this.props.applicationService.fetchConfig();
    this.setState({
      loading: false,
    });
  }
}
export default wire(
  ['userService', 'applicationService', 'drawerService'],
  Settings,
);
