import React from 'react';
import { useSheetsQueue } from '@rootsher/use-sheets-queue';
import { Form, Formik } from 'formik-latest';
import { wire } from 'react-hot-wire';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { TextField } from '../../../form/field/text-field/input';
import { Select } from '../../../form/field/select/select';
import { RepairValues } from '../../create';
import { DeviceTypeService } from '../../../../../services/device/DeviceType.service';
import { DeviceBrandService } from '../../../../../services/device/brand/DeviceBrand.service';
import { BrandModelService } from '../../../../../services/device/brand/model/BrandModel.service';
import { RepairService } from '../../../../../services/repair/Repair.service';

export type DeviceValues = {
  device_type?: string;
  device_brand?: string;
  device_model?: string;
  identifier?: string;
  password?: string;
};

type DeviceCreateProps = {
  deviceTypeService: DeviceTypeService;
  deviceBrandService: DeviceBrandService;
  brandModelService: BrandModelService;
  repairService: RepairService;
  options: any;
};

const DeviceCreateSchema = Yup.object().shape({
  device_model: Yup.string().required('Required'),
  identifier: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

export const DeviceCreate = ({
  deviceTypeService,
  deviceBrandService,
  brandModelService,
  repairService,
  options,
}: DeviceCreateProps) => {
  const [, pop] = useSheetsQueue();

  function onSubmit(values: DeviceValues) {
    return repairService
      .createDevice(values)
      .then((values) => options.onSubmit(values))
      .finally(pop);
  }

  return (
    <Formik
      initialValues={{}}
      isInitialValid={false}
      validationSchema={DeviceCreateSchema}
      onSubmit={onSubmit}
    >
      {({ values, isValid, setFieldValue }) => {
        return (
          <Form>
            <div className="p-4">
              <Typography variant="h5" align="center" className="my-3">
                Tworzenie urządzenia
              </Typography>
              <div className="mb-3">
                <Select<RepairValues>
                  name="device_type"
                  onChange={() => setFieldValue('device_model', undefined)}
                  fetch={() =>
                    deviceTypeService.list().then((list) =>
                      list.map((type) => ({
                        value: type.id,
                        content: type.name,
                      })),
                    )
                  }
                  label="Wybierz typ urządzenia"
                />
              </div>
              <div className="mb-3">
                <Select<RepairValues>
                  name="device_brand"
                  onChange={() => setFieldValue('device_model', undefined)}
                  fetch={() =>
                    deviceBrandService.list().then((list) =>
                      list.map((type) => ({
                        value: type.id,
                        content: type.name,
                      })),
                    )
                  }
                  label="Wybierz markę"
                />
              </div>
              <Select<RepairValues>
                key={`${values.device_type} ${values.device_brand}`}
                name="device_model"
                disabled={!values.device_type || !values.device_brand}
                fetch={() =>
                  brandModelService
                    .list({
                      typeId: values.device_type,
                      brandId: values.device_brand,
                    })
                    .then((list) =>
                      list.map((type) => ({
                        value: type.id,
                        content: type.name,
                      })),
                    )
                }
                label="Wybierz model *"
              />
              <TextField
                name="identifier"
                label="Identyfikator (S/N, IMEI itp.) *"
                placeholder="Wpisz identyfikator urządzenia..."
              />
              <TextField
                name="password"
                label="Hasło do urządzenia"
                placeholder="Wpisz hasło do urządzenia..."
              />
              <div className="mt-4">
                <Button color="primary" className="mr-3" onClick={pop}>
                  anuluj
                </Button>
                <Button
                  type="submit"
                  disabled={!isValid}
                  color="primary"
                  variant="contained"
                >
                  utwórz urządzenie
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default wire(
  [
    'deviceTypeService',
    'deviceBrandService',
    'brandModelService',
    'repairService',
  ],
  DeviceCreate,
);
