import LocationPath from './location';
import LocationExpressPath from './location-express';
import { CourierPath } from './courier';
import { RepairValues } from '../../../create';

export type PathProps<T = {}> = {
  send: (stage: string) => void;
  reset: () => void;
  values: RepairValues;
} & T;

export default {
  location: LocationPath,
  location_express: LocationExpressPath,
  courier: CourierPath,
};
