import { ApiService } from "../Api.service";
import { CreateDeviceDTO } from "../../models/device/Device";

export interface DeviceServiceInterface {
  create(values: CreateDeviceDTO): Promise<void>;
}

export class DeviceService implements DeviceServiceInterface {
  private _apiService: ApiService;

  constructor(apiService: ApiService) {
    this._apiService = apiService;
  }

  public async create(values: CreateDeviceDTO): Promise<void> {
    return await this._apiService.post("/device", {
      modelId: values.modelId,
      identifier: String(values.identifier),
      unlockPassword: String(values.unlockPassword)
    });
  }
}
