import { AccessTokenService } from '../AccessToken.service';
import { LogService } from '../Log.service';

export interface RealtimeCommunicationEventInterface {
  name: string;
  data: any;
}

export interface RealtimeCommunicationAdapterInterface {
  send(event: RealtimeCommunicationEventInterface): void;
  subscribe(
    eventName: string,
    cb: (event: RealtimeCommunicationEventInterface) => void,
  ): () => void;
  authenticate(accessToken: string | null): void;
}

export class RealtimeCommunicationService {
  private _adapters: {
    [name: string]: RealtimeCommunicationAdapterInterface;
  } = {};

  constructor(
    private readonly accessTokenService: AccessTokenService,
    private readonly logService: LogService,
    adapters: RealtimeCommunicationAdapterInterface[],
  ) {
    this.loadAdapters(adapters);
    this.manageAuth();
  }

  public subscribe(
    eventName: string,
    cb: (
      event: RealtimeCommunicationEventInterface,
      adapterName: string,
    ) => void,
    adapterName?: string,
  ): () => void {
    if (adapterName && this._adapters[eventName]) {
      return this._adapters[eventName].subscribe(eventName, (event) =>
        cb(event, adapterName),
      );
    }

    if (adapterName && !this._adapters[eventName]) {
      throw new Error(
        `[RealtimeCommunicationService] Unknown adapter "${eventName}"`,
      );
    }

    const unsubscribeFunctions: Array<() => void> = [];

    Object.entries(this._adapters).forEach(([adapterName, adapter]) =>
      unsubscribeFunctions.push(
        adapter.subscribe(eventName, (event) => cb(event, adapterName)),
      ),
    );

    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }

  private loadAdapters(
    adapters: RealtimeCommunicationAdapterInterface[],
  ): void {
    adapters.forEach(
      (adapter) => (this._adapters[adapter.constructor.name] = adapter),
    );
  }

  private manageAuth(): void {
    Object.entries(this._adapters).forEach(([name, adapter]) => {
      adapter.subscribe('authenticated', () =>
        this.logService.info(`[${name}] Authenticated`),
      );
      adapter.subscribe('unauthenticated', (data: unknown) =>
        this.logService.error(`[${name}] Authentication failed`, data),
      );
      adapter.subscribe('reconnect', () => {
        if (this.accessTokenService.hasAccessToken()) {
          this.onAccessTokenChange(this.accessTokenService.accessToken);
        }
      });
    });

    this.accessTokenService.subscribe((accessToken) =>
      this.onAccessTokenChange(accessToken),
    );

    if (this.accessTokenService.hasAccessToken()) {
      this.onAccessTokenChange(this.accessTokenService.accessToken);
    }
  }

  private onAccessTokenChange(accessToken: string | null): void {
    Object.values(this._adapters).forEach((adapter) =>
      adapter.authenticate(accessToken),
    );
  }
}
