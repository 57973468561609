import { ApiService } from "../../Api.service";
import {
  CreateDeviceBrandDTO,
  DeviceBrand,
  DeviceBrandDTO,
  UpdateDeviceBrandDTO
} from "../../../models/device/brand/DeviceBrand";

export interface BrandServiceInterface {
  create(values: CreateDeviceBrandDTO): Promise<void>;
  getOne(id: string): Promise<DeviceBrand>;
  list(): Promise<DeviceBrand[]>;
  remove(id: string): Promise<void>;
  update(values: UpdateDeviceBrandDTO): Promise<void>;
}

export class DeviceBrandService implements BrandServiceInterface {
  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async create(values: CreateDeviceBrandDTO): Promise<void> {
    return await this._apiService.post("/brand", {
      name: values.name,
      systemMargin: values.systemMargin,
      partnerMargins: values.partnerMargins
    });
  }

  public async remove(id: string | null): Promise<void> {
    return await this._apiService.delete(`/brand/${id}`);
  }
  public async update(values: UpdateDeviceBrandDTO): Promise<void> {
    return await this._apiService.put(`/brand/${values.id}`, {
      name: values.name,
      systemMargin: values.systemMargin,
      partnerMargins: values.partnerMargins
    });
  }

  public async getOne(id: string | null): Promise<DeviceBrand> {
    return await this._apiService
      .get<DeviceBrandDTO>(`/brand/${id}`)
      .then((brand: DeviceBrandDTO) => new DeviceBrand(brand));
  }

  public async list(): Promise<DeviceBrand[]> {
    return await this._apiService
      .get<DeviceBrandDTO[]>("/brand")
      .then(list =>
        list.map((brand: DeviceBrandDTO) => new DeviceBrand(brand))
      );
  }
}
