import React from 'react';
import { Box } from '@material-ui/core';
import { useQueryClient } from 'react-query';

import { RepairService } from '../../../../services/repair/Repair.service';
import { UserService } from '../../../../services/user/User.service';

import { Repair } from '../../../../models/repair/Repair';

import RepairNotesList from './repair-notes-list';
import RepairAddNote from './repair-add-note';

export interface RepairDetailsTabProps {
  repair: Repair;
  repairService: RepairService;
  userService: UserService;
}

export const RepairNotesTab = ({
  repair,
  repairService,
  userService,
}: RepairDetailsTabProps) => {
  const queryClient = useQueryClient();

  return (
    <Box>
      <Box>
        <RepairNotesList
          repairService={repairService}
          userService={userService}
          repairId={repair.id}
        />
      </Box>
      <Box mt={2}>
        <RepairAddNote
          repairService={repairService}
          repair={repair}
          onNoteAdded={() => {
            queryClient.refetchQueries({
              queryKey: `repair-${repair.id}-notes`,
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default RepairNotesTab;
