import { User, UserDTO } from '../user/User';
import {
  CustomerProfile,
  CustomerProfileDTO,
} from '../customer/CustomerProfile';
import { RepairStatus } from './RepairStatus';
import { RepairOrigin } from './repair-origin';
import {
  RepairAssignedService,
  RepairAssignedServiceDTO,
} from './RepairAssignedService';
import { Device, DeviceDTO } from '../device/Device';
import { Location, LocationDTO } from '../location/Location';
import { GuestCustomer, GuestCustomerDTO } from '../user/guest-customer';

export interface RepairDTO {
  id: string;
  number: string;
  origin: RepairOrigin;
  deviceReceivedFromClient: boolean;
  description: string;
  deviceStatusDescription: string;
  accessoriesList: string;
  contactEmail: string;
  contactPhone: string;
  reporter?: UserDTO;
  status: RepairStatus;
  assignee?: UserDTO;
  assignedTechnician: UserDTO;
  customer?: CustomerProfileDTO;
  guestCustomer: GuestCustomerDTO;
  device: DeviceDTO;
  services: RepairAssignedServiceDTO[];
  location?: LocationDTO;
  courierOrder?: RepairCourierOrderDTO;
  express?: RepairExpressDTO;
  returnAddress?: string;
  changes: RepairChangeEventDTO[];
  created_at: string;
  updated_at: string;
}

export interface RepairCourierOrderDTO {
  address: string;
  hourFrom: number;
  hourTo: number;
}

export interface RepairExpressDTO {
  locationName: string;
  date: number;
  location?: LocationDTO;
}

export interface RepairCourierOrder {
  address: string;
  hourFrom: Date;
  hourTo: Date;
}

export interface RepairExpress {
  locationName: string;
  date: Date;
  location?: LocationDTO;
}

export interface RepairChangeEventDTO {
  changeType: string;
  prevValue: string;
  newValue: string;
  user?: UserDTO;
  created_at: number;
}
export interface RepairChangeEvent {
  id: string;
  changeType: string;
  prevValue: string;
  newValue: string;
  user?: User;
  createdAt: Date;
}

export class Repair {
  private _id: string;
  private _number: string;
  private _origin: RepairOrigin;
  private _deviceReceivedFromClient: boolean;
  private _description: string;
  private _deviceStatusDescription: string;
  private _accessoriesList: string;
  private _contactEmail: string;
  private _contactPhone: string;
  private _reporter: User | undefined;
  private _status: RepairStatus;
  private _assignee: User | undefined;
  private _assignedTechnician?: User;
  private _customer: CustomerProfile | undefined;
  private _guestCustomer: GuestCustomer | undefined;
  private _device: Device;
  private _services: RepairAssignedService[];
  private _location: Location | undefined;
  private _courierOrder: RepairCourierOrder | undefined;
  private _express: RepairExpress | undefined;
  private _returnAddress: string | undefined;
  private _changes: RepairChangeEvent[];
  private _createdAt: Date;
  private _updatedAt: Date;

  constructor(repair: RepairDTO) {
    this._id = repair.id;
    this._number = repair.number;
    this._origin = repair.origin;
    this._deviceReceivedFromClient = repair.deviceReceivedFromClient;
    this._description = repair.description;
    this._deviceStatusDescription = repair.deviceStatusDescription;
    this._accessoriesList = repair.accessoriesList;
    this._contactEmail = repair.contactEmail;
    this._contactPhone = repair.contactPhone;
    this._reporter = repair.reporter && new User(repair.reporter);
    this._status = repair.status;
    this._assignee = repair.assignee && new User(repair.assignee);
    this._assignedTechnician = repair.assignedTechnician
      ? new User(repair.assignedTechnician)
      : undefined;
    this._customer = repair.customer && new CustomerProfile(repair.customer);
    this._guestCustomer =
      repair.guestCustomer && new GuestCustomer(repair.guestCustomer);
    this._device = new Device(repair.device);
    this._services = (repair.services || []).map(
      (service) => new RepairAssignedService(service),
    );
    this._location = repair.location && new Location(repair.location);
    this._courierOrder = repair.courierOrder
      ? {
          ...repair.courierOrder,
          hourFrom: new Date(repair.courierOrder.hourFrom),
          hourTo: new Date(repair.courierOrder.hourTo),
        }
      : undefined;
    this._express = repair.express
      ? {
          ...repair.express,
          date: new Date(repair.express.date),
        }
      : undefined;
    this._changes = !Array.isArray(repair.changes)
      ? []
      : repair.changes.map((change) => ({
          ...change,
          id: `${change.created_at}-${change.changeType}`,
          createdAt: new Date(change.created_at),
          user: change.user ? new User(change.user) : undefined,
        }));
    this._returnAddress = repair.returnAddress;
    this._createdAt = new Date(repair.created_at);
    this._updatedAt = new Date(repair.updated_at);
  }

  public get id(): string {
    return this._id;
  }

  public get number(): string {
    return this._number;
  }

  public get origin(): RepairOrigin {
    return this._origin;
  }

  public get isDeviceReceivedFromClient(): boolean {
    return this._deviceReceivedFromClient;
  }

  public get description(): string {
    return this._description;
  }

  public get deviceStatusDescription(): string {
    return this._deviceStatusDescription;
  }

  public get accessoriesList(): string {
    return this._accessoriesList;
  }

  public get contactEmail(): string {
    return this._contactEmail;
  }

  public get contactPhone(): string {
    return this._contactPhone;
  }

  public get reporter(): User | undefined {
    return this._reporter;
  }

  public get status(): RepairStatus {
    return this._status;
  }

  public get assignee(): User | undefined {
    return this._assignee;
  }

  public get assignedTechnician(): User | undefined {
    return this._assignedTechnician;
  }

  public get customer(): CustomerProfile | undefined {
    return this._customer;
  }

  public get guestCustomer(): GuestCustomer | undefined {
    return this._guestCustomer;
  }

  public get device(): Device {
    return this._device;
  }

  public get location(): Location | undefined {
    return this._location;
  }

  public get courierOrder(): RepairCourierOrder | undefined {
    return this._courierOrder;
  }

  public get express(): RepairExpress | undefined {
    return this._express;
  }

  public get returnAddress(): string | undefined {
    return this._returnAddress;
  }

  public get services(): RepairAssignedService[] {
    return this._services;
  }

  public get changes(): RepairChangeEvent[] {
    return this._changes;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }
}
