import React, { ReactElement } from 'react';
import _get from 'lodash/get';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

export interface SimpleTwoColTableRow<T extends object> {
  field: keyof T | string;
  name: string;
  key?: string;
  valueFormatter?: (value: any, data: T) => React.ReactNode;
  renderCell?: (value: any, data: T) => ReactElement;
}

export interface SimpleTwoColTableProps<T extends object> {
  data: T;
  columns?: [] | [string] | [string, string];
  rows: SimpleTwoColTableRow<T>[];
}

export const SimpleTwoColTable = <T extends object>({
  data,
  columns,
  rows,
}: SimpleTwoColTableProps<T>) => (
  <TableContainer component={Paper}>
    <Table aria-label="simple table">
      {columns && columns.length && (
        <TableHead>
          <TableRow>
            {Array.from({ length: 2 }, (v, k) => columns[k] || '').map(
              (columnName: string) => (
                <TableCell key={columnName}>{columnName}</TableCell>
              ),
            )}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((row: SimpleTwoColTableRow<T>) => (
          <TableRow key={row.key || row.name}>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">
              {row.renderCell
                ? row.renderCell(_get(data, row.field), data)
                : row.valueFormatter
                ? row.valueFormatter(_get(data, row.field), data)
                : _get(data, row.field)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default SimpleTwoColTable;
