import * as Yup from 'yup';

import Device from './device/Device';
import Password from './Password';
import ModelSelect from './model/Model';
import Select, { SelectField } from './select/Select';
import Text from './Text';
import Textarea from './Textarea';
import { FormikProps } from 'formik';
import Checkbox from './Checkbox';

export interface DefaultField {
  type: string;
  name: string;
  label?: string;
  placeholder?: string;
  validator?: Yup.Schema<string | undefined>;
  requires?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
}

export type Field = DefaultField & SelectField;

export interface FieldProps {
  field: Field;
  formikProps: FormikProps<{ [key: string]: string }>;
}

export const types: { [key: string]: any } = {
  device: Device,
  password: Password,
  select: Select,
  model: ModelSelect,
  text: Text,
  textarea: Textarea,
  checkbox: Checkbox,
};
