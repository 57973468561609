import React, { PureComponent } from 'react';

import { FieldProps } from '../index';

import Select from './Select.template';

export interface SelectOption {
  value: string | number | undefined;
  content: string;
}

export interface SelectField {
  options?: Array<SelectOption>;
  fetchOptions?: Function;
}

export class SelectComponent extends PureComponent<FieldProps> {
  state: { options: Array<SelectOption> };

  constructor(props: FieldProps) {
    super(props);

    this.state = {
      options: this.props.field.options || [],
    };
  }

  componentDidMount() {
    const { fetchOptions } = this.props.field;

    if (fetchOptions) {
      fetchOptions().then((options: Array<SelectOption>) =>
        this.setState({ options }),
      );
    }
  }

  render() {
    return (
      <Select
        {...this.props}
        field={{ ...this.props.field, options: this.state.options }}
      />
    );
  }
}

export default SelectComponent;
