import React from 'react';

import { HistoryChangeHeaderProps } from '../history-change';
import { RepairStatusTranslation } from '../../../../../models/repair/RepairStatus';

export const HistoryStatusChangeHeader = ({
  event: { newValue },
}: HistoryChangeHeaderProps) => (
  <span>{RepairStatusTranslation[newValue] || newValue}</span>
);

export default HistoryStatusChangeHeader;
