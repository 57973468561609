import React, { PureComponent } from "react";

import Login from "./Login.template";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { wire } from "react-hot-wire";

import { AuthService } from "../../../services/auth/Auth.service";

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string().required()
});

export class LoginComponent extends PureComponent<{
  authService: AuthService;
}> {
  render() {
    return (
      <Formik
        initialValues={{}}
        validationSchema={schema}
        onSubmit={values => this.onSubmit(values)}
        render={(props: FormikProps<object>) => <Login {...props} />}
      />
    );
  }

  onSubmit(values: { [key: string]: string }) {
    this.props.authService.login({
      email: values.email,
      password: values.password
    });
  }
}

export default wire(["authService"], LoginComponent);
