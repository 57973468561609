import { MaybeNull } from 'definitions/MaybeNull';

import { BrandModel, BrandModelDTO } from '../device/brand/model/BrandModel';
import {
  CreatePartnerMarginDTO,
  PartnerMarginDTO,
} from '../partner/PartnerMargin';
import { WarehouseItem, WarehouseItemDTO } from '../warehouse/WarehouseItem';
import { preciseAdd } from '../../helpers/decimalHelper';

export interface ServiceDTO {
  id: string;
  name: string;
  margin: number;
  system_margin: MaybeNull<number>;
  partner_margins: MaybeNull<PartnerMarginDTO[]>;
  priceNet: number;
  tax: number;
  duration: number;
  model?: BrandModelDTO;
  warehouseItems: WarehouseItemDTO[];
}

export interface CreateServiceDTO {
  name: string;
  margin: number;
  systemMargin: MaybeNull<number>;
  partnerMargins: CreatePartnerMarginDTO[];
  priceNet: number;
  tax: number;
  duration: number;
  modelId: string;
  warehouseItemsIds: string;
}

export interface EditServiceDTO extends CreateServiceDTO {
  id: string;
}

export class Service {
  private _id: string;
  private _name: string;
  private _priceNet: number;
  private _tax: number;
  private _duration: number;
  private _margin: number;
  private _systemMargin: MaybeNull<number>;
  private _model?: BrandModel;
  private _partnerMargins: MaybeNull<PartnerMarginDTO[]>;
  private _warehouseItems: WarehouseItem[];

  constructor(service: ServiceDTO) {
    this._id = service.id;
    this._name = service.name;
    this._priceNet = service.priceNet;
    this._tax = service.tax;
    this._duration = service.duration;
    this._margin = service.margin;
    this._systemMargin = service.system_margin;
    this._partnerMargins = service.partner_margins;
    this._model = service.model && new BrandModel(service.model);
    this._warehouseItems = (service.warehouseItems || []).map(
      item => new WarehouseItem(item),
    );
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get price(): number {
    return preciseAdd(this.priceNet, this.priceNet * (this.tax / 100), 2);
  }

  public get priceNet(): number {
    return this._priceNet;
  }

  public get tax(): number {
    return this._tax;
  }

  public get duration(): number {
    return this._duration;
  }

  public get margin(): number {
    return this._margin;
  }

  public get systemMargin(): MaybeNull<number> {
    return this._systemMargin;
  }

  public get partnerMargins(): MaybeNull<PartnerMarginDTO[]> {
    return this._partnerMargins;
  }

  public get model(): BrandModel | undefined {
    return this._model;
  }

  public get warehouseItems(): WarehouseItem[] {
    return this._warehouseItems;
  }

  public set id(id) {
    this._id = id;
  }

  public set name(name) {
    this._name = name;
  }

  public set priceNet(price) {
    this._priceNet = price;
  }

  public set tax(tax) {
    this._tax = tax;
  }

  public set duration(duration) {
    this._duration = duration;
  }

  public set margin(margin) {
    this._margin = margin;
  }

  public set systemMargin(systemMargin) {
    this._systemMargin = systemMargin;
  }

  public set partnerMargins(partnerMargins: MaybeNull<PartnerMarginDTO[]>) {
    this._partnerMargins = partnerMargins;
  }

  public set model(model) {
    this._model = model;
  }
}
