import { Service, ServiceDTO } from '../service/Service';

export interface RepairAssignedServiceDTO extends ServiceDTO {
  originalServiceId: string;
}

export interface AddOrUpdateRepairAssignedServiceDTO {
  serviceId?: string;
  repairServiceId?: string;
}

export class RepairAssignedService extends Service {
  private _originalServiceId: string;

  constructor(service: RepairAssignedServiceDTO) {
    super(service);

    this._originalServiceId = service.originalServiceId;
  }

  public get originalServiceId(): string {
    return this._originalServiceId;
  }
}
