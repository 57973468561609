import React, { PureComponent } from "react";
import { wire } from "react-hot-wire";
import { isEmpty } from "lodash";

import BrandModel from "./BrandModel.template";
import DeviceType from "../device-type/DeviceType";
import Brand from "../brand/Brand";
import { BrandModelService } from "../../../../../../services/device/brand/model/BrandModel.service";
import { BrandModel as Option } from "../../../../../../models/device/brand/model/BrandModel";

export interface Props {
  onChange: Function;
  brandModelService: BrandModelService;
}

export class BrandModelComponent extends PureComponent<Props> {
  state: {
    options: Option[];
    filters: { [key: string]: any };
  } = {
    options: [],
    filters: {}
  };

  render() {
    return (
      <div className="d-flex">
        <div className="ml-4">
          <DeviceType
            onChange={(typeId: string) =>
              this.onFilterChange("deviceType", typeId)
            }
          />
        </div>
        <div className="ml-4">
          <Brand
            onChange={(brandId: string) =>
              this.onFilterChange("brand", brandId)
            }
          />
        </div>
        {!isEmpty(this.state.filters) && (
          <div className="ml-4">
            <BrandModel {...this.props} options={this.state.options} />
          </div>
        )}
      </div>
    );
  }

  private onFilterChange(name: string, value: string) {
    const filters = {
      ...this.state.filters,
      [name]: value
    };

    this.setState({ filters }, () => this.fetchOptions());
  }

  private async fetchOptions() {
    this.setState({
      options: await this.props.brandModelService.list({
        typeId: this.state.filters.deviceType,
        brandId: this.state.filters.brand
      })
    });
  }
}

export default wire(["brandModelService"], BrandModelComponent);
