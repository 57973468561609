import brand from "./brand";
import { DeviceTypeService } from "./DeviceType.service";
import { DeviceService } from "./Device.service";

export default {
  ...brand,
  deviceTypeService: {
    class: DeviceTypeService,
    public: true,
    arguments: [{ $ref: "apiService" }]
  },
  deviceService: {
    class: DeviceService,
    public: true,
    arguments: [{ $ref: "apiService" }]
  }
};
