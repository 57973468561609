import React, { Fragment } from 'react';
import { Field, FieldProps } from 'formik-latest';
import { useQuery } from 'react-query';

import MUIFormControl from '@material-ui/core/FormControl';
import MUIInputLabel from '@material-ui/core/InputLabel';
import MUISelect from '@material-ui/core/Select';
import MUIMenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

export type SelectProps = {
  name: string;
  label: string;
  onChange?: (value: string) => void;
  fetch: () => Promise<{ value: string | number; content: string }[]>;
  disabled?: boolean;
  multiple?: boolean;
};

export function Select<T>({
  label,
  name,
  onChange,
  disabled,
  ...props
}: SelectProps) {
  const { isLoading, data = [] } = useQuery(name, props.fetch);

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps<T>) => (
        <MUIFormControl
          variant="outlined"
          error={meta.touched && !!meta.error}
          key={name}
        >
          <MUIInputLabel>
            {isLoading ? <CircularProgress size={18} /> : label}
          </MUIInputLabel>
          <Fragment>
            <MUISelect
              defaultValue=""
              variant="outlined"
              fullWidth
              MenuProps={{
                style: { zIndex: 3000 },
              }}
              disabled={disabled || isLoading || data.length === 0}
              {...field}
              {...props}
              onChange={event => {
                field.onChange(event);

                if (onChange) {
                  onChange(event.target.value as string);
                }
              }}
            >
              {data.map(option => (
                <MUIMenuItem key={option.value} value={option.value}>
                  {option.content}
                </MUIMenuItem>
              ))}
            </MUISelect>
            {meta.touched && meta.error && (
              <FormHelperText>{meta.error}</FormHelperText>
            )}
          </Fragment>
        </MUIFormControl>
      )}
    </Field>
  );
}
