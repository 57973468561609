import React from 'react';

import { HistoryChangeHeaderProps } from '../history-change';

export const HistoryAssignedTechnicianChangeHeader = ({
  event: { newValue },
}: HistoryChangeHeaderProps) => {
  let parsed: any = {};

  try {
    parsed = JSON.parse(newValue);
  } catch (e) {}

  return <span>{`${parsed.userFirstname} ${parsed.userSurname}`}</span>;
};

export default HistoryAssignedTechnicianChangeHeader;
