import moment from 'moment';

import { ApiService } from '../Api.service';

import {
  Repair,
  RepairChangeEvent,
  RepairChangeEventDTO,
  RepairDTO,
} from '../../models/repair/Repair';
import { ClientValues } from '../../components/repair/create/steps/client/client-create';
import { DeviceValues } from '../../components/repair/create/steps/device/device-create';
import { RepairValues } from '../../components/repair/create/create';
import { ListResponse } from '../../models/common/ListResponse';
import { RepairStatus } from '../../models/repair/RepairStatus';
import {
  AddOrUpdateRepairAssignedServiceDTO,
  RepairAssignedService,
  RepairAssignedServiceDTO,
} from '../../models/repair/RepairAssignedService';
import { User } from '../../models/user/User';
import { Location } from '../../models/location/Location';
import { Customer, CustomerDTO } from '../../models/customer/Customer';
import { Device, DeviceDTO } from '../../models/device/Device';
import { Service, ServiceDTO } from '../../models/service/Service';
import { RepairsBalanceOverallResponseDTO } from '../../models/repair/RepairBalanceStatistics';
import { AutocompleteOption } from '../../components/repair/form/field/autocomplete/autocomplete';
import {
  AddRepairNoteDTO,
  RepairNote,
  RepairNoteDTO,
} from '../../models/repair/RepairNote';

export interface RepairServiceInterface {
  getOne(id: string): Promise<Repair>;
  recent(): Promise<Repair[]>;
}

export class RepairService implements RepairServiceInterface {
  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async getOne(id: string): Promise<Repair> {
    return await this._apiService
      .get<RepairDTO>(`/repair/${id}`)
      .then((repair: RepairDTO) => new Repair(repair));
  }

  public async recent(): Promise<Repair[]> {
    return await this._apiService
      .get<RepairDTO[]>('/repair/recent')
      .then((list) => list.map((repair: RepairDTO) => new Repair(repair)));
  }

  public async create(values: RepairValues) {
    const output = {
      locationId:
        values.path === 'location'
          ? values.location!.id
          : values.path === 'location_express'
          ? values.location_express!.id
          : null,
      customerId: values.client?.value,
      deviceId: values.device,
      services: values.services!.map((option) => ({
        serviceId: option.value,
      })),
      courierOrder:
        values.path === 'courier'
          ? {
              date: values.courier?.date,
              address: values.courier?.address,
              hourFrom: values.courier?.time?.start?.format('HH:mm'),
              hourTo: values.courier?.time?.end?.format('HH:mm'),
            }
          : null,
      express:
        values.path === 'location_express'
          ? {
              date: values.location_express!.time,
            }
          : null,
      contactEmail: values.contact.email,
      contactPhone: values.contact.phone,
      deviceStatusDescription: values.note_device,
      accessoriesList: values.note_accessories,
      description: values.note,
      createdAt: values.createdAt,
    };

    return this._apiService.post('/repair', output);
  }

  public async location({ id }: { id: string }): Promise<Location> {
    return await this._apiService.get<Location>(`/location/${id}`);
  }

  public async locationList(): Promise<
    { value: string | number; content: string }[]
  > {
    const { items: list } = await this._apiService.get<{ items: Location[] }>(
      '/location/list/pickup',
    );

    return list.map((location) => ({
      value: location.id,
      content: location.name,
    }));
  }

  public async locationExpressList(): Promise<
    { value: string | number; content: string }[]
  > {
    const list = await this._apiService.get<{ location: Location }[]>(
      '/repair/express/available',
    );

    return list.map(({ location }) => ({
      value: location.id,
      content: location.name,
    }));
  }

  public async expressHours({
    location,
  }: {
    location: string;
  }): Promise<{ value: string | number; content: string }[]> {
    return this._apiService
      .get<{ notAvailable: boolean; available: string[] }>(
        `/repair/express/available/${location}`,
      )
      .then(({ notAvailable, available }) =>
        (notAvailable ? [] : available).map((date) => ({
          value: date,
          content: moment(date).format('HH:mm'),
        })),
      );
  }

  public async courierHours(): Promise<{
    [key: string]: { start: number | null; end: number | null };
  }> {
    return this._apiService.get('/repair/courier-order/work-hours');
  }

  public async client({ id }: { id?: string }): Promise<Customer> {
    const customer = await this._apiService.get<CustomerDTO>(`/customer/${id}`);

    return new Customer(customer);
  }

  public async clients(value: string): Promise<AutocompleteOption[]> {
    if (!value || value.length < 3) {
      return Promise.resolve([]);
    }

    const { items: list } = await this._apiService.get<{
      items: CustomerDTO[];
    }>(`/customer/search/${value}`);

    return list
      .map((customer) => new Customer(customer))
      .map((customer) => ({
        value: customer.id,
        content: customer.email,
      }));
  }

  public async createClient(values: ClientValues) {
    const customer = await this._apiService.post<CustomerDTO>(
      '/customer/create',
      {
        emailAddress: values.email,
        firstname: values.forename,
        surname: values.surname,
        phone: values.phone,
        notes: values.notes,
      },
    );

    return new Customer(customer);
  }

  public async device({ id }: { id?: string }): Promise<Device> {
    const device = await this._apiService.get<DeviceDTO>(`/device/${id}`);

    return new Device(device);
  }

  public async createDevice(values: DeviceValues) {
    const device = await this._apiService.post<DeviceDTO>('/device', {
      identifier: values.identifier,
      unlockPassword: values.password,
      modelId: values.device_model,
    });

    return new Device(device);
  }

  public async clientDevices({
    client,
  }: {
    client?: string;
  }): Promise<{ value: string; content: string }[]> {
    const { items: list } = await this._apiService.get<{ items: DeviceDTO[] }>(
      `/customer/profile/${client}/devices`,
    );

    return list
      .map((device) => new Device(device))
      .map((device) => ({
        value: device.id,
        content: `${device.model.brand.name} ${device.model.name} (${device.identifier})`,
      }));
  }

  public async services({
    model,
  }: {
    model?: string;
  }): Promise<AutocompleteOption[]> {
    const list = await this._apiService.get<ServiceDTO[]>(
      `/model/${model}/services`,
    );

    return list
      .map((service) => new Service(service))
      .map((service) => ({
        value: service.id,
        content: `${service.name} (${service.price}zł)`,
      }));
  }

  public async listAll({
    page,
    limit,
    searchTerm,
    status,
    location,
    technician,
  }: {
    page: number;
    limit: number;
    searchTerm?: string;
    status?: RepairStatus;
    location?: string;
    technician?: string;
  }): Promise<ListResponse<Repair>> {
    let url = `/repair/all?limit=${limit}&offset=${(page - 1) * limit}`;

    if (searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (location) {
      url += `&locationId=${location}`;
    }

    if (technician) {
      url += `&assignedTechnicianId=${technician}`;
    }

    return await this._apiService
      .get<ListResponse<RepairDTO>>(url)
      .then((response) => ({
        ...response,
        items: response.items.map((repair) => new Repair(repair)),
      }));
  }

  public async history(repairId: string): Promise<RepairChangeEvent[]> {
    return await this._apiService
      .get<{ changes: RepairChangeEventDTO[] }>(`/repair/${repairId}/history`)
      .then((result) =>
        result.changes.map((change) => ({
          ...change,
          id: `${change.created_at}-${change.changeType}`,
          createdAt: new Date(change.created_at),
          user: change.user ? new User(change.user) : undefined,
        })),
      );
  }

  public async getRepairServices(
    repairId: string,
  ): Promise<RepairAssignedService[]> {
    return await this._apiService
      .get<RepairAssignedServiceDTO[]>(`/repair/${repairId}/services`)
      .then((list) =>
        list.map(
          (service: RepairAssignedServiceDTO) =>
            new RepairAssignedService(service),
        ),
      );
  }

  public async setStatus(
    repairId: string,
    status: RepairStatus,
  ): Promise<boolean> {
    return await this._apiService
      .put(`/repair/${repairId}/status`, {
        status,
      })
      .then(() => true)
      .catch(() => false);
  }

  public async setAssignedTechnician(
    repairId: string,
    userId: string,
  ): Promise<boolean> {
    return await this._apiService
      .put(`/repair/${repairId}/technician`, {
        userId,
      })
      .then(() => true)
      .catch(() => false);
  }

  public async setServices(
    repairId: string,
    services: AddOrUpdateRepairAssignedServiceDTO[],
  ): Promise<RepairAssignedService[]> {
    return await this._apiService
      .put<RepairAssignedServiceDTO[]>(`/repair/${repairId}/services`, {
        services,
      })
      .then((list) =>
        list.map(
          (service: RepairAssignedServiceDTO) =>
            new RepairAssignedService(service),
        ),
      );
  }

  public async setCreationDate(
    repairId: string,
    date: Date,
  ): Promise<RepairAssignedService[]> {
    return await this._apiService.put(`/repair/${repairId}/creation-date`, {
      createdAt: date.toISOString(),
    });
  }

  public async changeDeviceReceivedFromClientStatus(
    repairId: string,
    status: boolean,
  ): Promise<RepairAssignedService[]> {
    return await this._apiService.put(
      `/repair/${repairId}/device-received-from-client`,
      {
        deviceReceivedFromClient: status,
      },
    );
  }

  public async getRepairsBalanceStatistics(): Promise<RepairsBalanceOverallResponseDTO> {
    return await this._apiService.get<RepairsBalanceOverallResponseDTO>(
      '/dashboard/repairs-balance',
    );
  }

  public async getRepairNotes(repairId: string): Promise<RepairNote[]> {
    return await this._apiService
      .get<RepairNoteDTO[]>(`/repair/${repairId}/notes`)
      .then((response) =>
        Array.isArray(response)
          ? response.map((note) => new RepairNote(note))
          : [],
      );
  }

  public async addRepairNote({
    repairId,
    note,
  }: {
    repairId: string;
    note: AddRepairNoteDTO;
  }) {
    return await this._apiService.post<void>(`/repair/${repairId}/notes`, note);
  }

  public async getRepairParts({
    repairId,
  }: {
    repairId: string;
  }): Promise<RepairAssignedService[]> {
    return await this._apiService
      .get<RepairAssignedServiceDTO[]>(`/repair/${repairId}/parts`)
      .then((list) =>
        list.map((service) => new RepairAssignedService(service)),
      );
  }
}
