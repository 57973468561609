import { FC } from 'react';

import {
  LOCATION_OR_LOCATION_EXPRESS_OR_COURIER,
  COURIER,
  LOCATION_EXPRESS,
  ON_SITE_CLIENT,
  ON_SITE_DEVICE,
  ON_SITE_SERVICES,
  ON_SITE_ACCEPTANCE_INFO,
  ON_SITE_NOTES,
  EXPRESS_CLIENT,
  EXPRESS_DEVICE,
  EXPRESS_SERVICES,
  EXPRESS_ACCEPTANCE_INFO,
  EXPRESS_NOTES,
  COURIER_CLIENT,
  COURIER_DEVICE,
  COURIER_SERVICES,
  COURIER_ACCEPTANCE_INFO,
  COURIER_NOTES,
} from '../machine';

import LocationOrLocationExpressOrCourier from './location-or-location-express-or-courier/location-or-location-express-or-courier';
import Courier from './courier';
import LocationExpress from './location-express';
import Client from './client/client';
import Device from './device/device';
import Services from './services/services';
import { AcceptanceInfo } from './acceptance-info/acceptance-info';
import { Notes } from './notes/notes';
import { User } from '../../../../models/user/User';

export type StepComponentType<T = {}> = FC<
  {
    send: (stage: string) => void;
    register?: () => void;
    user: User;
  } & T
>;

export default {
  [LOCATION_OR_LOCATION_EXPRESS_OR_COURIER]: LocationOrLocationExpressOrCourier,
  [COURIER]: Courier,
  [LOCATION_EXPRESS]: LocationExpress,
  // on-site components
  [ON_SITE_CLIENT]: Client,
  [ON_SITE_DEVICE]: Device,
  [ON_SITE_SERVICES]: Services,
  [ON_SITE_ACCEPTANCE_INFO]: AcceptanceInfo,
  [ON_SITE_NOTES]: Notes,
  // express components
  [EXPRESS_CLIENT]: Client,
  [EXPRESS_DEVICE]: Device,
  [EXPRESS_SERVICES]: Services,
  [EXPRESS_ACCEPTANCE_INFO]: AcceptanceInfo,
  [EXPRESS_NOTES]: Notes,
  // courier components
  [COURIER_CLIENT]: Client,
  [COURIER_DEVICE]: Device,
  [COURIER_SERVICES]: Services,
  [COURIER_ACCEPTANCE_INFO]: AcceptanceInfo,
  [COURIER_NOTES]: Notes,
} as { [key: string]: StepComponentType };
