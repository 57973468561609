import { pickBy } from 'lodash';

import { ApiService } from '../Api.service';
import {
  CreateServiceDTO,
  EditServiceDTO,
  Service,
  ServiceDTO,
} from '../../models/service/Service';

export interface ServiceServiceInterface {
  create(values: CreateServiceDTO): Promise<void>;
  remove(id: string): Promise<void>;
  update(values: EditServiceDTO): Promise<void>;
  getOne(id: string): Promise<Service>;
  list(): Promise<Service[]>;
}

export class ServiceService implements ServiceServiceInterface {
  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async create(values: CreateServiceDTO): Promise<void> {
    return await this._apiService.post('/service', {
      modelId: values.modelId,
      name: values.name,
      margin: values.margin,
      system_margin: values.systemMargin,
      partner_margins: values.partnerMargins,
      priceNet: values.priceNet,
      tax: values.tax,
      duration: values.duration,
      warehouseItemsIds: values.warehouseItemsIds,
    });
  }

  public async remove(id: string): Promise<void> {
    return await this._apiService.delete(`/service/${id}`);
  }

  public async update(values: EditServiceDTO): Promise<void> {
    return await this._apiService.patch(`/service/${values.id}`, {
      modelId: values.modelId,
      name: values.name,
      margin: values.margin,
      system_margin: values.systemMargin,
      partner_margins: values.partnerMargins,
      priceNet: values.priceNet,
      tax: values.tax,
      duration: values.duration,
      warehouseItemsIds: values.warehouseItemsIds,
    });
  }

  public async getOne(id: string): Promise<Service> {
    return await this._apiService
      .get<ServiceDTO>(`/service/${id}`)
      .then((service: ServiceDTO) => new Service(service));
  }

  public async list(query?: { modelId: string }): Promise<Service[]> {
    return await this._apiService
      .get<ServiceDTO[]>('/service', pickBy(query, key => key))
      .then(list => list.map((service: ServiceDTO) => new Service(service)));
  }
}
