import React from 'react';
import {
  AppBar,
  Box,
  createStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQueryClient } from 'react-query';

import { SalesIdeaService } from '../../../services/sales/idea/sales-idea.service';
import SalesIdeasList from './sales-ideas-list';
import AddSalesIdea from './add-sales-idea';

export interface SalesIdeasProps {
  salesIdeaService: SalesIdeaService;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export const SalesIdeas = ({ salesIdeaService }: SalesIdeasProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  return (
    <Box>
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Pomysły
          </Typography>
        </Toolbar>
      </AppBar>
      <Box m={1}>
        <Box>
          <SalesIdeasList salesIdeaService={salesIdeaService} />
        </Box>
        <Box mt={2}>
          <AddSalesIdea
            salesIdeaService={salesIdeaService}
            onIdeaAdded={() => {
              queryClient.refetchQueries({
                queryKey: 'sales-ideas-list',
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default (props: SalesIdeasProps) => () => {
  return <SalesIdeas {...props} />;
};
