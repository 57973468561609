import React, { FC, Fragment } from 'react';
import { MDBSideNav, MDBSideNavCat, MDBSideNavNav } from 'mdbreact';
import { wire } from 'react-hot-wire';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useSheetsQueue } from '@rootsher/use-sheets-queue';

import { DrawerService } from '../../../../services/drawer/Drawer.service';
import { UserService } from '../../../../services/user/User.service';
import { DrawerProps } from '../../../../models/drawer/Drawer';
import { ApplicationService } from '../../../../services/application/Application.service';
import { Config } from 'models/application/Config';
import { User, UserRoles } from '../../../../models/user/User';

import RepairCreate from '../../../repair/create/create';
import { NavLink, Link, NavLinkProps } from 'react-router-dom';

export interface Props {
  isOpenSideNav: boolean;
  toggleSideNav: Function;
  userService: UserService;
  drawerService: DrawerService;
  applicationService: ApplicationService;
}

type Category = {
  name: string;
  moduleName: string;
  id: string;
  children: CategoryLink[];
  roles?: UserRoles[];
};

type CategoryLink = {
  to: string;
  children: string;
  moduleName: string;
  roles?: UserRoles[];
  id?: string;
} & React.PropsWithoutRef<NavLinkProps> &
  React.RefAttributes<HTMLAnchorElement>;

const instanceOfCategoryLink = (object: any): object is CategoryLink => {
  return typeof object.children === 'string';
};

const renderLink = (
  { moduleName, id, roles, ...link }: CategoryLink,
  toggleSideNav: () => void,
) =>
  /^https?:\/\//.test(link.to) ? (
    <a key={id || moduleName} id={id} href={link.to} {...link}>
      {link.children}
    </a>
  ) : (
    <NavLink key={id || moduleName} id={id} {...link} onClick={toggleSideNav} />
  );

const renderCat = (
  { children, moduleName, id, roles, ...categoryProps }: Category,
  toggleSideNav: () => void,
) =>
  children &&
  children.length && (
    <MDBSideNavCat
      key={id || moduleName}
      id={id}
      icon="chevron-right"
      {...categoryProps}
    >
      {children.map((link) => renderLink(link, toggleSideNav))}
    </MDBSideNavCat>
  );

const checkRoles = (roles?: UserRoles[], user?: User) =>
  !roles || !roles.length || (user && roles.some((role) => user.hasRole(role)));

const buildNav = (
  items: (Category | CategoryLink)[],
  toggleSideNav: () => void,
  config: Config,
  user?: User,
) => (
  <MDBSideNavNav>
    {items
      .filter(
        ({ moduleName, roles = [] }) =>
          config.isModuleVisible(moduleName) && checkRoles(roles, user),
      )
      .map((item) =>
        instanceOfCategoryLink(item)
          ? renderLink(item, toggleSideNav)
          : renderCat(
              {
                ...item,
                children: item.children.filter(
                  ({ moduleName, roles }) =>
                    config.isModuleVisible(moduleName) &&
                    checkRoles(roles, user),
                ),
              },
              toggleSideNav,
            ),
      )
      .filter((category) => category)}
  </MDBSideNavNav>
);

const SystemUserNav = ({
  activate,
  toggleSideNav,
  config,
  user,
}: {
  activate: Function;
  toggleSideNav: () => void;
  config: Config;
  user?: User;
}) => {
  return buildNav(
    [
      {
        name: 'Sprzedaż',
        moduleName: 'sales',
        children: 'Sprzedaż i finanse',
        to: '/sales',
        roles: [UserRoles.admin, UserRoles.employee],
      },
      {
        name: 'Naprawy',
        moduleName: 'repairs',
        id: 'repairs',
        roles: [
          UserRoles.admin,
          UserRoles.employee,
          UserRoles.business_customer,
          UserRoles.partner,
        ],
        children: [
          {
            to: '/repair/list',
            children: 'Lista',
            moduleName: 'repairs-list',
          },
        ],
      },
      {
        name: 'Urządzenia',
        moduleName: 'device',
        id: 'devices',
        roles: [UserRoles.admin],
        children: [
          {
            to: '/device/type/list',
            children: 'Typy urządzeń',
            moduleName: 'device-type',
          },
          {
            to: '/device/brand/list',
            children: 'Marki urządzeń',
            moduleName: 'device-brand',
          },
          {
            to: '/device/brand/model/list',
            children: 'Modele urządzeń',
            moduleName: 'device-model',
          },
        ],
      },
      {
        name: 'Usługi',
        moduleName: 'service',
        id: 'services',
        roles: [
          UserRoles.admin,
          UserRoles.partner,
          UserRoles.business_customer,
        ],
        children: [
          {
            to: '/service/list',
            children: 'Cennik',
            moduleName: 'service-list',
          },
        ],
      },
      {
        name: 'Punkty',
        moduleName: 'location',
        id: 'locations',
        roles: [UserRoles.admin],
        children: [
          {
            to: '/location/list',
            children: 'Lista punktów',
            moduleName: 'location-list',
          },
        ],
      },
      {
        name: 'Użytkownicy',
        moduleName: 'user',
        id: 'users',
        roles: [UserRoles.admin],
        children: [
          {
            to: '/user/list',
            children: 'Lista użytkowników',
            moduleName: 'user-list',
          },
        ],
      },
      {
        name: 'NTSN',
        moduleName: 'ntsn',
        id: 'ntsn-reapirs',
        roles: [UserRoles.partner],
        children: [
          {
            to: '/ntsn/repair/list/current',
            children: 'Aktualne naprawy',
            moduleName: 'ntsn-current-list',
          },
          {
            to: '/ntsn/repair/list/history',
            children: 'Historia napraw',
            moduleName: 'ntsn-history-list',
          },
        ],
      },
      {
        name: 'Magazyn',
        moduleName: 'warehouse',
        id: 'warehouse',
        roles: [UserRoles.admin],
        children: [
          {
            to: '/warehouse/items/list',
            children: 'Produkty',
            moduleName: 'warehouse-items',
            roles: [UserRoles.admin],
          },
        ],
      },
      {
        name: 'Ustawienia',
        moduleName: 'application',
        id: 'application-settings',
        roles: [UserRoles.admin],
        children: [
          {
            to: '/application/settings',
            children: 'Ogólne',
            moduleName: 'application-settings',
          },
          {
            to: '/application/settings/courier',
            children: 'Kurier',
            id: 'courier-settings',
            moduleName: 'application-settings',
          },
        ],
      },
      {
        name: 'Aplikacja',
        moduleName: 'application',
        id: 'application',
        roles: [UserRoles.su],
        children: [
          {
            to: '/application/action-event',
            children: 'Events',
            moduleName: 'application-action-event',
            roles: [UserRoles.su],
          },
          {
            to: '/application/queue',
            children: 'Queues',
            moduleName: 'application-queue',
            roles: [UserRoles.su],
          },
        ],
      },
      {
        id: 'link-to-store',
        moduleName: 'application',
        children: 'Sklep',
        to: 'http://sklep.dillcom.pl/',
        target: '_blank',
      },
    ],
    toggleSideNav,
    config,
    user,
  );
};

function RepairCreateButton({ onClick }: { onClick: () => void }) {
  const [push] = useSheetsQueue();

  return (
    <Box display="flex" justifyContent="center">
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => {
          push(RepairCreate, { side: 'right', size: 50 });
          onClick();
        }}
      >
        Utwórz naprawę
      </Button>
    </Box>
  );
}

export const SideNav: FC<Props> = ({
  isOpenSideNav,
  drawerService,
  toggleSideNav,
  userService: { user },
  applicationService: { config },
}) => {
  function activate(event: Event, name: string, params: DrawerProps) {
    drawerService.activate(name, params);
    toggleSideNav(event);
  }

  return (
    <Fragment>
      <MDBSideNav
        triggerOpening={isOpenSideNav}
        className="blue lighten-2"
        mask="strong"
        hidden
      >
        <div className="d-flex justify-content-center py-3">
          <Link to="/" onClick={() => toggleSideNav()}>
            <img
              src={config!.logo}
              alt={config!.name}
              style={{ height: '25px' }}
            />
          </Link>
        </div>
        {config && config.isNewRepairEnabled && (
          <RepairCreateButton onClick={() => toggleSideNav()} />
        )}
        <SystemUserNav
          activate={(event: Event, name: string, params: DrawerProps) =>
            activate(event, name, params)
          }
          toggleSideNav={() => toggleSideNav()}
          config={config!}
          user={user!}
        />
      </MDBSideNav>
    </Fragment>
  );
};

export default wire(
  ['userService', 'drawerService', 'applicationService'],
  SideNav,
);
