import React, { PureComponent, Fragment } from "react";
import * as Yup from "yup";
import { wire } from "react-hot-wire";

import { PartnerService } from "../../../services/partner/Partner.service";
import { UserService } from "../../../services/user/User.service";
import { ApplicationService } from "../../../services/application/Application.service";

import SimpleForm, {
  SimpleFormProps
} from "../../common/form/simple-form/SimpleForm";

import {
  NotificationMessageType,
  NotificationService
} from "../../../services/application/Notification.service";

export interface EditUserPasswordFormInterface {
  id: string;
  password: string;
  confirmPassword: string;
  userPassword: string;
  [key: string]: string | undefined;
}

export class EditUserPassword extends PureComponent<{
  simpleFormProps: SimpleFormProps;
  partnerService: PartnerService;
  userService: UserService;
  applicationService: ApplicationService;
  notificationService: NotificationService;
}> {
  public render() {
    return (
      <SimpleForm
        title={`Edycja hasła użytkownika ${
          this.props.simpleFormProps.initialValues.email
        }`}
        submitButton="Zapisz"
        submittingButton="Trwa zapisywanie..."
        onSubmit={(values: EditUserPasswordFormInterface) =>
          this.props.userService
            .updateUserPassword(values)
            .then(() =>
              this.props.notificationService.notify({
                message: "Hasło zaktualizowane",
                type: NotificationMessageType.SUCCESS
              })
            )
            .catch((error: any) => {
              console.error(error);
              this.props.notificationService.notify({
                message: "Błąd przy aktualizacji hasła",
                type: NotificationMessageType.ERROR
              });
            })
        }
        fields={[
          {
            type: "password",
            name: "newPassword",
            label: "Nowe hasło",
            validator: Yup.string()
              .min(1, "too-short")
              .max(80, "too-long")
              .required("required")
          },
          {
            type: "password",
            name: "confirmNewPassword",
            label: "Powtórz nowe hasło",
            validator: Yup.string()
              .min(1, "too-short")
              .max(80, "too-long")
              .required("required")
          },
          {
            type: "password",
            name: "userPassword",
            label: "Twoje hasło",
            validator: Yup.string()
              .min(1, "too-short")
              .required("required")
          }
        ]}
        render={({ newPassword, confirmNewPassword, userPassword }: any) => (
          <Fragment>
            <div className="d-flex">
              <div className="flex-grow-1 mr-3">
                {newPassword({ containerClass: "m-0" })}
              </div>
              <div className="flex-grow-1">
                {confirmNewPassword({ containerClass: "m-0" })}
              </div>
            </div>
            <div>{userPassword({ containerClass: "m-0" })}</div>
          </Fragment>
        )}
        {...this.props.simpleFormProps}
      />
    );
  }
}

export default wire(
  [
    "partnerService",
    "notificationService",
    "drawerService",
    "applicationService",
    "userService"
  ],
  EditUserPassword
);
