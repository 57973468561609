import { ApiService } from '../Api.service';
import { EditEmployeeDTO } from '../../models/employee/Employee';
import { User, UserDTO } from '../../models/user/User';
import { ListResponse } from '../../models/common/ListResponse';

export class EmployeeService {
  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async register(data: any): Promise<void> {
    return await this._apiService.post('/employee/register', data);
  }

  public async remove(id: string) {
    return await this._apiService.delete(`/user/${id}`);
  }

  public async update(values: EditEmployeeDTO): Promise<void> {
    return await this._apiService.put(`/employee/${values.id}`, values);
  }

  public async getTechnicians(): Promise<User[]> {
    return await this._apiService
      .get<ListResponse<UserDTO>>('/employee/technician/list')
      .then(response => response.items.map(user => new User(user)));
  }
}
