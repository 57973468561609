import { UserService } from "./User.service";

export default {
  userService: {
    class: UserService,
    public: true,
    arguments: [
      { $ref: "apiService" },
      { $ref: "partnerService" },
      { $ref: "customerService" },
      { $ref: "businessCustomerService" },
      { $ref: "employeeService" }
    ]
  }
};
