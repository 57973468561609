import { PureComponent } from "react";
import { wire } from "react-hot-wire";

import { AuthService } from "../../../services/auth/Auth.service";

export interface Props {
  authService: AuthService;
}

export class Logout extends PureComponent<Props> {
  componentWillMount() {
    this.props.authService.logout();
  }

  render() {
    return null;
  }
}

export default wire(["authService"], Logout);
