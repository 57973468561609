export enum RepairOrigin {
  PANEL = 'PANEL',
  MOBILE_APP = 'MOBILE_APP',
  WWW = 'WWW',
}

export const RepairOriginTranslation = {
  [RepairOrigin.PANEL]: 'Panel',
  [RepairOrigin.MOBILE_APP]: 'Aplikacja Mobilna',
  [RepairOrigin.WWW]: 'Strona WWW',
};
