import React, { PureComponent, Fragment } from "react";
import * as Yup from "yup";
import { wire } from "react-hot-wire";

import { PartnerService } from "../../../services/partner/Partner.service";
import { UserService } from "../../../services/user/User.service";
import { ApplicationService } from "../../../services/application/Application.service";

import SimpleForm, {
  SimpleFormProps
} from "../../common/form/simple-form/SimpleForm";

import {
  NotificationMessageType,
  NotificationService
} from "../../../services/application/Notification.service";

import { Field } from "../../common/form/simple-form/field";
import { DrawerService } from "../../../services/drawer/Drawer.service";
import Spinner from "../../common/spinner/Spinner";

import editFields from "../../../models/user/Fields/Edit";
import { LocationService } from "../../../services/location/Location.service";

export interface EditUserFormInterface {
  id: string;
  email: string;
  nickname: string;
  firstname: string;
  surname: string;
  externalId?: string;
  [key: string]: string | undefined;
}

export interface EditUserState {
  loading: boolean;
  locations: Array<{ value: string; content: string }>;
}

export class EditUser extends PureComponent<{
  simpleFormProps: SimpleFormProps;
  partnerService: PartnerService;
  userService: UserService;
  applicationService: ApplicationService;
  notificationService: NotificationService;
  drawerService: DrawerService;
  locationService: LocationService;
}> {
  public state: EditUserState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      locations: []
    };
  }

  public componentDidMount() {
    this.fetchLocations();
  }

  public render() {
    const props = {
      ...this.props.simpleFormProps,
      initialValues: {
        ...this.props.simpleFormProps.initialValues,
        employeeRole: this.props.simpleFormProps.initialValues.employeeRole
      }
    };

    return (
      <SimpleForm
        title="Edycja użytkownika"
        submitButton="Zapisz"
        submittingButton="Trwa zapisywanie..."
        onSubmit={(values: EditUserFormInterface) =>
          this.props.userService
            .update(this.props.simpleFormProps.initialValues.role, {
              ...values,
              role: values.employeeRole,
              locationId: values.location
            })
            .then(() =>
              this.props.notificationService.notify({
                message: "Użytkownik zaktualizowany pomyślnie",
                type: NotificationMessageType.SUCCESS
              })
            )
            .catch((error: any) => {
              console.error(error);
              this.props.notificationService.notify({
                message: "Błąd przy aktualizacji użytkownika",
                type: NotificationMessageType.ERROR
              });
            })
        }
        fields={[
          {
            type: "text",
            name: "email",
            label: "E-mail*",
            validator: Yup.string()
              .min(1, "too-short")
              .max(80, "too-long")
              .required("required")
          },
          ...this.getFields()
        ]}
        render={({ email, ...rest }: any) =>
          this.state.loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div>{email()}</div>
              {this.renderFields(rest)}
            </Fragment>
          )
        }
        {...props}
      />
    );
  }

  private getFields(): Array<Field> {
    const fields = (editFields[
      this.props.simpleFormProps.initialValues.role || "default"
    ] || []) as Field[];

    const locationField = fields.find(field => field.name === "location");

    if (locationField) {
      locationField.options = this.state.locations;
    }

    return fields;
  }

  private renderFields(fields: { [key: string]: Function }) {
    const fieldsToRender: any[] = [];

    if (fields.location) {
      fieldsToRender.push(
        <div key="location">{fields.location({ containerClass: "m-0" })}</div>
      );
    }

    if (fields.employeeRole) {
      fieldsToRender.push(
        <div key="employeeRole">
          {fields.employeeRole({ containerClass: "m-0" })}
        </div>
      );
    }

    if (fields.phone) {
      fieldsToRender.push(
        <div key="phone">{fields.phone({ containerClass: "m-0" })}</div>
      );
    }

    if (fields.firstname && fields.surname) {
      fieldsToRender.push(
        <div className="d-flex" key="name">
          <div className="flex-grow-1 mr-3">
            {fields.firstname({ containerClass: "m-0" })}
          </div>
          <div className="flex-grow-1">
            {fields.surname({ containerClass: "m-0" })}
          </div>
        </div>
      );
    }

    if (fields.nickname) {
      fieldsToRender.push(
        <div key="nickname">{fields.nickname({ containerClass: "m-0" })}</div>
      );
    }

    if (fields.externalId) {
      fieldsToRender.push(
        <div key="externalId">
          {fields.externalId({ containerClass: "m-0" })}
        </div>
      );
    }

    return <Fragment>{fieldsToRender.map(field => field)}</Fragment>;
  }

  private async fetchLocations() {
    const locationsListResponse = await this.props.locationService.simpleList();

    this.setState({
      loading: false,
      locations: locationsListResponse.items.map(location => ({
        content: location.name,
        value: location.id
      }))
    });
  }
}

export default wire(
  [
    "partnerService",
    "notificationService",
    "applicationService",
    "userService",
    "drawerService",
    "locationService"
  ],
  EditUser
);
