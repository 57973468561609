import brandModel from "./model";
import { DeviceBrandService } from "./DeviceBrand.service";

export default {
  ...brandModel,
  deviceBrandService: {
    class: DeviceBrandService,
    public: true,
    arguments: [{ $ref: "apiService" }]
  }
};
