import React from "react";
import { MDBBtn } from "mdbreact";
import _ from "lodash";
import classnames from "classnames";

import {
  SimpleListColumn,
  SimpleListProps,
  SimpleListRow,
  SimpleListState
} from "./SimpleList";
import simpleListFilters from "./filter";
import PaginationPage from "../../pagination/Pagination";

export default ({
  title,
  description,
  columns,
  rows,
  createButtonText,
  filters = [],
  onFilterChange,
  onPageChange,
  createHandler,
  previewHandler,
  editHandler,
  deleteHandler,
  isLoading,
  pageCount,
  page
}: SimpleListProps &
  SimpleListState & { onFilterChange: Function; onPageChange: Function }) => (
  <div>
    <div className="filters d-flex justify-content-end">
      {pageCount || pageCount === 0 ? (
        <PaginationPage
          currentPage={page}
          pageCount={pageCount}
          onPageChange={(page: number) => onPageChange(page)}
        />
      ) : null}
      {filters.map((filter: string) => {
        const Filter: any = simpleListFilters[filter];

        return (
          <div className="ml-4" key={filter}>
            <Filter
              onChange={(value: string) => onFilterChange(filter, value)}
            />
          </div>
        );
      })}
    </div>
    <div
      className={classnames("d-flex", {
        "flex-column flex-md-row": description,
        "flex-column": !description
      })}
    >
      <div
        className={classnames("p-0 pr-md-4", {
          "col-12 col-md-3": description
        })}
      >
        <h3 className="mt-0 mt-md-2">{title}</h3>
        <p>{description}</p>
        {createHandler && (
          <MDBBtn onClick={createHandler} size="sm" color="primary">
            {createButtonText || "Utworz"}
          </MDBBtn>
        )}
      </div>
      <div className="flex-grow-1">
        <table className="simple-list table table-striped table-hover table-dark table-bordered">
          <thead>
            <tr>
              {columns.map((column: SimpleListColumn) => (
                <th key={column.name} {...column}>
                  {column.label}
                </th>
              ))}
              {previewHandler && <th className="button">podglad</th>}
              {editHandler && <th className="button">edycja</th>}
              {deleteHandler && <th className="button">usuwanie</th>}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="loading" colSpan={columns.length + 3}>
                  trwa ladowanie listy elementow...
                </td>
              </tr>
            ) : (
              rows.map((row: SimpleListRow) => (
                <tr key={row.id}>
                  {columns.map((column: SimpleListColumn) => (
                    <td key={column.name}>{_.get(row, column.name)}</td>
                  ))}
                  {previewHandler && (
                    <td className="button">
                      <MDBBtn
                        onClick={() => previewHandler(row)}
                        size="sm"
                        color="primary"
                      >
                        podglad
                      </MDBBtn>
                    </td>
                  )}
                  {editHandler && (
                    <td className="button">
                      <MDBBtn onClick={() => editHandler(row)} size="sm">
                        edytuj
                      </MDBBtn>
                    </td>
                  )}
                  {deleteHandler && (
                    <td className="button">
                      <MDBBtn
                        onClick={() => deleteHandler(row)}
                        size="sm"
                        color="red"
                      >
                        usun
                      </MDBBtn>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="filters d-flex justify-content-end">
        {pageCount || pageCount === 0 ? (
          <PaginationPage
            currentPage={page}
            pageCount={pageCount}
            onPageChange={(page: number) => onPageChange(page)}
          />
        ) : null}
      </div>
    </div>
  </div>
);
