import { ApiService } from "../Api.service";

import {
  WarehouseItem,
  WarehouseItemDTO
} from "../../models/warehouse/WarehouseItem";
import { ListResponse } from "../../models/common/ListResponse";

export interface WarehouseItemServiceInterface {
  list(warehouseId: string, page: number): Promise<ListResponse<WarehouseItem>>;
}

export class WarehouseItemService implements WarehouseItemServiceInterface {
  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async list(
    warehouseId: string,
    page: number = 1
  ): Promise<ListResponse<WarehouseItem>> {
    return await this._apiService
      .get<ListResponse<WarehouseItemDTO>>(
        `/warehouse/${warehouseId}/item/list?limit=100&offset=${(page - 1) *
          100}`
      )
      .then(response => ({
        ...response,
        items: response.items.map(item => new WarehouseItem(item))
      }));
  }
}
