import React, { CSSProperties, PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import { DeviceBrandService } from '../../../../services/device/brand/DeviceBrand.service';
import { DrawerService } from '../../../../services/drawer/Drawer.service';
import SimpleList, {
  SimpleListProps,
  SimpleListRow,
} from '../../../common/list/simple-list/SimpleList';
import { DeviceBrand } from '../../../../models/device/brand/DeviceBrand';
import { UserService } from '../../../../services/user/User.service';
import { PartnerMarginDTO } from '../../../../models/partner/PartnerMargin';

const customCellStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
};

const customColStyles: CSSProperties = {
  ...customCellStyles,
};

export class DeviceBrandList extends PureComponent<{
  deviceBrandService: DeviceBrandService;
  drawerService: DrawerService;
  userService: UserService;
}> {
  public render() {
    const {
      userService: { user },
    } = this.props;

    const props: SimpleListProps = {
      title: 'Lista marek',
      description: 'Tutaj możesz zarządzac markami',
      createButtonText: 'dodaj markę',
      columns: [
        {
          label: 'Marka',
          name: 'name',
        },
        {
          label: (
            <span style={{ ...customColStyles }}>Marża systemowa (%)</span>
          ),
          name: 'systemMargin',
        },
      ],
      fetchList: () =>
        this.props.deviceBrandService.list().then(list =>
          list.map(brand => ({
            id: brand.id,
            name: brand.name,
            partnerMargins: brand.partnerMargins,
            systemMargin: (
              <span
                data-value={brand.systemMargin}
                style={{ ...customCellStyles }}
              >
                {typeof brand.systemMargin === 'number'
                  ? brand.systemMargin
                  : '-'}
              </span>
            ),
            ...(brand as {}),
          })),
        ),
    };

    if (user!.isAdmin()) {
      props.editHandler = ({
        onEdit,
        row,
      }: {
        onEdit: Function;
        row: SimpleListRow;
      }) => {
        this.props.drawerService.activate('edit-device-brand', {
          simpleFormProps: {
            initialValues: {
              id: row.id,
              name: row.name,
              systemMargin: row.systemMargin.props['data-value'],
              ...(row.partnerMargins || []).reduce(
                (
                  obj: { [key: string]: number },
                  partnerMargin: PartnerMarginDTO,
                ) => {
                  obj[`partner_${partnerMargin.id}`] = partnerMargin.margin;

                  return obj;
                },
                {},
              ),
            },
            onResolved: onEdit,
          },
        });
      };

      props.deleteHandler = ({
        onDelete,
        row,
      }: {
        onDelete: Function;
        row: DeviceBrand;
      }) => this.props.deviceBrandService.remove(row.id).then(() => onDelete());

      props.createHandler = ({ onCreate }: { onCreate: Function }) =>
        this.props.drawerService.activate('create-device-brand', {
          simpleFormProps: {
            onResolved: onCreate,
          },
        });
    }

    return <SimpleList {...props} />;
  }
}

export default wire(
  ['deviceBrandService', 'drawerService', 'userService'],
  DeviceBrandList,
);
