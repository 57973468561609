import React, { FC, Fragment } from 'react';
import { useQuery } from 'react-query';
import { wire } from 'react-hot-wire';

import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { ON_SITE_CLIENT } from '../../../machine';
import { PathProps } from './index';
import { RepairService } from '../../../../../../services/repair/Repair.service';
import { Box } from '../../../create';

export const LocationPath = ({
  send,
  reset,
  values,
  repairService,
}: PathProps<{ repairService: RepairService }>) => {
  const { isLoading, data: location } = useQuery('locationPath', () =>
    repairService.location({ id: values.location!.id }),
  );

  return (
    <div className="d-flex flex-column my-4">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          <Box>
            <Typography>
              Nazwa: <strong>{location?.name}</strong>
            </Typography>
            <Typography>
              Szybkie naprawy:{' '}
              <strong>{location?.canFastRepair ? 'tak' : 'nie'}</strong>
            </Typography>
            <Typography>
              Odbiór sprzętu:{' '}
              <strong>{location?.canPickup ? 'tak' : 'nie'}</strong>
            </Typography>
          </Box>
          <div className="mt-4">
            <MUIButton color="primary" className="mr-3" onClick={reset}>
              Zmień wybór
            </MUIButton>
            <MUIButton
              color="primary"
              variant="contained"
              onClick={() => send(ON_SITE_CLIENT)}
            >
              Dalej
            </MUIButton>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default wire(['repairService'], LocationPath);
