import React from 'react';
import { useFormikContext } from 'formik-latest';
import { wire } from 'react-hot-wire';

import Button from '@material-ui/core/Button';

import { StepComponentType } from './index';
import { Select } from '../../form/field/select/select';
import { RepairValues } from '../create';
import { RepairService } from '../../../../services/repair/Repair.service';

export const LocationExpress: StepComponentType<{
  repairService: RepairService;
}> = ({ send, repairService }) => {
  const { values } = useFormikContext<RepairValues>();

  return (
    <div className="d-flex flex-column my-4">
      <Select<RepairValues>
        name="location_express.time"
        label="Wybierz godzinę"
        onChange={() => {}}
        fetch={() =>
          repairService.expressHours({ location: values.location_express!.id })
        }
      />
      <div className="mt-4">
        <Button
          color="primary"
          className="mr-3"
          onClick={() => send('PREVIOUS')}
        >
          wstecz
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!values.location_express!.time}
          onClick={() => send('NEXT')}
        >
          dalej
        </Button>
      </div>
    </div>
  );
};

export default wire(['repairService'], LocationExpress);
