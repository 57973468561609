import React, { CSSProperties } from "react";
import { Form } from "formik";
import { MDBCard, MDBCardBody, MDBContainer } from "mdbreact";

export const logoStyles: CSSProperties = {
  maxWidth: "217px",
  maxHeight: "56px",
  margin: "38px 79px"
};

export const titleStyles: CSSProperties = {
  fontWeight: "bold",
  fontSize: "18px",
  lineHeight: "27px",
  color: "#000000"
};

export const textStyles: CSSProperties = {
  fontWeight: "normal",
  fontSize: "15px",
  lineHeight: "22px",
  color: "#000000"
};

const ResetPasswordContainer: React.FunctionComponent<{
  header?: JSX.Element;
}> = ({ children, header }) => (
  <MDBContainer>
    <div className="mx-auto" style={{ maxWidth: "440px" }}>
      <Form>
        <MDBCard className="my-3 my-md-5">
          <MDBCardBody className="mx-4 px-1">
            <div className="text-center">
              <div>
                <img src="/logo.svg" style={logoStyles} alt="Dillcom" />
              </div>

              {header}
            </div>
            {children}
          </MDBCardBody>
        </MDBCard>
      </Form>
    </div>
  </MDBContainer>
);

export default ResetPasswordContainer;
