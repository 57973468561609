import React, { Fragment, PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { MDBBtn } from 'mdbreact';

import { UserService } from '../../../../services/user/User.service';
import { DrawerService } from '../../../../services/drawer/Drawer.service';
import { CourierService } from '../../../../services/courier/Courier.service';

import Spinner from '../../../common/spinner/Spinner';

import { CourierWorkingHoursDTO } from '../../../../models/courier/CourierConfiguration';
import { Weekday } from '../../../../models/common/Weekday';

export interface CourierSettingsState {
  loading: boolean;
  workingHours: CourierWorkingHoursDTO[];
  error: any;
}

export class CourierSettings extends PureComponent<
  {
    userService: UserService;
    courierService: CourierService;
    drawerService: DrawerService;
  },
  CourierSettingsState
> {
  public state: CourierSettingsState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      workingHours: [],
    };
  }

  componentDidMount(): void {
    this.fetchConfig();
  }

  public render() {
    if (this.state.error) {
      return (
        <div>
          <p>
            <b>Wystąpił błąd</b>
          </p>
          <pre>
            {JSON.stringify(
              this.state.error.message
                ? this.state.error.message
                : this.state.error,
              null,
              2,
            )}
          </pre>
        </div>
      );
    }

    return (
      <Fragment>
        <div>
          <h2>Ustawienia Kuriera</h2>
        </div>
        {this.state.loading ? (
          <div>
            <Spinner small />
          </div>
        ) : (
          <Fragment>
            <div>
              <h5>Godziny pracy</h5>
              <div>
                {[
                  { label: 'Poniedziałek', weekday: Weekday.MONDAY },
                  { label: 'Wtorek', weekday: Weekday.TUESDAY },
                  { label: 'Środa', weekday: Weekday.WEDNESDAY },
                  { label: 'Czwartek', weekday: Weekday.THURSDAY },
                  { label: 'Piątek', weekday: Weekday.FRIDAY },
                  { label: 'Sobota', weekday: Weekday.SATURDAY },
                  { label: 'Niedziela', weekday: Weekday.SUNDAY },
                ].map(day => {
                  const dayConfig = this.state.workingHours.find(
                    workingHours => workingHours.weekday === day.weekday,
                  );

                  return (
                    <div>
                      <span className="font-weight-bold mr-2">
                        {day.label}:
                      </span>
                      {dayConfig ? (
                        <span>
                          {dayConfig.start}:00 - {dayConfig.end}:00
                        </span>
                      ) : (
                        <span className="red-text font">
                          <b>wyłączony</b>
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <MDBBtn
              color="primary"
              className="p-3"
              onClick={() =>
                this.props.drawerService.activate('edit-courier-settings', {
                  simpleFormProps: {
                    initialValues: {
                      workingHours: this.state.workingHours,
                    },
                    onResolved: () => this.fetchConfig(),
                  },
                })
              }
            >
              Edit
            </MDBBtn>
          </Fragment>
        )}
      </Fragment>
    );
  }

  private async fetchConfig() {
    this.setState({
      loading: true,
    });
    this.props.courierService
      .fetchWorkingHoursConfiguration()
      .then(workingHours =>
        this.setState({
          workingHours,
          loading: false,
        }),
      )
      .catch(e => {
        console.error(e);
        this.setState({
          workingHours: [],
          loading: false,
          error: e,
        });
      });
  }
}
export default wire(
  ['userService', 'courierService', 'drawerService'],
  CourierSettings,
);
