export interface WarehouseItemDTO {
  id: string;
  name: string;
  priceNet: number;
  priceGross: number;
  quantity: number;
  externalId: string;
  createdAt: number;
  updatedAt: number;
}

export class WarehouseItem {
  private _id: string;
  private _name: string;
  private _priceNet: number;
  private _priceGross: number;
  private _quantity: number;
  private _externalId: string;
  private _createdAt: Date;
  private _updatedAt: Date;

  constructor(item: WarehouseItemDTO) {
    this._id = item.id;
    this._name = item.name;
    this._priceNet = item.priceNet;
    this._priceGross = item.priceGross;
    this._quantity = item.quantity;
    this._externalId = item.externalId;
    this._createdAt = new Date(item.createdAt);
    this._updatedAt = new Date(item.updatedAt);
  }

  public get id(): string {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get priceNet() {
    return this._priceNet;
  }

  public get priceGross() {
    return this._priceGross;
  }

  public get quantity() {
    return this._quantity;
  }

  public get externalId() {
    return this._externalId;
  }

  public get createdAt() {
    return this._createdAt;
  }

  public get updatedAt() {
    return this._updatedAt;
  }
}
