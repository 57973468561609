import React, { Fragment, useEffect } from 'react';
import { wire } from 'react-hot-wire';
import { useQuery } from 'react-query';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { Box, RepairValues } from '../../create';
import { RepairService } from '../../../../../services/repair/Repair.service';

type ClientPreviewProps = {
  send: (stage: string) => void;
  reset: () => void;
  values: RepairValues;
  setFieldValue: any;
  repairService: RepairService;
};

export const ClientPreview = ({
  send,
  reset,
  values,
  setFieldValue,
  repairService,
}: ClientPreviewProps) => {
  const {
    isLoading,
    data: customer,
  } = useQuery(`client-${values.client?.value}`, () =>
    repairService.client({ id: values.client?.value }),
  );

  useEffect(() => {
    (async () => {
      if (values.contact.email || values.contact.phone) {
        return;
      }

      const customer = await repairService.client({ id: values.client?.value });

      setFieldValue('contact.email', customer.email);
      setFieldValue('contact.phone', customer.profiles[0].phone);
    })();
  }, [
    values.client?.value,
    repairService,
    setFieldValue,
    values.contact.email,
    values.contact.phone,
  ]);

  return (
    <div className="d-flex flex-column my-4">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          <Box>
            <Typography>
              Imię i nazwisko: <strong>{customer?.profiles[0].fullName}</strong>
            </Typography>
            <Typography>
              E-mail: <strong>{customer?.email}</strong>
            </Typography>
            <Typography>
              telefon: <strong>{customer?.profiles[0].phone}</strong>
            </Typography>
          </Box>
          <div className="mt-4">
            <Button
              color="primary"
              className="mr-3"
              onClick={() => send('PREVIOUS')}
            >
              wstecz
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="mr-3"
              onClick={reset}
            >
              zmień klienta
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => send('NEXT')}
            >
              dalej
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default wire(['repairService'], ClientPreview);
