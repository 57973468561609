import React, { Fragment } from 'react';
import ue from 'assets/images/ue.png';

export const UE = () => {
  return (
    <Fragment>
      <div className="info-ue">
        <img src={ue} />
        <h1>
          "Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich:
          <br />
          Europa inwestująca w obszary wiejskie".
        </h1>
        <p>
          Operacja pn. „Rozwój działalności gospodarczej firmy Dilicom Serwis
          Jan Łajewski z zakresu serwisu komputerowego poprzez zakup
          innowacyjnej aplikacji usprawniającej obsługę klientów” mająca na celu
          rozwój działalności gospodarczej w zakresie naprawy i konserwacji
          komputerów i urządzeń peryferyjnych, w tym utworzenie jednego etatu i
          utrzymanie dwóch miejsc pracy w okresie trzech lat od dokonania
          płatności końcowej współfinansowana jest ze środków Unii Europejskiej
          w ramach poddziałania „Wsparcie na wdrażanie operacji w ramach
          strategii rozwoju lokalnego kierowanego przez społeczność” Programu
          Rozwoju Obszarów Wiejskich na lata 2014-2020. Wartość realizowanej
          operacji: 136471,92 zł, w tym ze środków EFPROW 56290,27 zł. Operacja
          realizowana w ramach strategii rozwoju lokalnego kierowanego przez
          społeczność wdrażanej przez LGD Nadarzyn-Raszyn- Michałowice.
        </p>
      </div>
      <div className="ue-line-1" />
      <div className="ue-line-2" />
    </Fragment>
  );
};
