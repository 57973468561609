import { withStyles } from '@material-ui/core';
import MuiBox from '@material-ui/core/Box';
import indigo from '@material-ui/core/colors/indigo';
import grey from '@material-ui/core/colors/grey';

export const BlueBox = withStyles({
  root: {
    background: indigo[500],
    color: grey[50],
  },
})(MuiBox);
