import { BrandModel, BrandModelDTO } from './brand/model/BrandModel';

export interface DeviceDTO {
  id: string;
  identifier: string;
  unlockPassword: string;
  model: BrandModelDTO;
}

export interface CreateDeviceDTO {
  modelId: number;
  identifier: string;
  unlockPassword?: string;
}

export class Device {
  private _id: string;
  private _identifier: string;
  private _unlockPassword: string;
  private _model: BrandModel;

  constructor(device: DeviceDTO) {
    this._id = device.id;
    this._identifier = device.identifier;
    this._unlockPassword = device.unlockPassword;
    this._model = new BrandModel(device.model);
  }

  public get id(): string {
    return this._id;
  }

  public get identifier(): string {
    return this._identifier;
  }

  public get unlockPassword(): string {
    return this._unlockPassword;
  }

  public get model(): BrandModel {
    return this._model;
  }
}
