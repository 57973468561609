import React, { Fragment, PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import * as Yup from 'yup';

import { UserService } from '../../../../services/user/User.service';
import { ApplicationService } from '../../../../services/application/Application.service';
import {
  NotificationMessageType,
  NotificationService,
} from '../../../../services/application/Notification.service';

import { UpdateConfigDTO } from '../../../../models/application/Config';
import SimpleForm, {
  SimpleFormProps,
} from '../../../common/form/simple-form/SimpleForm';
import { UserRoles } from '../../../../models/user/User';

export interface UpdateSettingsFormInterface {
  name: string;
  systemMargin?: string;
  enabledModules?: string;
  [key: string]: string | boolean | undefined;
}

export class EditSettings extends PureComponent<{
  userService: UserService;
  applicationService: ApplicationService;
  notificationService: NotificationService;
  simpleFormProps: SimpleFormProps;
}> {
  public render() {
    return (
      <SimpleForm
        title="Ustawienia"
        submitButton="Zapisz zmiany"
        submittingButton="Trwa zapisywanie zmian..."
        onSubmit={(values: UpdateSettingsFormInterface) =>
          this.props.applicationService
            .updateApplicationSettings({
              name: values.name,
              logo: values.logo,
              systemMargin: values.systemMargin
                ? Number(values.systemMargin)
                : null,
              serviceMargin: values.serviceMargin,
              enableMobileApp: values.enableMobileApp,
              enableMobileAppRegister: values.enableMobileAppRegister,
              enableMobileAppLogin: values.enableMobileAppLogin,
              enableMobileAppPassReset: values.enableMobileAppPassReset,
              enableNewRepair: values.enableNewRepair,
              enableRepairCourier: values.enableRepairCourier,
              ...(this.props.userService.user!.hasRole(UserRoles.su)
                ? { enabledModules: values.enabledModules!.split(',') }
                : {}),
            } as UpdateConfigDTO)
            .then(() =>
              this.props.notificationService.notify({
                message: 'Ustawienia zaktualizowane',
                type: NotificationMessageType.SUCCESS,
              }),
            )
            .catch((error: any) => {
              console.error(error);
              this.props.notificationService.notify({
                message: 'Błąd przy aktualizacji ustawień',
                type: NotificationMessageType.ERROR,
              });
            })
        }
        fields={[
          {
            type: 'text',
            name: 'name',
            label: 'Nazwa',
            validator: Yup.string()
              .max(255, 'too-long')
              .required('required'),
          },
          {
            type: 'text',
            name: 'logo',
            label: 'Logo',
          },
          {
            type: 'text',
            name: 'systemMargin',
            label: 'Marża systemowa (%)',
          },
          {
            type: 'checkbox',
            name: 'enableNewRepair',
            label: 'Tworzenie nowych napraw',
          },
          {
            type: 'checkbox',
            name: 'enableRepairCourier',
            label: 'Kurier',
          },
          {
            type: 'checkbox',
            name: 'enableMobileApp',
            label: 'Włączone',
          },
          {
            type: 'checkbox',
            name: 'enableMobileAppRegister',
            label: 'Rejestracja',
          },
          {
            type: 'checkbox',
            name: 'enableMobileAppLogin',
            label: 'Logowanie',
          },
          {
            type: 'checkbox',
            name: 'enableMobileAppPassReset',
            label: 'Resetowanie hasła',
          },
          {
            type: 'checkbox',
            name: 'serviceMargin',
            label: 'Service Margin',
          },
          {
            type: 'textarea',
            name: 'enabledModules',
            label: 'Enabled Modules',
          },
        ]}
        render={({
          name,
          logo,
          systemMargin,
          enabledModules,
          serviceMargin,
          enableMobileApp,
          enableMobileAppRegister,
          enableMobileAppLogin,
          enableMobileAppPassReset,
          enableNewRepair,
          enableRepairCourier,
        }: any) => (
          <Fragment>
            {name()}
            {logo()}
            {systemMargin()}
            <hr />
            <b>Naprawy</b>
            <hr />
            {enableNewRepair()}
            {enableRepairCourier()}
            <b>Mobile App</b>
            {enableMobileApp()}
            {enableMobileAppRegister()}
            {enableMobileAppLogin()}
            {enableMobileAppPassReset()}
            {this.props.userService.user!.hasRole(UserRoles.su) ? (
              <Fragment>
                <hr />
                {serviceMargin()}
                {enabledModules()}
              </Fragment>
            ) : null}
          </Fragment>
        )}
        {...this.props.simpleFormProps}
      />
    );
  }
}
export default wire(
  ['userService', 'applicationService', 'notificationService'],
  EditSettings,
);
