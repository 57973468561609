import { ApiService } from '../Api.service';
import {
  Location,
  CreateLocationDTO,
  LocationDTO,
  UpdateLocationDTO,
} from '../../models/location/Location';
import { ListResponse } from '../../models/common/ListResponse';

export interface LocationServiceInterface {
  create(values: CreateLocationDTO): Promise<void>;
  getOne(id: string): Promise<Location>;
  list(values?: Object): Promise<ListResponse<Location>>;
  remove(id: string): Promise<void>;
  update(values: UpdateLocationDTO): Promise<void>;
}

export class LocationService implements LocationServiceInterface {
  private _apiService: ApiService;

  constructor(apiService: ApiService) {
    this._apiService = apiService;
  }

  public async create(values: CreateLocationDTO): Promise<void> {
    return await this._apiService.post('/location', {
      name: values.name,
      canPickup: values.canPickup,
      canFastRepair: values.canFastRepair,
      fastRepairWorkHours: values.fastRepairWorkHours || [],
    });
  }

  public async remove(id: string): Promise<void> {
    return await this._apiService.delete(`/location/${id}`);
  }

  public async update(values: UpdateLocationDTO): Promise<void> {
    return await this._apiService.put(`/location/${values.id}`, {
      name: values.name,
      canPickup: values.canPickup,
      canFastRepair: values.canFastRepair,
      fastRepairWorkHours: values.fastRepairWorkHours || [],
    });
  }

  public async getOne(id: string): Promise<Location> {
    return await this._apiService
      .get<LocationDTO>(`/location/${id}`)
      .then((location: LocationDTO) => new Location(location));
  }

  public async list(page: number): Promise<ListResponse<Location>> {
    return await this._apiService
      .get<ListResponse<LocationDTO>>(
        `/location/list?limit=20&offset=${(page - 1) * 20}`,
      )
      .then(response => ({
        ...response,
        items: response.items.map(location => new Location(location)),
      }));
  }

  public async simpleList(): Promise<
    ListResponse<{ id: string; name: string }>
  > {
    return await this._apiService.get<
      ListResponse<{ id: string; name: string }>
    >('/location/simple-list');
  }
}
