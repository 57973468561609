import React from "react";
import { MDBInput, MDBBtn } from "mdbreact";
import { Field, FieldProps as FormikFieldProps, FormikProps } from "formik";

import ErrorMessage from "../../common/form/ErrorMessage";
import ResetPasswordContainer, {
  textStyles,
  titleStyles
} from "./ResetPasswordContainer";

export interface ResetTemplateProps extends FormikProps<object> {
  isActive: boolean;
}
export default ({ isValid, isActive }: ResetTemplateProps) => (
  <ResetPasswordContainer
    header={
      <div>
        <p style={titleStyles}>
          {isActive ? "Zmiana hasła" : "Aktywacja konta"}
        </p>
        <p style={textStyles}>
          {isActive
            ? "Wpisz nowe hasło do konta"
            : "Aby aktywować konto ustaw hasło"}
        </p>
      </div>
    }
  >
    <Field
      name="password"
      render={({ field }: FormikFieldProps) => (
        <MDBInput {...field} type="password" hint="Hasło" outline>
          <ErrorMessage name="password" />
        </MDBInput>
      )}
    />
    <Field
      name="password_confirm"
      render={({ field }: FormikFieldProps) => (
        <MDBInput {...field} type="password" hint="Powtórz hasło" outline>
          <ErrorMessage name="password" />
        </MDBInput>
      )}
    />
    <MDBBtn type="submit" color="primary" className="w-100" disabled={!isValid}>
      {isActive ? "Zresetuj hasło" : "Aktywuj konto"}
    </MDBBtn>
  </ResetPasswordContainer>
);
