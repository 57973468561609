import React from "react";
import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption
} from "mdbreact";
import { DeviceBrand } from "../../../../../../models/device/brand/DeviceBrand";

interface Props {
  onChange: Function;
  options: DeviceBrand[];
}

export default ({ onChange, options }: Props) => (
  <div>
    <MDBSelect
      className="m-0"
      getValue={(value: Array<string>) => onChange(value[0])}
    >
      <MDBSelectInput selected="Wybierz marke" />
      <MDBSelectOptions>
        {options.map(option => (
          <MDBSelectOption key={option.id} value={option.id}>
            {option.name}
          </MDBSelectOption>
        ))}
      </MDBSelectOptions>
    </MDBSelect>
  </div>
);
