import React, { Fragment } from 'react';
import { useSheetsQueue } from '@rootsher/use-sheets-queue';
import { useFormikContext } from 'formik-latest';
import { wire } from 'react-hot-wire';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { StepComponentType } from '../index';
import { Select } from '../../../form/field/select/select';
import DeviceCreate from './device-create';
import { ErrorBox, RepairValues } from '../../create';
import DevicePreview from './device-preview';
import { RepairService } from '../../../../../services/repair/Repair.service';
import { Device as DeviceClass } from '../../../../../models/device/Device';

export const Device: StepComponentType<{ repairService: RepairService }> = ({
  send,
  repairService,
}) => {
  const { values, setFieldValue } = useFormikContext<RepairValues>();
  const [push] = useSheetsQueue();

  if (values.device) {
    return (
      <DevicePreview
        send={send}
        reset={() => setFieldValue('device', undefined)}
        values={values}
      />
    );
  }

  return (
    <div className="d-flex flex-column my-4">
      {values.client ? (
        <Fragment>
          <Select<RepairValues>
            name="device"
            label="Wybierz urządzenie..."
            fetch={async () => {
              const client = await repairService.client({
                id: values.client?.value,
              });
              return repairService.clientDevices({
                client: client.profiles[0].id,
              });
            }}
          />
          <Typography align="center" className="my-3">
            lub
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              push(
                DeviceCreate,
                {
                  side: 'right',
                  size: 50,
                  onSubmit: (device: DeviceClass) =>
                    setFieldValue('device', device.id),
                },
                { side: 'right', size: 80 },
              )
            }
          >
            utwórz urządzenie
          </Button>
        </Fragment>
      ) : (
        <ErrorBox>
          <Typography>
            <small>
              Zanim wybierzesz urządzenie -{' '}
              <strong onClick={() => send('PREVIOUS')}>wybierz klienta</strong>.
            </small>
          </Typography>
        </ErrorBox>
      )}
      <div className="mt-4">
        <Button
          color="primary"
          className="mr-3"
          onClick={() => send('PREVIOUS')}
        >
          wstecz
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!values.device}
          onClick={() => send('NEXT')}
        >
          dalej
        </Button>
      </div>
    </div>
  );
};

export default wire(['repairService'], Device);
