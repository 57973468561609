import * as Yup from 'yup';

import { UserRoles } from '../User';

export default {
  [UserRoles.customer]: [
    {
      type: 'text',
      name: 'phone',
      label: 'Phone*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'firstname',
      label: 'Imię*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'surname',
      label: 'Nazwisko*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'checkbox',
      name: 'silent',
      label: 'Cicha rejestracja',
    },
  ],
  [UserRoles.business_customer]: [
    {
      type: 'select',
      name: 'location',
      label: 'Lokacja użytkowika',
      placeholder: 'Lokacja użytkowika',
      options: [],
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Phone*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'firstname',
      label: 'Imię*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'surname',
      label: 'Nazwisko*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
  ],
  [UserRoles.partner]: [
    {
      type: 'select',
      name: 'location',
      label: 'Lokacja użytkowika',
      placeholder: 'Lokacja użytkowika',
      options: [],
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Phone',
    },
    {
      type: 'text',
      name: 'firstname',
      label: 'Imię*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'surname',
      label: 'Nazwisko*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'nickname',
      label: 'Nickname',
    },
    {
      type: 'text',
      name: 'externalId',
      label: 'Identyfikator NTSN*',
      validator: Yup.string().required('required'),
    },
  ],
  [UserRoles.employee]: [
    {
      type: 'select',
      name: 'location',
      label: 'Lokacja użytkowika',
      placeholder: 'Lokacja użytkowika',
      options: [],
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Phone',
    },
    {
      type: 'text',
      name: 'firstname',
      label: 'Imię*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'surname',
      label: 'Nazwisko*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'nickname',
      label: 'Nickname',
    },
  ],
  [UserRoles.admin]: [
    {
      type: 'select',
      name: 'location',
      label: 'Lokacja użytkowika',
      placeholder: 'Lokacja użytkowika',
      options: [],
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Phone',
    },
    {
      type: 'text',
      name: 'firstname',
      label: 'Imię*',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'surname',
      label: 'Nazwisko',
      validator: Yup.string()
        .min(1, 'too-short')
        .max(80, 'too-long')
        .required('required'),
    },
    {
      type: 'text',
      name: 'nickname',
      label: 'Nickname',
    },
  ],
} as { [key: string]: object[] };
