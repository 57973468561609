import React from 'react';
import { Field, FieldProps as FormikFieldProps } from 'formik';
import { MDBInput } from 'mdbreact';

import { DefaultField, FieldProps } from '.';
import ErrorMessage from '../../ErrorMessage';

export default ({
  field: { name, label, placeholder, ...rest },
  formikProps: { values },
}: FieldProps) => (
  <Field
    name={name}
    render={({ field }: FormikFieldProps<DefaultField>) => (
      <MDBInput
        {...field}
        id={name}
        type="checkbox"
        label={label}
        checked={values[name]}
        {...rest as {}}
        outline
      >
        <ErrorMessage name={name} />
      </MDBInput>
    )}
  />
);
