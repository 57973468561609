import React, { PureComponent, Fragment } from 'react';
import { wire } from 'react-hot-wire';

import SimpleList from '../../../../common/list/simple-list/SimpleList';
import { NTSNRepairService } from '../../../../../services/ntsn/NTSNRepair.service';

export class NTSNCurrentRepairList extends PureComponent<{
  NTSNRepairService: NTSNRepairService;
}> {
  public render() {
    return (
      <Fragment>
        <SimpleList
          title="NTSN - aktualne naprawy"
          columns={[
            {
              label: 'Data dodania',
              name: 'createDate',
            },
            {
              label: 'Imie',
              name: 'firstname',
            },
            {
              label: 'Nazwisko',
              name: 'surname',
            },
            {
              label: 'Urządzenie (typ, marka)',
              name: 'device',
            },
            {
              label: 'IMEI',
              name: 'imei',
            },
            {
              label: 'S/N',
              name: 'serialNumber',
            },
            {
              label: 'Komentarz',
              name: 'comment',
            },
            {
              label: 'Status naprawy',
              name: 'repairStatus',
            },
            {
              label: 'Cost (zl)',
              name: 'cost',
            },
          ]}
          fetchList={() =>
            this.props.NTSNRepairService.currentRepairList().then((list) =>
              list.map((repair) => ({
                ...repair,
                repairStatus: (
                  <div
                    className="badge"
                    style={{
                      backgroundColor: `${repair.statusColor}`,
                    }}
                  >
                    <span style={{ color: '#333' }}>
                      {repair.statusDescription}
                    </span>
                  </div>
                ),
              })),
            )
          }
        />
      </Fragment>
    );
  }
}

export default wire(['NTSNRepairService'], NTSNCurrentRepairList);
