import React, { Fragment } from "react";
import { MDBInput, MDBBtn } from "mdbreact";

import { FieldProps } from "..";

export interface Props extends FieldProps {
  createDevice: Function;
}

export default ({
  createDevice,
  field: { name, placeholder },
  formikProps: { values, setFieldValue }
}: Props) => (
  <div className="grey lighten-4 p-2 pt-3 mb-3 shadow-sm rounded">
    {values[name] ? (
      <Fragment>
        <pre>{JSON.stringify(values[name], null, "    ")}</pre>
        <MDBBtn size="sm" onClick={() => setFieldValue(name, null)}>
          Wybierz od nowa
        </MDBBtn>
      </Fragment>
    ) : (
      <Fragment>
        <MDBInput
          label="Identyfikator urządzenia"
          hint="Wpisz S/N lub IMEI"
          containerClass="m-0"
          labelClass="grey lighten-4"
          outline
        />
        <div className="d-flex justify-content-between">
          <MDBBtn size="sm" disabled>
            Znajdź urządzenie
          </MDBBtn>
          <MDBBtn color="primary" size="sm" onClick={createDevice}>
            Dodaj nowe urządzenie
          </MDBBtn>
        </div>
      </Fragment>
    )}
  </div>
);
