import { pickBy } from 'lodash';

import { ApiService } from '../Api.service';
import {
  CreateCustomerDTO,
  EditCustomerDTO,
  Customer,
  CustomerDTO,
} from '../../models/customer/Customer';

export interface CustomerServiceInterface {
  register(values: CreateCustomerDTO): Promise<void>;
  list(): Promise<Customer[]>;
}

export class CustomerService implements CustomerServiceInterface {
  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async register(values: CreateCustomerDTO): Promise<void> {
    return await this._apiService.post('/customer/create', values);
  }

  public async update(values: EditCustomerDTO): Promise<void> {
    return await this._apiService.put(`/customer/${values.id}`, {
      emailAddress: values.email,
      firstname: values.firstname,
      surname: values.surname,
      phone: values.phone,
      notes: values.notes,
      password: values.password ? values.password : null,
      confirmPassword: values.confirmPassword ? values.confirmPassword : null,
      userPassword: values.userPassword ? values.userPassword : null,
    });
  }

  public async list(query?: { id: string }): Promise<Customer[]> {
    return await this._apiService
      .get<CustomerDTO[]>(
        '/customer/list',
        pickBy(query, (key) => key),
      )
      .then((list) =>
        list.map((customer: CustomerDTO) => new Customer(customer)),
      );
  }
}
