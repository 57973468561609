import React from 'react';
import { wire } from 'react-hot-wire';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';

import { ApiService } from '../../../services/Api.service';

import { RepairStatusTranslation } from '../../../models/repair/RepairStatus';

export type CountStatisticsProps = {
  apiService: ApiService;
};

interface CountStatisticsDTO {
  customersCount: number;
  businessCustomersCount: number;
  partnersCount: number;
  employeeCount: number;
  repairsCount: { [key: string]: number };
}

const CountStatisticsWrapper: React.FC = ({ children }) => (
  <Box>
    <Typography variant="h5" className="mb-3">
      Statystyki
    </Typography>
    <Box>
      <Paper>
        <Box p={2}>{children}</Box>
      </Paper>
    </Box>
  </Box>
);

export const CountStatistics = ({ apiService }: CountStatisticsProps) => {
  const { isLoading, error, data } = useQuery<CountStatisticsDTO, Error>(
    'count-statistic',
    () => apiService.get<CountStatisticsDTO>('/dashboard/statistics'),
  );

  if (isLoading) {
    return (
      <CountStatisticsWrapper>
        <CircularProgress />
      </CountStatisticsWrapper>
    );
  }

  if (error) {
    return (
      <CountStatisticsWrapper>
        <p>
          <b>Wystąpił błąd</b>
        </p>
        <pre>
          {JSON.stringify(error.message ? error.message : error, null, 2)}
        </pre>
      </CountStatisticsWrapper>
    );
  }

  if (!data) {
    return <CountStatisticsWrapper>Brak danych</CountStatisticsWrapper>;
  }

  return (
    <CountStatisticsWrapper>
      <Grid container>
        <Grid item xs={6}>
          <Typography>Ilość kont:</Typography>
          <ul>
            <li>Klientów: {data.customersCount}</li>
            <li>Klientów biznesowych: {data.businessCustomersCount}</li>
            <li>Partnerów: {data.partnersCount}</li>
            <li>Pracowników: {data.employeeCount}</li>
          </ul>
        </Grid>
        <Grid item xs={6}>
          <Typography>Ilość napraw per status:</Typography>
          <ul>
            {Object.entries(data.repairsCount).map(([name, count]) => (
              <li key={name}>
                {RepairStatusTranslation[name]}: {count}
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </CountStatisticsWrapper>
  );
};

export default wire(['apiService'], CountStatistics);
