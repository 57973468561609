import { wire } from 'react-hot-wire';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Container } from '@material-ui/core';

import SimpleTwoColTable, {
  SimpleTwoColTableRow,
} from '../../common/table/simple-two-col/simple-two-col-table';

import {
  Repair,
  RepairCourierOrder,
  RepairExpress,
} from '../../../models/repair/Repair';
import { CustomerProfile } from '../../../models/customer/CustomerProfile';
import { Device } from '../../../models/device/Device';
import { Location } from '../../../models/location/Location';
import { GuestCustomer } from '../../../models/user/guest-customer';

import { RepairService } from '../../../services/repair/Repair.service';

export interface PrintRepairWarrantyProps {
  repairService: RepairService;
}

export interface PrintRepairWarrantyParams {
  repairId: string;
}

const customerRows: Array<SimpleTwoColTableRow<Repair>> = [
  {
    key: 'firstname',
    field: 'customer',
    name: 'Imię',
    valueFormatter: (value: CustomerProfile) => value.firstname,
  },
  {
    key: 'surname',
    field: 'customer',
    name: 'Nazwisko',
    valueFormatter: (value: CustomerProfile) => value.surname,
  },
  {
    field: 'contactPhone',
    name: 'Telefon kontaktowy',
    valueFormatter: (value: Repair['contactPhone']) => value || '---',
  },
  {
    field: 'contactEmail',
    name: 'Email kontaktowy',
    valueFormatter: (value: Repair['contactEmail']) => value || '---',
  },
];

const guestCustomerRows: Array<SimpleTwoColTableRow<Repair>> = [
  {
    key: 'firstname',
    field: 'guestCustomer',
    name: 'Imię',
    valueFormatter: (value: GuestCustomer) => value.firstname,
  },
  {
    key: 'surname',
    field: 'guestCustomer',
    name: 'Nazwisko',
    valueFormatter: (value: GuestCustomer) => value.surname,
  },
  {
    field: 'contactPhone',
    name: 'Telefon kontaktowy',
    valueFormatter: (value: Repair['contactPhone']) => value || '---',
  },
  {
    field: 'contactEmail',
    name: 'Email kontaktowy',
    valueFormatter: (value: Repair['contactEmail']) => value || '---',
  },
];

const deviceRows: Array<SimpleTwoColTableRow<Repair>> = [
  {
    key: 'deviceId',
    field: 'device',
    name: 'Identyfikator (S/N)',
    valueFormatter: (device: Device) => device.identifier,
  },
  {
    key: 'model',
    field: 'device',
    name: 'Model',
    valueFormatter: (device: Device) => device.model.name,
  },
  {
    key: 'type',
    field: 'device',
    name: 'Typ',
    valueFormatter: (device: Device) =>
      device.model.type ? device.model.type.name : '---',
  },
  {
    key: 'brand',
    field: 'device',
    name: 'Marka',
    valueFormatter: (device: Device) =>
      device.model.brand ? device.model.brand.name : '---',
  },
];

const generalRows: Array<SimpleTwoColTableRow<Repair>> = [
  {
    field: 'createdAt',
    name: 'Data utworzenia',
    valueFormatter: (value: Date) => value.toLocaleString(),
  },
  {
    field: 'location',
    name: 'Punkt',
    valueFormatter: (value: Location | undefined) =>
      value ? value.name : '---',
  },
  {
    field: 'courierOrder',
    name: 'Odbiór od klienta',
    renderCell: (value: RepairCourierOrder | undefined) =>
      value ? (
        <div>
          <p>
            Tak ({value.hourFrom.toLocaleDateString()} od{' '}
            {value.hourFrom.getHours()}:
            {value.hourFrom.getMinutes() > 9 ? '' : '0'}
            {value.hourFrom.getMinutes()} do {value.hourTo.getHours()}:
            {value.hourTo.getMinutes() > 9 ? '' : '0'}
            {value.hourTo.getMinutes()})
          </p>
          <p>Adres: {value.address}</p>
        </div>
      ) : (
        <div>Nie</div>
      ),
  },
  {
    field: 'express',
    name: 'Naprawa ekspresowa',
    renderCell: (value: RepairExpress | undefined) =>
      value ? <div>Tak ({value.date.toLocaleString()})</div> : <div>Nie</div>,
  },
];

export const PrintRepairWarranty = ({
  repairService,
}: PrintRepairWarrantyProps) => {
  const [repair, setRepair] = useState<Repair | null>(null);
  const { repairId } = useParams<PrintRepairWarrantyParams>();

  useEffect(() => {
    repairService.getOne(repairId).then(setRepair);
  }, [repairService, repairId]);

  useEffect(() => {
    if (repair) {
      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    }
  }, [repair]);

  if (!repair) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Box my={2} fontWeight={500}>
        <Box mb={3}>
          <img src="/logo.svg" alt="Dillcom" />
        </Box>
        <Box my={2}>DILLCOM SERWIS Jan Łajewski</Box>

        <Box mb={2}>
          ul. Kasprowicza 81/85, 01-836 Warszawa
          <br />
          NIP: 534-253-1683
        </Box>

        <Box mb={2}>
          Telefon: 791-495-495
          <br />
          E-mail: biuro@dillcom.pl
        </Box>
        <Box>www.dillcom.pl</Box>
      </Box>
      <Box component="h2" textAlign="center">
        Naprawa nr {repair.number}
      </Box>
      <Box component="h4" textAlign="center">
        Karta naprawy urządzenia
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box width="50%" p="15px">
          <h4>Dane klienta</h4>
          <Box>
            {repair.customer && (
              <SimpleTwoColTable data={repair} rows={customerRows} />
            )}
            {repair.guestCustomer && (
              <SimpleTwoColTable data={repair} rows={guestCustomerRows} />
            )}
          </Box>
        </Box>
        <Box width="50%" p="15px">
          <h4>Dane naprawy</h4>
          <Box>
            <SimpleTwoColTable data={repair} rows={generalRows} />
          </Box>
        </Box>
        <Box width="50%" p="15px">
          <h4>Dane urządzenia</h4>
          <Box>
            <SimpleTwoColTable data={repair} rows={deviceRows} />
          </Box>
        </Box>
        <Box width="50%" display="flex" style={{ flexDirection: 'column' }}>
          <Box p="15px">
            <h4>Opis stanu urządzenia</h4>
            <Box>{repair.deviceStatusDescription || '---'}</Box>
          </Box>
          <Box p="15px">
            <h4>Załączone akcesoria</h4>
            <Box>{repair.accessoriesList || '---'}</Box>
          </Box>
          <Box p="15px">
            <h4>Notatki</h4>
            <Box>{repair.description || '---'}</Box>
          </Box>
        </Box>

        <Box
          width="100%"
          mt={2}
          fontWeight={500}
          fontSize={24}
          textAlign="center"
        >
          Gwarancja na wykonaną naprawę
        </Box>

        <Box mt={3}>
          <ol>
            <li>
              Serwis gwarantuje poprawne działanie naprawionych podzespołów
              (zgodnie z Kartą Naprawy nr {repair.number} w okresiemiesięcy od
              dnia {new Date().toLocaleDateString()}).
            </li>
            <li>
              Gwarancja dotyczy wyłącznie przypadków niesprawnego działania
              sprzętu, których przyczyną są wady produkcyjnejednego lub kilku
              podzespołów.
            </li>
            <li>
              Gwarancja nie dotyczy czynności konserwacyjnych użytkownika
              sprzętu opisanych w instrukcji obsługi. Czynności te sąwyłącznie
              obowiązkiem użytkownika i są wykonywane na jego koszt.
            </li>
            <li>Gwarancja nie obejmuje zużycia materiałów eksploatacyjnych.</li>
            <li>
              Dostarczenie sprzętu do serwisu mieszczącego się pod adresem
              Kasprowicza 81/85 lok 6 01-836 Warszawa jest nakoszt użytkownika.
              Użytkownik zobowiązany jest do odpowiedniego zapakowania sprzętu.
              Wyłącznie użytkownikodpowiada za uszkodzenia powstałe w trakcie
              transportu. Dostarczony sprzęt musi odpowiadać podstawowym
              warunkomhigienicznym, w przeciwnym wypadku czyszczenie sprzętu
              będzie odpłatne na koszt użytkownika.
            </li>
          </ol>
        </Box>

        <Box mt={2} textAlign="center" width="100%">
          Powyższa Karta Gwarancyjna ważna jest wyłącznie z pieczęcią i podpisem
          autoryzowanego sprzedawcy.
        </Box>

        <Box
          width="100%"
          pt="50px"
          display="flex"
          flexWrap="nowrap"
          justifyContent="space-between"
          mt={4}
        >
          <Box
            width="25%"
            minHeight="75px"
            style={{ borderBottom: '2px black dotted' }}
          >
            Potwierdzam odbiór (Imie, Nazwisko, Data)
          </Box>
          <Box
            width="25%"
            minHeight="75px"
            style={{ borderBottom: '2px black dotted' }}
          >
            DILLCOM SERWIS Jan Łajewski
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default wire(['repairService'], PrintRepairWarranty);
