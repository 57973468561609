import React, { PureComponent } from "react";
import { wire } from "react-hot-wire";

import DeviceType from "./DeviceType.template";
import { DeviceTypeService } from "../../../../../../services/device/DeviceType.service";

export interface Props {
  onChange: Function;
  deviceTypeService: DeviceTypeService;
}

export class DeviceTypeComponent extends PureComponent<Props> {
  state = {
    options: []
  };

  componentDidMount() {
    this.fetchOptions();
  }

  render() {
    return <DeviceType {...this.props} {...this.state} />;
  }

  private async fetchOptions() {
    this.setState({ options: await this.props.deviceTypeService.list() });
  }
}

export default wire(["deviceTypeService"], DeviceTypeComponent);
