import * as React from 'react';
import { FC } from 'react';

import { QueueStatus } from '../../../models/queue/QueueStatus';
import { QueueJob } from '../../../models/queue/QueueJob';

const SingleStatus: FC<{
  label: string;
  status: QueueJob[];
  className?: string;
}> = ({ label, status, className }) => (
  <span className={`px-2 ${className || ''}`}>
    {label}: {status.length}
  </span>
);

export const JobsStatus: FC<{ statues: QueueStatus }> = ({ statues }) => (
  <div>
    <b>Stan kolejki:</b>
    <SingleStatus
      label="Oczekujące"
      status={statues.waiting}
      className={statues.waiting.length ? 'text-info' : ''}
    />
    <SingleStatus label="Opóźnione" status={statues.delayed} />
    <SingleStatus
      label="Aktywne"
      status={statues.active}
      className={statues.active.length ? 'text-primary font-weight-bold' : ''}
    />
    <SingleStatus
      label="Ukończne"
      status={statues.completed}
      className="text-success"
    />
    <SingleStatus
      label="Nieudane"
      status={statues.failed}
      className={statues.failed.length ? 'text-danger' : ''}
    />
  </div>
);
