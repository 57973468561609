import React, { useEffect, useState, Fragment } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { wire } from 'react-hot-wire';

import { LocationService } from '../../../../services/location/Location.service';
import { EmployeeService } from '../../../../services/employee/Employee.service';
import { UserService } from '../../../../services/user/User.service';

import { User, UserRoles } from '../../../../models/user/User';
import {
  RepairStatus,
  RepairStatusTranslation,
} from '../../../../models/repair/RepairStatus';

import Spinner from '../../../common/spinner/Spinner';
import NewPagination, {
  NewPaginationProps,
} from '../../../common/pagination/new-pagination';

export interface RepairListTableHeaderProps extends NewPaginationProps {
  onSearchValueChange: (event: string) => void;
  onStatusValueChange: (event: RepairStatus | undefined) => void;
  onLocationChange: (event: string | undefined) => void;
  onTechnicianChange: (event: string | undefined) => void;
  locationService: LocationService;
  employeeService: EmployeeService;
  userService: UserService;
}

const useStyles = makeStyles({
  root: {
    margin: '10px',
    height: '70px',
    width: '100%',
    '& .MuiInputLabel-formControl': {
      backgroundColor: 'transparent',
    },
  },
  textInput: {
    height: '70px',
  },
  fullHeight: {
    height: '100%',
  },
});

export const RepairListTableHeader = ({
  onSearchValueChange,
  onStatusValueChange,
  onLocationChange,
  onTechnicianChange,
  page,
  pageCount,
  setPage,
  locationService,
  employeeService,
  userService,
}: RepairListTableHeaderProps) => {
  const [locations, setLocations] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [locationsLoading, setLocationsLoading] = useState<boolean>(true);
  const [technicians, setTechnicians] = useState<User[]>([]);
  const [techniciansLoading, setTechniciansLoading] = useState<boolean>(true);

  const classes = useStyles();

  useEffect(() => {
    if (
      userService.user &&
      userService.user.hasOneOfRoles([UserRoles.admin, UserRoles.receptionist])
    ) {
      locationService
        .simpleList()
        .then(list => {
          setLocations(list.items);
          setLocationsLoading(false);
        })
        .catch(console.error);

      employeeService
        .getTechnicians()
        .then(list => {
          setTechnicians(list);
          setTechniciansLoading(false);
        })
        .catch(console.error);
    }
  }, [userService, locationService, employeeService]);

  return (
    <Grid
      container
      className={classes.root}
      spacing={2}
      alignItems="flex-start"
    >
      <Grid item xs={3}>
        <TextField
          variant="outlined"
          placeholder="Wyszukaj (minimum 3 znaki)"
          helperText="Id naprawy, klient (imię, nazwisko, numer telefonu, email), identyfikator urządzenia"
          onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
            onSearchValueChange(event.target.value)
          }
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              onStatusValueChange(event.target.value as
                | RepairStatus
                | undefined)
            }
            label="Status"
          >
            <MenuItem value={undefined}>
              <em>-</em>
            </MenuItem>
            {Object.entries(RepairStatus).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {RepairStatusTranslation[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {userService.user &&
        userService.user.hasOneOfRoles([
          UserRoles.admin,
          UserRoles.receptionist,
        ]) && (
          <Fragment>
            <Grid item xs={2}>
              {techniciansLoading ? (
                <Spinner small />
              ) : (
                <FormControl variant="outlined">
                  <InputLabel>Technik</InputLabel>
                  <Select
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      onTechnicianChange(event.target.value as
                        | string
                        | undefined)
                    }
                    label="Technik"
                  >
                    <MenuItem value={undefined}>
                      <em>-</em>
                    </MenuItem>
                    {technicians.map(user => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.profile.viewName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={2}>
              {locationsLoading ? (
                <Spinner small />
              ) : (
                <FormControl variant="outlined">
                  <InputLabel>Punkt</InputLabel>
                  <Select
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      onLocationChange(event.target.value as string | undefined)
                    }
                    label="Punkt"
                  >
                    <MenuItem value={undefined}>
                      <em>-</em>
                    </MenuItem>
                    {locations.map(location => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Fragment>
        )}
      <Grid
        item
        container
        xs
        justify="flex-end"
        alignItems="center"
        className={classes.fullHeight}
      >
        <NewPagination page={page} pageCount={pageCount} setPage={setPage} />
      </Grid>
    </Grid>
  );
};

export default wire(
  ['locationService', 'employeeService', 'userService'],
  RepairListTableHeader,
);
