import { ApiService } from "../Api.service";
import { NTSNRepairDTO, NTSNRepair } from "../../models/ntsn/NTSNRepair";

export class NTSNRepairService {
  private _apiService: ApiService;

  constructor(apiService: ApiService) {
    this._apiService = apiService;
  }

  public async currentRepairList(): Promise<NTSNRepair[]> {
    return await this._apiService
      .get<NTSNRepairDTO[]>("/repair/currents")
      .then(list =>
        list.map((service: NTSNRepairDTO) => new NTSNRepair(service))
      );
  }

  public async historyRepairList(): Promise<NTSNRepair[]> {
    return await this._apiService
      .get<NTSNRepairDTO[]>("/repair/history")
      .then(list =>
        list.map((service: NTSNRepairDTO) => new NTSNRepair(service))
      );
  }
}
