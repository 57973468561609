import React from 'react';
import { Pagination } from '@material-ui/lab';

export interface NewPaginationProps {
  page: number;
  pageCount: number;
  setPage: (page: number) => void;
}

export const NewPagination = ({
  page,
  pageCount,
  setPage,
}: NewPaginationProps) => (
  <Pagination
    color="primary"
    page={page}
    count={pageCount}
    onChange={(event, value) => setPage(value)}
  />
);

export default NewPagination;
