import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import { RepairService } from '../../../services/repair/Repair.service';
import { RepairChangeEvent } from '../../../models/repair/Repair';
import HistoryChange from './history/history-change';

export interface RepairDetailsTabProps {
  repairId: string;
  repairService: RepairService;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    summary: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '40%',
      flexShrink: 0,
      color: theme.palette.text.secondary,
    },
    additionalHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      textAlign: 'right',
      flexBasis: '27%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    details: {
      display: 'block',
    },
  }),
);

export const RepairHistoryTab = ({
  repairId,
  repairService,
}: RepairDetailsTabProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [changes, setChanges] = useState<RepairChangeEvent[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    repairService
      .history(repairId)
      .then(changes => {
        setChanges(
          changes.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
        );
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setError(e);
      });
  }, [repairService, repairId]);

  if (error) {
    return (
      <div>
        <p>Wystąpił błąd</p>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    );
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (!changes || !changes.length) {
    return <div>Brak zmian</div>;
  }

  return (
    <div className={classes.root}>
      {changes.map(row => (
        <HistoryChange key={row.id} event={row} />
      ))}
    </div>
  );
};

export default RepairHistoryTab;
