import React, { PureComponent, Fragment } from 'react';
import * as Yup from 'yup';
import { wire } from 'react-hot-wire';
import { MDBSelect } from 'mdbreact';

import { UserService } from '../../../services/user/User.service';
import { ApplicationService } from '../../../services/application/Application.service';
import { DrawerService } from '../../../services/drawer/Drawer.service';
import {
  NotificationMessageType,
  NotificationService,
} from '../../../services/application/Notification.service';

import SimpleForm, {
  SimpleFormProps,
} from '../../common/form/simple-form/SimpleForm';

import { Field } from '../../common/form/simple-form/field';

import { UserRoles } from '../../../models/user/User';
import createFields from '../../../models/user/Fields/Create';
import { LocationService } from '../../../services/location/Location.service';
import Spinner from '../../common/spinner/Spinner';

export interface CreateUserFormInterface {
  id: string;
  email: string;
  nickname: string;
  firstname: string;
  surname: string;
  externalId?: string;
  [key: string]: string | undefined;
}

export interface CreateUserState {
  userType: UserRoles | null;
  role: UserRoles | null;
  loading: boolean;
  locations: Array<{ value: string; content: string }>;
}

export class CreateUser extends PureComponent<
  {
    simpleFormProps: SimpleFormProps;
    userService: UserService;
    applicationService: ApplicationService;
    notificationService: NotificationService;
    drawerService: DrawerService;
    locationService: LocationService;
  },
  CreateUserState
> {
  public state: CreateUserState;
  private typeOptions = [
    {
      text: 'Klient',
      value: UserRoles.customer,
    },
    {
      text: 'Klient biznesowy',
      value: UserRoles.business_customer,
    },
    {
      text: 'Partner',
      value: UserRoles.partner,
    },
    {
      text: 'Pracownik',
      value: UserRoles.employee,
      optionsPlaceholder: 'Wybierz typ pracownika',
      options: [
        {
          text: 'Technik',
          value: UserRoles.technician,
        },
        {
          text: 'Recepcjonista(-ka)',
          value: UserRoles.receptionist,
        },
        {
          text: 'Przewoźnik',
          value: UserRoles.carrier,
        },
      ],
    },
    {
      text: 'Administrator',
      value: UserRoles.admin,
    },
  ];

  constructor(props: any) {
    super(props);

    this.state = {
      userType: null,
      role: null,
      loading: true,
      locations: [],
    };
  }

  public componentDidMount() {
    this.fetchLocations();
  }

  public render() {
    const optionSettings = this.getCurrentOptionSettings();
    const hasSubRoles =
      optionSettings && optionSettings.options && optionSettings.options.length;

    return (
      <SimpleForm
        title="Tworzenie użytkownika"
        submitButton="Utwórz"
        submittingButton="Trwa tworzenie..."
        onSubmit={(values: CreateUserFormInterface) =>
          this.onSave({ ...values, role: this.state.role })
            .then(() =>
              this.props.notificationService.notify({
                message: 'Użytkownik utworzony pomyślnie',
                type: NotificationMessageType.SUCCESS,
              }),
            )
            .catch((error: any) => {
              console.error(error);
              this.props.notificationService.notify({
                message: 'Błąd przy tworzeniu użytkownika',
                type: NotificationMessageType.ERROR,
              });
            })
        }
        fields={[
          {
            type: 'text',
            name: 'email',
            label: 'E-mail*',
            validator: Yup.string()
              .min(1, 'too-short')
              .max(80, 'too-long')
              .required('required'),
          },
          ...this.getFields(),
        ]}
        render={({ email, ...rest }: any) =>
          this.state.loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div>
                <MDBSelect
                  options={this.typeOptions}
                  placeholder="Typ użytkownika"
                  selected="Wybierz typ użytkownika"
                  getValue={(value: string[]) =>
                    this.selectedTypeChanged(value)
                  }
                />
                {optionSettings && hasSubRoles && (
                  <MDBSelect
                    options={optionSettings.options}
                    selected={
                      optionSettings.optionsPlaceholder || 'Typ użytkownika'
                    }
                    getValue={(value: string[]) =>
                      this.selectedSubTypeChanged(value)
                    }
                  />
                )}
              </div>
              {this.state.userType && (hasSubRoles ? this.state.role : true) && (
                <Fragment>
                  <div>{email()}</div>
                  {this.renderFields(rest)}
                </Fragment>
              )}
            </Fragment>
          )
        }
        {...this.props.simpleFormProps}
      />
    );
  }

  private getFields(): Array<Field> {
    const fields = (createFields[this.state.userType || 'default'] ||
      []) as Field[];

    const locationField = fields.find((field) => field.name === 'location');

    if (locationField) {
      locationField.options = this.state.locations;
    }

    return fields;
  }

  private renderFields(fields: { [key: string]: Function }) {
    const fieldsToRender: any[] = [];

    if (fields.silent) {
      fieldsToRender.push(
        <div key="silent">{fields.silent({ containerClass: 'm-0' })}</div>,
      );
    }
    if (fields.location) {
      fieldsToRender.push(
        <div key="location">{fields.location({ containerClass: 'm-0' })}</div>,
      );
    }

    if (fields.phone) {
      fieldsToRender.push(
        <div key="phone">{fields.phone({ containerClass: 'm-0' })}</div>,
      );
    }

    if (fields.firstname && fields.surname) {
      fieldsToRender.push(
        <div className="d-flex" key="name">
          <div className="flex-grow-1 mr-3">
            {fields.firstname({ containerClass: 'm-0' })}
          </div>
          <div className="flex-grow-1">
            {fields.surname({ containerClass: 'm-0' })}
          </div>
        </div>,
      );
    }

    if (fields.nickname) {
      fieldsToRender.push(
        <div key="nickname">{fields.nickname({ containerClass: 'm-0' })}</div>,
      );
    }

    if (fields.externalId) {
      fieldsToRender.push(
        <div key="externalId">
          {fields.externalId({ containerClass: 'm-0' })}
        </div>,
      );
    }

    if (fields.password && fields.confirmPassword) {
      fieldsToRender.push(
        <div className="d-flex" key="password">
          <div className="flex-grow-1 mr-3">
            {fields.password({ containerClass: 'm-0' })}
          </div>
          <div className="flex-grow-1">
            {fields.confirmPassword({ containerClass: 'm-0' })}
          </div>
        </div>,
      );
    }

    return <Fragment>{fieldsToRender.map((field) => field)}</Fragment>;
  }

  private selectedTypeChanged(userType: string[]) {
    this.setState({
      userType: userType[0] as UserRoles,
      role: null,
    });
  }

  private selectedSubTypeChanged(userType: string[]) {
    this.setState({
      role: userType[0] as UserRoles,
    });
  }

  private getCurrentOptionSettings() {
    const roleOption = this.typeOptions.find(
      (option) => option.value === this.state.userType,
    );

    if (this.state.userType && roleOption) {
      return roleOption;
    }

    return null;
  }

  private onSave(values: any): Promise<void> {
    if (!this.state.userType) {
      throw new Error('User type not selected');
    }

    return this.props.userService.register(this.state.userType, values);
  }

  private async fetchLocations() {
    const locationsListResponse = await this.props.locationService.simpleList();

    this.setState({
      loading: false,
      locations: locationsListResponse.items.map((location) => ({
        content: location.name,
        value: location.id,
      })),
    });
  }
}

export default wire(
  [
    'notificationService',
    'drawerService',
    'applicationService',
    'userService',
    'drawerService',
    'locationService',
  ],
  CreateUser,
);
