import { pickBy } from 'lodash';

import { ApiService } from '../../../Api.service';
import {
  BrandModel,
  BrandModelDTO,
  CreateBrandModelDTO,
  UpdateBrandModelDTO,
} from '../../../../models/device/brand/model/BrandModel';

export interface ModelServiceInterface {
  create(values: CreateBrandModelDTO): Promise<void>;
  getOne(id: string): Promise<BrandModel>;
  list(values?: Object): Promise<BrandModel[]>;
  remove(id: string): Promise<void>;
  update(values: UpdateBrandModelDTO): Promise<void>;
}

export class BrandModelService implements ModelServiceInterface {
  private _apiService: ApiService;

  constructor(apiService: ApiService) {
    this._apiService = apiService;
  }

  public async create(values: CreateBrandModelDTO): Promise<void> {
    return await this._apiService.post('/model', {
      name: values.name,
      brandId: values.brandId,
      typeId: values.typeId,
      systemMargin: values.systemMargin,
      partnerMargins: values.partnerMargins,
      shopItemsIds: values.shopItemsIds,
    });
  }

  public async remove(id: string): Promise<void> {
    return await this._apiService.delete(`/model/${id}`);
  }

  public async update(values: UpdateBrandModelDTO): Promise<void> {
    return await this._apiService.put(`/model/${values.id}`, {
      name: values.name,
      brandId: values.brandId,
      typeId: values.typeId,
      systemMargin: values.systemMargin,
      partnerMargins: values.partnerMargins,
      shopItemsIds: values.shopItemsIds,
    });
  }

  public async getOne(id: string): Promise<BrandModel> {
    return await this._apiService
      .get<BrandModelDTO>(`/model/${id}`)
      .then((model: BrandModelDTO) => new BrandModel(model));
  }

  public async list(query?: {
    typeId?: string | number;
    brandId?: string | number;
  }): Promise<BrandModel[]> {
    return await this._apiService
      .get<BrandModelDTO[]>('/model', pickBy(query, key => key))
      .then(list => list.map((model: BrandModelDTO) => new BrandModel(model)));
  }
}
