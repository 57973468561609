import React, { Fragment, PureComponent } from "react";
import Select from "../select/Select.template";

import { wire } from "react-hot-wire";
import { FieldProps } from "../index";
import { DeviceTypeService } from "../../../../../../services/device/DeviceType.service";
import { DeviceBrandService } from "../../../../../../services/device/brand/DeviceBrand.service";
import { BrandModelService } from "../../../../../../services/device/brand/model/BrandModel.service";
import { BrandModel } from "../../../../../../models/device/brand/model/BrandModel";
import * as Yup from "yup";

export interface SelectOption {
  value: string;
  content: string;
}

export class ModelComponent extends PureComponent<
  FieldProps & {
    deviceTypeService: DeviceTypeService;
    deviceBrandService: DeviceBrandService;
    brandModelService: BrandModelService;
  }
> {
  state: {
    typeOptions: Array<SelectOption>;
    brandOptions: Array<SelectOption>;
    modelOptions: Array<SelectOption>;
    currentType: number;
    currentBrand: number;
    currentModel: number;
  };

  constructor(
    props: FieldProps & {
      deviceTypeService: DeviceTypeService;
      deviceBrandService: DeviceBrandService;
      brandModelService: BrandModelService;
    }
  ) {
    super(props);

    this.state = {
      typeOptions: [],
      brandOptions: [],
      modelOptions: [],
      currentType: 0,
      currentBrand: 0,
      currentModel: 0
    };
  }

  fetchData({
    service,
    store,
    id
  }: {
    service: any;
    store: string;
    id?: number;
  }) {
    return service
      .list()
      .then((data: any) =>
        data.map((type: any) => {
          return { value: type.id, content: type.name };
        })
      )
      .then((options: any) => this.setState({ [store]: options }));
  }

  fetchModels() {
    const { currentType: typeId, currentBrand: brandId } = this.state;

    return this.props.brandModelService
      .list({ typeId, brandId })
      .then((data: BrandModel[]) => {
        return data.map((type: BrandModel) => {
          return { value: String(type.id), content: type.name };
        });
      })
      .then((options: SelectOption[]) =>
        this.setState({ modelOptions: options })
      );
  }

  componentDidMount() {
    const initialValues = this.props.formikProps.initialValues;

    if (
      initialValues["brandId"] &&
      initialValues["typeId"] &&
      initialValues["typeId"]
    ) {
      this.setState(
        {
          currentBrand: initialValues["brandId"],
          currentType: initialValues["typeId"],
          currentModel: initialValues["modelId"]
        },
        () => {
          this.fetchModels().then(() => {
            this.forceUpdate();
            this.fetchData({
              service: this.props.deviceTypeService,
              store: "typeOptions"
            });
            this.fetchData({
              service: this.props.deviceBrandService,
              store: "brandOptions"
            });
          });
          this.forceUpdate();
        }
      );
      this.forceUpdate();
    } else {
      this.fetchData({
        service: this.props.deviceTypeService,
        store: "typeOptions"
      });
      this.fetchData({
        service: this.props.deviceBrandService,
        store: "brandOptions"
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Select
          {...this.props}
          field={{
            name: "typeId",
            type: "select",
            placeholder: "Wybierz typ urządzenia",
            label: "Typ",
            options: this.state.typeOptions,
            className: "mt-1",
            validator: Yup.string()
              .max(2, "too-short")
              .max(255, "too-long")
              .required("required")
          }}
          formikProps={{
            ...this.props.formikProps,
            setFieldValue: (type: any, value: any) => {
              this.props.formikProps.setFieldValue(type, value);
              this.setState({ currentType: value }, () => {
                this.fetchModels();
              });
            }
          }}
        />
        <Select
          {...this.props}
          field={{
            name: "brandId",
            type: "select",
            label: "Marka",
            placeholder: "Wybierz markę urządzenia",
            options: this.state.brandOptions,
            validator: Yup.string()
              .max(2, "too-short")
              .max(255, "too-long")
              .required("required")
          }}
          formikProps={{
            ...this.props.formikProps,
            setFieldValue: (type: any, value: any) => {
              this.props.formikProps.setFieldValue(type, value);
              this.setState({ currentBrand: value }, () => {
                this.fetchModels();
              });
            }
          }}
        />
        {this.state.currentType &&
        this.state.currentBrand &&
        this.state.modelOptions.length ? (
          <Select
            {...this.props}
            field={{
              name: "modelId",
              type: "select",
              placeholder: "Wybierz model urządzenia",
              options: this.state.modelOptions,
              disabled: !(this.state.currentType || this.state.currentBrand),
              validator: Yup.string()
                .max(2, "too-short")
                .max(255, "too-long")
                .required("required")
            }}
            formikProps={{
              ...this.props.formikProps,
              setFieldValue: (type: any, value: any) => {
                this.props.formikProps.setFieldValue(type, value);
                this.setState({ currentModel: value });
              }
            }}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default wire(
  ["deviceTypeService", "deviceBrandService", "brandModelService"],
  ModelComponent
);
