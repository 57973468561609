import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@material-ui/core';

import { preciseAdd } from '../../../helpers/decimalHelper';

import { Service } from '../../../models/service/Service';
import { Repair } from '../../../models/repair/Repair';

import { ServiceService } from '../../../services/service/Service.service';
import { RepairService } from '../../../services/repair/Repair.service';
import { RepairAssignedService } from '../../../models/repair/RepairAssignedService';

export interface RepairServicesTabProps {
  repair: Repair;
  canEdit: boolean;
  repairService: RepairService;
  serviceService: ServiceService;
}

type RepairServicesList = Array<{
  key: string;
  id?: string;
  assignedServiceId?: string;
  checked: boolean;
  name: string;
  price: number;
  priceNet: number;
  old?: boolean;
}>;

export const RepairServicesTab = ({
  repair,
  canEdit,
  serviceService,
  repairService,
}: RepairServicesTabProps) => {
  const [modelServices, setModelServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [repairServices, setRepairServices] = useState<RepairAssignedService[]>(
    [],
  );
  const [servicesList, setServicesList] = useState<RepairServicesList>([]);
  const [error, setError] = useState<any>();

  useEffect(() => {
    setLoading(true);
    serviceService
      .list({ modelId: repair.device.model.id })
      .then((list) => setModelServices(list))
      .then(() => repairService.getRepairServices(repair.id))
      .then((list) => {
        setRepairServices(list);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(e);
      });
  }, [serviceService, repairService, repair]);

  useEffect(() => {
    const list: RepairServicesList = modelServices.map((modelService) => {
      const assignedService = repairServices.find(
        (service) => service.originalServiceId === modelService.id,
      );

      return {
        key: modelService.id,
        id: modelService.id,
        checked: !!assignedService,
        assignedServiceId: assignedService ? assignedService.id : undefined,
        name: modelService.name,
        price: modelService.price,
        priceNet: modelService.priceNet,
      };
    });

    repairServices.forEach((repairService) => {
      if (
        !list.find((service) => service.assignedServiceId === repairService.id)
      ) {
        list.unshift({
          key: `old-${repairService.id}`,
          old: true,
          checked: true,
          assignedServiceId: repairService.id,
          name: repairService.name,
          price: repairService.price,
          priceNet: repairService.priceNet,
        });
      }
    });

    setServicesList(list);
  }, [repairServices, modelServices, repair]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setServicesList(
      servicesList.map((service) => ({
        ...service,
        checked: event.target.name === service.key ? checked : service.checked,
      })),
    );
  };

  const onSave = () => {
    setLoading(true);
    repairService
      .setServices(
        repair.id,
        servicesList
          .filter((service) => service.checked)
          .map((service) => ({
            serviceId: service.id,
            repairServiceId: service.assignedServiceId,
          })),
      )
      .then((updatedList) => {
        setRepairServices(updatedList);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
      });
  };

  if (error) {
    return (
      <div>
        <p>Wystąpił błąd</p>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    );
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (!canEdit) {
    return (
      <div>
        {servicesList.map((service) => (
          <p key={service.key}>
            {service.name} ({service.price} zł)
          </p>
        ))}
      </div>
    );
  }

  return (
    <div>
      <div>
        <p>Legenda</p>
        <div>
          <Checkbox color="secondary" checked={true} /> - Usługa dodana do
          naprawy, ale nie istnieje już w ofercie i zniknie z listy po usunięciu
          z naprawy
        </div>
        <div>
          <Checkbox color="primary" checked={true} /> - Usługa dodana do naprawy
        </div>
      </div>

      <Button
        variant="contained"
        color="primary"
        className="my-4"
        onClick={onSave}
      >
        Zapisz
      </Button>

      <FormControl component="fieldset">
        <FormLabel component="legend">
          Usługi (Koszt całkowity:{' '}
          {repairServices.reduce(
            (summary, service) => preciseAdd(summary, service.price, 2),
            0,
          )}{' '}
          zł) (Ceny brutto)
        </FormLabel>
        <FormGroup>
          {servicesList.map((service) => (
            <FormControlLabel
              key={service.key}
              control={
                <Checkbox
                  color={service.old ? 'secondary' : 'primary'}
                  name={service.key}
                  onChange={handleChange}
                />
              }
              label={`${service.name} (${service.price} zł)`}
              checked={service.checked}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default RepairServicesTab;
