import { CustomerProfile, CustomerProfileDTO } from './CustomerProfile';

export interface CustomerDTO {
  id: string;
  emailAddress: string;
  profiles: CustomerProfileDTO[];
}

export interface CreateCustomerDTO {
  email: string;
  firstname: string;
  surname: string;
  phone: string;
  notes: string;
  password?: string;
  confirmPassword?: string;
  silent?: boolean;
}

export interface EditCustomerDTO extends CreateCustomerDTO {
  id: string;
  userPassword?: string;
}

export class Customer {
  private _id: string;
  private _email: string;
  private _profiles: CustomerProfile[];

  constructor(customer: CustomerDTO) {
    this._id = customer.id;
    this._email = customer.emailAddress;
    this._profiles = customer.profiles.map(
      (profile) => new CustomerProfile(profile),
    );
  }

  public get id(): string {
    return this._id;
  }

  public get email(): string {
    return this._email;
  }

  public get profiles(): CustomerProfile[] {
    return this._profiles;
  }

  public set email(email) {
    this._email = email;
  }

  public set profiles(profiles) {
    this._profiles = profiles;
  }
}
