import React, { CSSProperties, PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import { ActionEventService } from '../../../../services/application/ActionEvent.service';

import SimpleList, {
  SimpleListProps,
} from '../../../common/list/simple-list/SimpleList';
import { DrawerService } from '../../../../services/drawer/Drawer.service';
import { MDBBtn } from 'mdbreact';
import { ActionEvent } from '../../../../models/application/ActionEvent';

const warningStyles: CSSProperties = {
  padding: '.7rem',
  background: 'orange',
};

const errorStyles: CSSProperties = {
  padding: '.7rem',
  background: 'red',
};

export interface ActionEventListState {
  pageCount: number;
}

export class ActionEventList extends PureComponent<
  {
    actionEventService: ActionEventService;
    drawerService: DrawerService;
  },
  ActionEventListState
> {
  public state: ActionEventListState;

  constructor(props: any) {
    super(props);

    this.state = {
      pageCount: 1,
    };
  }

  public render() {
    const props: SimpleListProps = {
      title: 'Events',
      pageCount: this.state.pageCount,
      columns: [
        {
          label: 'Data',
          name: 'date',
        },
        {
          label: 'Controller',
          name: 'controller',
        },
        {
          label: 'Action',
          name: 'action',
        },
        {
          label: 'Method',
          name: 'method',
        },
        {
          label: 'URL',
          name: 'url',
        },
        {
          label: 'Response Status',
          name: 'responseStatus',
        },
        {
          label: 'Response Time (ms)',
          name: 'responseTime',
        },
        {
          label: 'User',
          name: 'user',
        },
        {
          label: 'Szczegóły',
          name: 'details',
        },
      ],
      fetchList: (filters, page) =>
        this.props.actionEventService.list(page).then((response) => {
          this.setState({
            pageCount: Math.ceil(response.total / response.limit),
          });

          return response.items.map((event) => ({
            date: event.date.toLocaleString(),
            controller: event.controller,
            action: event.action,
            method: event.method,
            url: event.url,
            responseStatus: (
              <span
                style={
                  event.responseStatus >= 500
                    ? errorStyles
                    : event.responseStatus >= 400
                    ? warningStyles
                    : undefined
                }
              >
                {event.responseStatus}
              </span>
            ),
            responseTime: event.time,
            user: event.user,
            details: (
              <MDBBtn onClick={() => this.showDetails(event)} size="sm">
                szczegóły
              </MDBBtn>
            ),
          }));
        }),
    };

    return <SimpleList {...props} />;
  }

  private showDetails(event: ActionEvent) {
    this.props.drawerService.activate('application-action-event-details', {
      simpleFormProps: {
        initialValues: {
          date: event.date.toLocaleString(),
          controller: event.controller,
          action: event.action,
          method: event.method,
          ip: event.ip,
          url: event.url,
          responseStatus: event.responseStatus,
          user: event.user,
          body: JSON.stringify(event.body, undefined, 2),
          response: JSON.stringify(event.error || event.response, undefined, 2),
          responseTime: event.time,
        },
      },
    });
  }
}

export default wire(['actionEventService', 'drawerService'], ActionEventList);
