import application from './application';
import auth from './auth';
import customer from './customer';
import businessCustomer from './businessCustomer';
import device from './device';
import drawer from './drawer';
import ntsn from './ntsn';
import partner from './partner';
import repair from './repair';
import service from './service';
import warehouse from './warehouse';
import user from './user';
import queue from './queue';
import location from './location';
import employee from './employee';
import courier from './courier';
import sales from './sales';

import { ApiService } from './Api.service';

export default {
  ...application,
  ...auth,
  ...customer,
  ...businessCustomer,
  ...device,
  ...drawer,
  ...ntsn,
  ...partner,
  ...repair,
  ...service,
  ...user,
  ...warehouse,
  ...queue,
  ...location,
  ...employee,
  ...courier,
  ...sales,
  apiService: {
    class: ApiService,
    public: true,
    arguments: [
      { BASE_URL: process.env.REACT_APP_API_URL },
      { $ref: 'accessTokenService' },
      { $ref: 'navigationService' },
    ],
  },
};
