import React, { PureComponent } from "react";
import { wire } from "react-hot-wire";

import Brand from "./Brand.template";
import { DeviceBrandService } from "../../../../../../services/device/brand/DeviceBrand.service";

export interface Props {
  onChange: Function;
  deviceBrandService: DeviceBrandService;
}

export class BrandComponent extends PureComponent<Props> {
  state = {
    options: []
  };

  componentDidMount() {
    this.fetchOptions();
  }

  render() {
    return <Brand {...this.props} {...this.state} />;
  }

  private async fetchOptions() {
    this.setState({ options: await this.props.deviceBrandService.list() });
  }
}

export default wire(["deviceBrandService"], BrandComponent);
