import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import { DrawerService } from '../../../services/drawer/Drawer.service';
import { LocationService } from '../../../services/location/Location.service';

import SimpleList, {
  SimpleListProps,
} from '../../common/list/simple-list/SimpleList';

import { Location } from '../../../models/location/Location';

export interface LocationListState {
  pageCount: number;
}

export class LocationList extends PureComponent<{
  locationService: LocationService;
  drawerService: DrawerService;
}> {
  public state: LocationListState;

  constructor(props: any) {
    super(props);

    this.state = {
      pageCount: 1,
    };
  }

  public render() {
    const props: SimpleListProps = {
      title: 'Lista punktów',
      pageCount: this.state.pageCount,
      createButtonText: 'dodaj punkt',
      columns: [
        {
          label: 'Nazwa',
          name: 'name',
        },
        {
          label: 'Odbiór',
          name: 'canPickupTranslated',
        },
        {
          label: 'Szybkie naprawy',
          name: 'canFastRepairTranslated',
        },
      ],
      fetchList: (filterValues: any, page: number) => {
        return this.props.locationService.list(page).then(response => {
          this.setState({
            pageCount: Math.ceil(response.total / response.limit),
          });

          return response.items.map(location => ({
            id: location.id,
            name: location.name,
            canPickupTranslated: location.canPickup ? 'Tak' : 'Nie',
            canPickup: location.canPickup,
            canFastRepairTranslated: location.canFastRepair ? 'Tak' : 'Nie',
            canFastRepair: location.canFastRepair,
            fastRepairWorkHours: location.fastRepairWorkHours,
          }));
        });
      },
    };

    props.editHandler = ({
      onEdit,
      row,
    }: {
      onEdit: Function;
      row: Location;
    }) => {
      this.props.drawerService.activate('edit-location', {
        simpleFormProps: {
          initialValues: {
            id: row.id,
            name: row.name,
            canPickup: row.canPickup,
            canFastRepair: row.canFastRepair,
            fastRepairWorkHours: row.fastRepairWorkHours,
          },
          onResolved: onEdit,
        },
      });
    };

    props.deleteHandler = ({
      onDelete,
      row,
    }: {
      onDelete: Function;
      row: Location;
    }) => this.props.locationService.remove(row.id).then(() => onDelete());

    props.createHandler = ({ onCreate }: { onCreate: Function }) =>
      this.props.drawerService.activate('create-location', {
        simpleFormProps: {
          onResolved: onCreate,
        },
      });

    return <SimpleList {...props} />;
  }
}

export default wire(['locationService', 'drawerService'], LocationList);
