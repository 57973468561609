import { PureComponent } from "react";
import { wire } from "react-hot-wire";

export class InitAuthorization extends PureComponent<any> {
  state = {
    render: false
  };

  componentDidMount() {
    this.props.applicationService
      .init()
      .then(() => this.setState({ render: true }));
  }

  render() {
    return this.state.render ? this.props.children : null;
  }
}

export default wire(["applicationService"], InitAuthorization);
