import React from 'react';
import { Field, FieldProps } from 'formik-latest';
import moment from 'moment';

import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { DateValidationProps } from '@material-ui/pickers/_helpers/date-utils';
import TextField from '@material-ui/core/TextField';

export type PickerProps = {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  className?: string;
};

export function DatePicker<T>(props: PickerProps & DateValidationProps) {
  return (
    <Field name={props.name}>
      {({ field, meta, form }: FieldProps<T>) => {
        return (
          <MuiDatePicker
            {...field}
            inputFormat="dd-MM-yyyy"
            mask="__-__-____"
            minDate={props.minDate}
            disabled={props.disabled}
            onChange={(value) => {
              form.setFieldTouched(field.name);
              form.setFieldValue(field.name, value && moment(value));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...props}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                variant="outlined"
              />
            )}
          />
        );
      }}
    </Field>
  );
}
