import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';

import { StepComponentType } from '../index';
import { TextField } from '../../../form/field/text-field/input';
import { useFormikContext } from 'formik-latest';
import { RepairValues } from '../../create';
import { DatePicker } from '../../../form/field/date-time/date/date';

export const Notes: StepComponentType = ({ send, user }) => {
  const { errors, isValid } = useFormikContext<RepairValues>();

  return (
    <div className="my-4">
      <TextField
        name="notes"
        label="Notatki / inne"
        placeholder="Podaj notatki..."
        multiline
        rows={4}
      />
      <Fragment>
        <DatePicker<RepairValues>
          name="createdAt"
          label="Data utworzenia naprawy"
          placeholder="Wybierz datę..."
          disabled={!user.isAdmin()}
          className="mt-3"
        />
      </Fragment>
      <div className="mt-4">
        <Button
          color="primary"
          className="mr-3"
          onClick={() => send('PREVIOUS')}
        >
          wstecz
        </Button>
        <Button
          color="secondary"
          disabled={!isValid}
          variant="contained"
          type="submit"
        >
          utwórz naprawę
        </Button>
        <pre style={{ color: 'red' }}>{JSON.stringify(errors, null, '\t')}</pre>
      </div>
    </div>
  );
};
