import Brand from "./brand/Brand";
import BrandModel from "./brand-model/BrandModel";
import DeviceType from "./device-type/DeviceType";
import { ReactElement } from "react";

const filters: { [key: string]: ReactElement } = {
  brand: Brand,
  brandModel: BrandModel,
  deviceType: DeviceType
};

export default filters;
