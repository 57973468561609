import { PartnerProfile, PartnerProfileDTO } from "./PartnerProfile";

export interface PartnerDTO {
  id: string;
  emailAddress: string;
  password: string;
  profiles: PartnerProfileDTO[];
  roles: string[];
}

export class Partner {
  private static ROLES = {
    admin: "ADMIN_ROLE",
    partner: "PARTNER_ROLE"
  };

  private _id: string;
  private _email: string;
  private _password: string;
  private _profiles: PartnerProfile[];
  private _roles: string[];

  constructor(user: PartnerDTO) {
    this._id = user.id;
    this._email = user.emailAddress;
    this._password = user.password;
    this._profiles = (user.profiles || []).map(
      (profile: PartnerProfileDTO) => new PartnerProfile(profile)
    );
    this._roles = user.roles || [];
  }

  public get id() {
    return this._id;
  }

  public get email() {
    return this._email;
  }

  public get password() {
    return this._password;
  }

  public get profiles() {
    return this._profiles;
  }

  public hasRole(role: string): boolean {
    return this._roles.includes(role);
  }

  public isAdmin() {
    return this._roles.includes(Partner.ROLES.admin);
  }

  public isPartner() {
    return this._roles.includes(Partner.ROLES.partner);
  }
}
