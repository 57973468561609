import React from "react";
import { Field, FieldProps as FormikFieldProps } from "formik";
import { MDBInput } from "mdbreact";

import { DefaultField, FieldProps } from ".";
import ErrorMessage from "../../ErrorMessage";

export default ({
  field: { name, label, placeholder, ...rest }
}: FieldProps) => (
  <Field
    name={name}
    render={({ field }: FormikFieldProps<DefaultField>) => (
      <MDBInput {...field} label={label} hint={placeholder} {...rest} outline>
        <ErrorMessage name={name} />
      </MDBInput>
    )}
  />
);
