import { ApiService } from "../Api.service";
import {
  Config,
  ConfigDTO,
  UpdateConfigDTO
} from "../../models/application/Config";
import { UserService } from "../user/User.service";

export class ApplicationService {
  private _apiService: ApiService;
  private _userService: UserService;

  private _config: Config | null;

  constructor(apiService: ApiService, userService: UserService) {
    this._apiService = apiService;
    this._userService = userService;

    this._config = null;
  }

  public get config(): Config | null {
    return this._config;
  }

  public async fetchConfig(): Promise<Config> {
    return await this._apiService
      .get<ConfigDTO>("/application/settings")
      .then(config => new Config(config))
      .then(config => (this._config = config));
  }

  public async init() {
    return await Promise.all([this._userService.fetchDetails()]);
  }

  public updateApplicationSettings(config: UpdateConfigDTO): Promise<void> {
    return this._apiService.put("/application/settings", config);
  }
}
