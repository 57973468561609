export interface UserProfileDTO {
  id: string;
  nickname: string;
  sex: string;
  firstname: string;
  surname: string;
  notes: string;
  phone: string;
  type: string;
}

export class UserProfile {
  private _id: string;
  private _type: string;
  private _nickname: string;
  private _sex: string;
  private _firstname: string;
  private _surname: string;
  private _phone: string;
  private _notes: string;

  constructor(profile: UserProfileDTO) {
    this._id = profile.id;
    this._type = profile.type;
    this._nickname = profile.nickname || "";
    this._sex = profile.sex || "";
    this._firstname = profile.firstname || "";
    this._surname = profile.surname || "";
    this._phone = profile.phone || "";
    this._notes = profile.notes || "";
  }

  public get id() {
    return this._id;
  }

  public get type() {
    return this._type;
  }

  public get viewName() {
    if (this._firstname && this._surname) {
      return `${this._firstname} ${this._surname}`;
    }

    return this._nickname;
  }

  public get nickname() {
    return this._nickname;
  }

  public get sex() {
    return this._sex;
  }

  public get firstname() {
    return this._firstname;
  }

  public get surname() {
    return this._surname;
  }

  public get notes() {
    return this._notes;
  }

  public get phone() {
    return this._phone;
  }
}
