const preciseOperation = (f: (a: number, b: number) => number) => (
  a: number,
  b: number,
  decimalDigits: number,
): number => {
  return +f(a, b).toFixed(decimalDigits || 12);
};
const add = (a: number, b: number) => a + b;
const minus = (a: number, b: number) => a - b;
const multiply = (a: number, b: number) => a * b;
const divide = (a: number, b: number) => a / b;
export const preciseAdd = (a: number, b: number, decimalDigits: number) =>
  preciseOperation(add)(a, b, decimalDigits);
export const preciseMinus = (a: number, b: number, decimalDigits: number) =>
  preciseOperation(minus)(a, b, decimalDigits);
export const preciseMultiply = (a: number, b: number, decimalDigits: number) =>
  preciseOperation(multiply)(a, b, decimalDigits);
export const preciseDivide = (a: number, b: number, decimalDigits: number) =>
  preciseOperation(divide)(a, b, decimalDigits);
