import { ApiService } from "../Api.service";
import {
  CreateDeviceTypeDTO,
  DeviceType,
  DeviceTypeDTO,
  UpdateDeviceTypeDTO
} from "../../models/device/DeviceType";

export interface DeviceTypeServiceInterface {
  create(values: CreateDeviceTypeDTO): Promise<void>;
  getOne(id: string): Promise<DeviceType>;
  list(): Promise<DeviceType[]>;
  remove(id: string): Promise<void>;
  update(values: UpdateDeviceTypeDTO): Promise<void>;
}

export class DeviceTypeService implements DeviceTypeServiceInterface {
  private _apiService: ApiService;

  constructor(apiService: ApiService) {
    this._apiService = apiService;
  }

  public async create({
    name,
    systemMargin,
    partnerMargins
  }: CreateDeviceTypeDTO): Promise<void> {
    return await this._apiService.post("/device-type", {
      name,
      systemMargin,
      partnerMargins
    });
  }

  public async remove(id: string | null): Promise<void> {
    return await this._apiService.delete(`/device-type/${id}`);
  }
  public async update({
    id,
    name,
    systemMargin,
    partnerMargins
  }: UpdateDeviceTypeDTO): Promise<void> {
    return await this._apiService.put(`/device-type/${id}`, {
      name,
      systemMargin,
      partnerMargins
    });
  }

  public async getOne(id: string | null): Promise<DeviceType> {
    return await this._apiService
      .get<DeviceType>(`/device-type/${id}`)
      .then((deviceType: DeviceTypeDTO) => new DeviceType(deviceType));
  }

  public async list(): Promise<DeviceType[]> {
    return await this._apiService
      .get<DeviceTypeDTO[]>("/device-type")
      .then(list =>
        list.map((deviceType: DeviceTypeDTO) => new DeviceType(deviceType))
      );
  }
}
