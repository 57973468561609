import React, { PureComponent } from "react";
import * as Yup from "yup";
import { wire } from "react-hot-wire";

import { DeviceService } from "../../../services/device/Device.service";
import { CreateDeviceDTO } from "../../../models/device/Device";

import SimpleForm, {
  SimpleFormProps
} from "../../common/form/simple-form/SimpleForm";

export class CreateDevice extends PureComponent<{
  deviceService: DeviceService;
  simpleFormProps: SimpleFormProps;
}> {
  public render() {
    return (
      <SimpleForm
        title="Tworzenie urządzenia"
        submitButton="Dodaj urządzenie"
        submittingButton="Trwa tworzenie urządzenia..."
        onSubmit={(values: CreateDeviceDTO) =>
          this.props.deviceService.create(values)
        }
        fields={[
          {
            type: "model",
            name: "modelId",
            validator: Yup.string()
              .min(1, "too-short")
              .max(80, "too-long")
              .required("required")
          },
          {
            type: "text",
            name: "identifier",
            label: "ID",
            placeholder: "Wpisz ID urządzenia",
            validator: Yup.string()
              .min(2, "too-short")
              .max(50, "too-long")
              .required("required")
          },
          {
            type: "text",
            name: "unlockPassword",
            label: "Hasło do urządzenia",
            placeholder: "Wpisz hasło do urządzenia",
            validator: Yup.string()
              .min(2, "too-short")
              .max(50, "too-long")
              .required("required")
          }
        ]}
        {...this.props}
      />
    );
  }
}

export default wire(["deviceService"], CreateDevice);
