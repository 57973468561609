import React, { PureComponent } from "react";
import { AuthService } from "../../../services/auth/Auth.service";
import { Formik, FormikProps } from "formik";
import { wire } from "react-hot-wire";
import * as Yup from "yup";

import RequestResetPasswordTemplate from "./RequestPasswordReset.template";
import ResetPasswordContainer, {
  textStyles,
  titleStyles
} from "./ResetPasswordContainer";
import Spinner from "../../common/spinner/Spinner";

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required()
});

export interface RequestResetPasswordState {
  loading: boolean;
  error: boolean;
  success: boolean;
}

export class RequestResetPassword extends PureComponent<{
  authService: AuthService;
}> {
  public state: RequestResetPasswordState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      success: false
    };
  }

  render() {
    if (this.state.loading) {
      return <ResetPasswordContainer header={<Spinner />} />;
    }

    if (this.state.error || this.state.success) {
      return (
        <ResetPasswordContainer
          header={
            this.state.error ? this.getErrorView() : this.getSuccessView()
          }
        />
      );
    }

    return (
      <Formik
        initialValues={{}}
        validationSchema={schema}
        onSubmit={values => this.onSubmit(values)}
        render={(props: FormikProps<object>) => (
          <RequestResetPasswordTemplate {...props} />
        )}
      />
    );
  }

  private async onSubmit(values: { [key: string]: string }) {
    this.setState({
      loading: true
    });

    const result = await this.props.authService.requestPasswordReset({
      email: values.email
    });

    this.setState({
      loading: false,
      success: result,
      error: !result
    });
  }

  private getErrorView() {
    return (
      <div>
        <p style={titleStyles} className="mt-3">
          Wystąpił błąd
        </p>
        <p style={textStyles} className="mt-3">
          Spróbuj ponownie później. <br />
        </p>
      </div>
    );
  }

  private getSuccessView() {
    return (
      <div>
        <p style={textStyles} className="mt-3">
          Jeśli podany adres email istnieje w bazie został wysłany email z
          linkiem do ustawienia nowego hasła
        </p>
      </div>
    );
  }
}

export default wire(["authService"], RequestResetPassword);
