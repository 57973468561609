import React, { ReactElement } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HistoryStatusChange from './value-renderer/history-status-change';
import { RepairChangeEvent } from '../../../../models/repair/Repair';
import HistoryStatusChangeHeader from './header-renderer/history-status-change-header';
import HistoryAssignedTechnicianChangeHeader from './header-renderer/history-assigned-technician-change-header';
import HistoryDefaultValue from './value-renderer/history-default-value';
import { repairEventsTranslation } from '../../../../models/repair/repair-events';

export interface HistoryValueProps {
  event: RepairChangeEvent;
}

export type HistoryChangeHeaderProps = Pick<HistoryValueProps, 'event'>;
export type HistoryChangeValueProps = Pick<HistoryValueProps, 'event'>;

const historyValueRenderer: {
  [key: string]: {
    header: (props: HistoryChangeValueProps) => ReactElement;
    value: (props: HistoryChangeValueProps) => ReactElement;
  };
} = {
  setStatus: {
    header: (props: HistoryChangeHeaderProps) => (
      <HistoryStatusChangeHeader {...props} />
    ),
    value: (props: HistoryChangeValueProps) => (
      <HistoryStatusChange {...props} />
    ),
  },
  'change:assignedTechnician': {
    header: (props: HistoryChangeHeaderProps) => (
      <HistoryAssignedTechnicianChangeHeader {...props} />
    ),
    value: (props: HistoryChangeValueProps) => (
      <HistoryDefaultValue {...props} />
    ),
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    summary: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '40%',
      flexShrink: 0,
      color: theme.palette.text.secondary,
    },
    additionalHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      textAlign: 'right',
      flexBasis: '27%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    details: {
      display: 'block',
    },
  }),
);

export const HistoryChange = ({ event }: HistoryValueProps) => {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.heading}>
          {repairEventsTranslation[event.changeType] || event.changeType}
        </Typography>
        <Typography className={classes.secondaryHeading}>
          {event.createdAt.toLocaleString()} -{' '}
          {event.user ? event.user.profile.viewName : 'unknown'}
        </Typography>
        {historyValueRenderer[event.changeType] && (
          <Typography className={classes.additionalHeading}>
            {historyValueRenderer[event.changeType].header({
              event,
            })}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {historyValueRenderer[event.changeType] ? (
          historyValueRenderer[event.changeType].value({
            event,
          })
        ) : (
          <HistoryDefaultValue event={event} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default HistoryChange;
