import React from 'react';
import { wire } from 'react-hot-wire';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useQuery } from 'react-query';

import { RepairService } from '../../../services/repair/Repair.service';

import { RepairsBalanceOverallResponseDTO } from '../../../models/repair/RepairBalanceStatistics';

import { RepairsBalanceWrapper } from './repairs-balance';

export interface RepairsBalanceLifetimeProps {
  repairService: RepairService;
}

const rowTitle: { [key: string]: string } = {
  total: 'Wszystkie',
  completed: 'Zakonczone',
  inProgress: 'W trakcie',
};

export const RepairsBalanceLifetime = ({
  repairService,
}: RepairsBalanceLifetimeProps) => {
  const { isLoading, error, data } = useQuery<
    RepairsBalanceOverallResponseDTO,
    Error
  >('repairs-balance', () => repairService.getRepairsBalanceStatistics());

  if (isLoading) {
    return (
      <RepairsBalanceWrapper>
        <CircularProgress />
      </RepairsBalanceWrapper>
    );
  }

  if (error) {
    return (
      <RepairsBalanceWrapper>
        <p>
          <b>Wystąpił błąd</b>
        </p>
        <pre>
          {JSON.stringify(error.message ? error.message : error, null, 2)}
        </pre>
      </RepairsBalanceWrapper>
    );
  }

  return (
    <RepairsBalanceWrapper title={'Bilans napraw "lifetime"'}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell style={{ minWidth: '100px' }} />
            <TableCell align="center">Netto (zł)</TableCell>
            <TableCell align="center">Brutto (zł)</TableCell>
            <TableCell align="center">Ilość</TableCell>
          </TableHead>
          <TableBody>
            {data &&
              Object.entries(data).map(([key, row]) => (
                <TableRow key={key}>
                  <TableCell>{rowTitle[key]}</TableCell>
                  <TableCell align="center">
                    {row.total.totalPriceNet}
                  </TableCell>
                  <TableCell align="center">
                    {row.total.totalPriceGross}
                  </TableCell>
                  <TableCell align="center">{row.total.repairsCount}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </RepairsBalanceWrapper>
  );
};

export default wire(['repairService'], RepairsBalanceLifetime);
