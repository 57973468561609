export interface ActionEventDTO {
  controller: string;
  action: string;
  url: string;
  ip: string;
  path: string;
  hostname: string;
  body: any;
  params: any;
  query: any;
  time: number;
  date: number;
  method: string;
  responseStatus: number;
  response?: string | object;
  error?: string | object;
  user?: string;
}

export interface ActionEventInterface {
  controller: string;
  action: string;
  url: string;
  ip: string;
  path: string;
  hostname: string;
  body: any;
  params: any;
  query: any;
  time: number;
  date: Date;
  method: string;
  responseStatus: number;
  response?: string | object;
  error?: string | object;
  user?: string;
}

export class ActionEvent implements ActionEventInterface {
  private _controller: string;
  private _action: string;
  private _url: string;
  private _ip: string;
  private _path: string;
  private _hostname: string;
  private _body: any;
  private _params: any;
  private _query: any;
  private _time: number;
  private _date: Date;
  private _method: string;
  private _responseStatus: number;
  private _response?: string | object;
  private _error?: string | object;
  private _user?: string;

  constructor(event: ActionEventDTO) {
    this._controller = event.controller;
    this._action = event.action;
    this._url = event.url;
    this._ip = event.ip;
    this._path = event.path;
    this._hostname = event.hostname;
    this._body = event.body;
    this._params = event.params;
    this._query = event.query;
    this._time = event.time;
    this._date = new Date(Number(event.date));
    this._method = event.method;
    this._responseStatus = event.responseStatus;
    this._response = event.response;
    this._error = event.error;
    this._user = event.user;
  }

  public get controller() {
    return this._controller;
  }

  public get action() {
    return this._action;
  }

  public get url() {
    return this._url;
  }

  public get ip() {
    return this._ip;
  }

  public get path() {
    return this._path;
  }

  public get hostname() {
    return this._hostname;
  }

  public get body() {
    return this._body;
  }

  public get params() {
    return this._params;
  }

  public get query() {
    return this._query;
  }

  public get time() {
    return this._time;
  }

  public get date() {
    return this._date;
  }

  public get method() {
    return this._method;
  }

  public get responseStatus() {
    return this._responseStatus;
  }

  public get response() {
    return this._response;
  }

  public get error() {
    return this._error;
  }

  public get user() {
    return this._user;
  }
}
