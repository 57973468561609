import { ApiService } from "../Api.service";

import { QueueStatus, QueueStatusDTO } from "../../models/queue/QueueStatus";
import { QueueJob } from "../../models/queue/QueueJob";

export class QueueService {
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly _apiService: ApiService) {}

  public getAllQueuesStatus(): Promise<QueueStatus[]> {
    return this._apiService
      .get<{ queues: QueueStatusDTO[] }>("/queue/job/status")
      .then(response =>
        response.queues.map(queue => ({
          name: queue.name,
          waiting: queue.waiting.map(job => new QueueJob(job)),
          active: queue.active.map(job => new QueueJob(job)),
          delayed: queue.delayed.map(job => new QueueJob(job)),
          completed: queue.completed.map(job => new QueueJob(job)),
          failed: queue.failed.map(job => new QueueJob(job))
        }))
      );
  }

  public async emptyQueue(queueName: string): Promise<void> {
    await this._apiService.post(`/queue/empty/${queueName}`);
  }
}
