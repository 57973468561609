import { User, UserDTO } from '../../user/User';

export interface SalesIdeaDTO {
  id: string;
  content: string;
  user: UserDTO;
  created_at: string;
  updated_at: string;
}

export interface AddSalesIdeaDTO {
  content: string;
}

export class SalesIdea {
  private _id: string;
  private _content: string;
  private _user: User;
  private _createdAt: Date;
  private _updatedAt: Date;

  constructor(salesIdea: SalesIdeaDTO) {
    this._id = salesIdea.id;
    this._content = salesIdea.content;
    this._user = new User(salesIdea.user);
    this._createdAt = new Date(salesIdea.created_at);
    this._updatedAt = new Date(salesIdea.updated_at);
  }

  public get id(): string {
    return this._id;
  }

  public get content(): string {
    return this._content;
  }

  public get user(): User {
    return this._user;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }
}
