import { ApiService } from '../Api.service';
import {
  CourierWorkingHoursDTO,
  CreateOrUpdateCourierWorkingHoursDTO,
} from '../../models/courier/CourierConfiguration';

export class CourierService {
  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async setWorkingHoursConfiguration(
    workHours: CreateOrUpdateCourierWorkingHoursDTO[],
  ): Promise<void> {
    return await this._apiService.put(
      '/repair/courier-order/configuration/work-hours',
      {
        workHours,
      },
    );
  }

  public async fetchWorkingHoursConfiguration(): Promise<
    CourierWorkingHoursDTO[]
  > {
    return await this._apiService.get<CourierWorkingHoursDTO[]>(
      '/repair/courier-order/configuration/work-hours',
    );
  }
}
