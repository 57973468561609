import moment from "moment";

export interface NTSNRepairDTO {
  iId: number;
  iAddDate: number;
  sFName: string;
  sSName: string;
  sHName: string;
  sField2: string;
  sField3: string;
  sFieldLong: string;
  statusColor: string;
  statusDescription: string;
  sCost: string;
  sMCost: string;
}

export class NTSNRepair {
  public id: number;
  public createDate: string;
  public firstname: string = "";
  public surname: string = "";
  public device: string = "";
  public imei: string = "---";
  public serialNumber: string = "---";
  public comment: string = "---";
  public statusColor: string = "";
  public statusDescription: string = "";
  public cost: string = "";
  public mcost: string = "";

  constructor(repair: NTSNRepairDTO) {
    this.id = repair.iId;
    this.createDate = moment
      .unix(repair.iAddDate)
      .format("DD-MM-YYYY HH:mm:ss");
    this.firstname = repair.sFName;
    this.surname = repair.sSName;
    this.device = repair.sHName;
    this.imei = repair.sField2;
    this.serialNumber = repair.sField3;
    this.comment = repair.sFieldLong;
    this.statusColor = repair.statusColor;
    this.statusDescription = repair.statusDescription;
    this.cost = repair.sCost;
    this.mcost = repair.sMCost;
  }
}
