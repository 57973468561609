import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import ReactNotification, { store } from 'react-notifications-component';

import { NotificationService } from '../../../services/application/Notification.service';
import { RealtimeCommunicationService } from '../../../services/application/communication/RealtimeCommunication.service';
import { RepairStatusTranslation } from '../../../models/repair/RepairStatus';

interface RepairStatusChangedEvent {
  name: string;
  data: {
    repairNumber: string;
    status: string;
    oldStatus: string;
  };
}

interface NewRepairEvent {
  name: string;
  data: {
    repairNumber: string;
  };
}

interface RepairAssignedToYou {
  name: string;
  data: {
    repairNumber: string;
  };
}

export class Notification extends PureComponent<{
  notificationService: NotificationService;
  realtimeCommunicationService: RealtimeCommunicationService;
}> {
  componentDidMount(): void {
    this.props.notificationService.subscribe(
      ({ message, type = 'info', duration }) =>
        this.notify({ type, message, duration }),
    );

    this.props.realtimeCommunicationService.subscribe(
      'newRepair',
      (event: NewRepairEvent) =>
        this.notify({
          type: 'info',
          message: `Dodano nową naprawę (${event.data.repairNumber})`,
          duration: 60000,
        }),
    );

    this.props.realtimeCommunicationService.subscribe(
      'repairAssignedToYou',
      (event: RepairAssignedToYou) =>
        this.notify({
          type: 'info',
          message: `Nowa naprawa została do Ciebie przypisana: ${
            event.data.repairNumber
          }`,
          duration: 60000,
        }),
    );

    this.props.realtimeCommunicationService.subscribe(
      'repairStatusChanged',
      (event: RepairStatusChangedEvent) =>
        this.notify({
          type: 'info',
          message: `Zaktualizowano status naprawy numer ${
            event.data.repairNumber
          }: ${RepairStatusTranslation[event.data.oldStatus]} => ${
            RepairStatusTranslation[event.data.status]
          }`,
          duration: 60000,
        }),
    );

    this.props.realtimeCommunicationService.subscribe(
      'warehouse_integration_sync_start',
      () =>
        this.notify({
          type: 'info',
          message: 'Synchronizacja magazynu rozpoczęta',
        }),
    );

    this.props.realtimeCommunicationService.subscribe(
      'warehouse_integration_sync_error',
      () =>
        this.notify({
          type: 'danger',
          message: 'Synchronizacja magazynu zakończona błędem',
        }),
    );

    this.props.realtimeCommunicationService.subscribe(
      'warehouse_integration_sync_failed',
      () =>
        this.notify({
          type: 'danger',
          message: 'Synchronizacja magazynu nieudana',
        }),
    );

    this.props.realtimeCommunicationService.subscribe(
      'warehouse_integration_sync_finish',
      () =>
        this.notify({
          type: 'success',
          message: 'Synchronizacja magazynu zakończona',
        }),
    );
  }

  public render() {
    return <ReactNotification />;
  }

  private notify({
    type,
    message,
    duration = 8000,
  }: {
    type: string;
    message: string;
    duration?: number;
  }) {
    store.addNotification({
      message,
      type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      width: 350,
      dismiss: {
        duration,
        onScreen: true,
        pauseOnHover: true,
        click: false,
        showIcon: true,
      },
    });
  }
}

export default wire(
  ['notificationService', 'realtimeCommunicationService'],
  Notification,
);
