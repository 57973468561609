import React, { Fragment } from 'react';
import { wire } from 'react-hot-wire';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useQuery } from 'react-query';

import { RepairService } from '../../../services/repair/Repair.service';

import { RepairsBalanceOverallResponseDTO } from '../../../models/repair/RepairBalanceStatistics';

export interface RepairsBalanceProps {
  repairService: RepairService;
}

const rowTitle: { [key: string]: string } = {
  total: 'Wszystkie',
  completed: 'Zakonczone',
  inProgress: 'W trakcie',
};

const useStyles = makeStyles({
  repairsBalanceTable: {
    '& .color-green': {
      color: '#58d50b',
    },
    '& .color-red': {
      color: '#d50b2c',
    },
  },
});

const calculateDifference = (v1: number, v2: number): number | null =>
  v1 > 0 && v2 > 0 ? Number(((v2 - v1) / Math.abs(v1)) * 100) : null;
const parseDifference = (difference: number | null): string =>
  difference
    ? `${difference.toLocaleString(undefined, { maximumFractionDigits: 2 })}%`
    : '-';
const getDifferenceCellColor = (difference: number | null): string =>
  classnames({
    'color-green': difference && difference > 0,
    'color-red': difference && difference < 0,
  });

export const RepairsBalanceWrapper: React.FC<{ title?: string }> = ({
  title,
  children,
}) => (
  <Box mb={4}>
    <Typography variant="h5" className="mb-3">
      {title || 'Bilans napraw'}
    </Typography>
    <Box>{children}</Box>
  </Box>
);

export const RepairsBalance = ({ repairService }: RepairsBalanceProps) => {
  const { isLoading, error, data } = useQuery<
    RepairsBalanceOverallResponseDTO,
    Error
  >('repairs-balance', () => repairService.getRepairsBalanceStatistics());
  const classes = useStyles();

  if (isLoading) {
    return (
      <RepairsBalanceWrapper>
        <CircularProgress />
      </RepairsBalanceWrapper>
    );
  }

  if (error) {
    return (
      <RepairsBalanceWrapper>
        <p>
          <b>Wystąpił błąd</b>
        </p>
        <pre>
          {JSON.stringify(error.message ? error.message : error, null, 2)}
        </pre>
      </RepairsBalanceWrapper>
    );
  }

  return (
    <RepairsBalanceWrapper>
      <TableContainer component={Paper} className={classes.repairsBalanceTable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: '100px' }} />
              <TableCell align="center" colSpan={3}>
                Dzisiaj
              </TableCell>
              <TableCell align="center" colSpan={3}>
                Poprzedni tydzien
              </TableCell>
              <TableCell align="center" colSpan={3}>
                Ten tydzien
              </TableCell>
              <TableCell align="center">Progress tygodniowy</TableCell>
              <TableCell align="center" colSpan={3}>
                Poprzedni miesiąc
              </TableCell>
              <TableCell align="center" colSpan={3}>
                Ten miesiąc
              </TableCell>
              <TableCell align="center">Progress miesięczny</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell align="center">Netto (zł)</TableCell>
              <TableCell align="center">Brutto (zł)</TableCell>
              <TableCell align="center">Ilość</TableCell>
              {Array.from({ length: 6 }).map((v, k) =>
                (k + 1) % 3 === 0 ? (
                  <TableCell key={k} align="center">
                    Netto (zł)
                  </TableCell>
                ) : (
                  <Fragment key={k}>
                    <TableCell align="center">Netto (zł)</TableCell>
                    <TableCell align="center">Brutto (zł)</TableCell>
                    <TableCell align="center">Ilość</TableCell>
                  </Fragment>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              Object.entries(data).map(([key, row]) => {
                const weekBalance = calculateDifference(
                  row.prevWeek.totalPriceNet,
                  row.currentWeek.totalPriceNet,
                );
                const monthBalance = calculateDifference(
                  row.prevMonth.totalPriceNet,
                  row.currentMonth.totalPriceNet,
                );

                return (
                  <TableRow key={key}>
                    <TableCell>{rowTitle[key]}</TableCell>
                    <TableCell align="center">
                      {row.today.totalPriceNet}
                    </TableCell>
                    <TableCell align="center">
                      {row.today.totalPriceGross}
                    </TableCell>
                    <TableCell align="center">
                      {row.today.repairsCount}
                    </TableCell>
                    <TableCell align="center">
                      {row.prevWeek.totalPriceNet}
                    </TableCell>
                    <TableCell align="center">
                      {row.prevWeek.totalPriceGross}
                    </TableCell>
                    <TableCell align="center">
                      {row.prevWeek.repairsCount}
                    </TableCell>
                    <TableCell align="center">
                      {row.currentWeek.totalPriceNet}
                    </TableCell>
                    <TableCell align="center">
                      {row.currentWeek.totalPriceGross}
                    </TableCell>
                    <TableCell align="center">
                      {row.currentWeek.repairsCount}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={getDifferenceCellColor(weekBalance)}
                    >
                      {parseDifference(weekBalance)}
                    </TableCell>
                    <TableCell align="center">
                      {row.prevMonth.totalPriceNet}
                    </TableCell>
                    <TableCell align="center">
                      {row.prevMonth.totalPriceGross}
                    </TableCell>
                    <TableCell align="center">
                      {row.prevMonth.repairsCount}
                    </TableCell>
                    <TableCell align="center">
                      {row.currentMonth.totalPriceNet}
                    </TableCell>
                    <TableCell align="center">
                      {row.currentMonth.totalPriceGross}
                    </TableCell>
                    <TableCell align="center">
                      {row.currentMonth.repairsCount}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={getDifferenceCellColor(monthBalance)}
                    >
                      {parseDifference(monthBalance)}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </RepairsBalanceWrapper>
  );
};

export default wire(['repairService'], RepairsBalance);
