export default {
  repair: {
    stepper: {
      button: {
        previous: 'wstecz',
        next: 'dalej',
      },
      step: {
        'location-or-location-express-or-courier':
          'Wybierz punkt gdzie pozostawisz sprzęt lub zamów kuriera',
        courier: 'Wypełnij dane dotyczące odbioru sprzętu przez kuriera',
        'location-express': 'Wybierz godzinę naprawy ekspresowej',
        // on-site translations
        'on-site-client': 'Znajdź klienta w bazie lub utwórz nowego',
        'on-site-device': 'Wyszukaj urządzenie lub utwórz nowe',
        'on-site-services': 'Wybierz usługi do naprawy',
        'on-site-acceptance-info': 'Wprowadź dane do naprawy',
        'on-site-notes': 'Wprowadź dodatkowe notatki',
        // express translations
        'express-client': 'Znajdź klienta w bazie lub utwórz nowego',
        'express-device': 'Wyszukaj urządzenie lub utwórz nowe',
        'express-services': 'Wybierz usługi do naprawy',
        'express-acceptance-info': 'Wprowadź dane do naprawy',
        'express-notes': 'Wprowadź dodatkowe notatki',
        // courier translations
        'courier-client': 'Znajdź klienta w bazie lub utwórz nowego',
        'courier-device': 'Wyszukaj urządzenie lub utwórz nowe',
        'courier-services': 'Wybierz usługi do naprawy',
        'courier-acceptance-info': 'Wprowadź dane do naprawy',
        'courier-notes': 'Wprowadź dodatkowe notatki',
      },
    },
  },
} as any;
