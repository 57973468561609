import { UserProfile, UserProfileDTO } from "./UserProfile";

export interface PartnerProfileDTO extends UserProfileDTO {
  externalId: string;
}

export class PartnerProfile extends UserProfile {
  private _externalId: string;

  constructor(profile: PartnerProfileDTO) {
    super(profile);

    this._externalId = profile.externalId;
  }

  public get externalId() {
    return this._externalId;
  }
}
