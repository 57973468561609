import { ApiService } from "../Api.service";
import { Partner, PartnerDTO } from "../../models/partner/Partner";
import { EditUserDTO } from "../../models/user/User";

export interface EditPartnerDTO extends EditUserDTO {
  externalId: string;
}

export class PartnerService {
  private _partners?: Partner[];

  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async register(data: any): Promise<void> {
    return await this._apiService.post("/auth/register/partner", data);
  }

  public async remove(id: string) {
    return await this._apiService.delete(`/partner/${id}`);
  }

  public async update(values: EditPartnerDTO): Promise<void> {
    return await this._apiService.put(`/partner/${values.id}`, values);
  }

  public async list(): Promise<Partner[]> {
    this._partners = await this._apiService
      .get<PartnerDTO[]>("/partner/list")
      .then(list => list.map((partner: PartnerDTO) => new Partner(partner)));

    return this._partners as Partner[];
  }

  public async listFetched(): Promise<Partner[]> {
    if (!this._partners) {
      this._partners = await this.list();
    }

    return this._partners;
  }
}
