import { pickBy } from "lodash";

import { ApiService } from "../Api.service";
import {
  CreateCustomerDTO,
  Customer,
  CustomerDTO
} from "../../models/customer/Customer";
import { EditBusinessCustomerDTO } from "../../models/customer/BusinessCustomer";

export interface BusinessCustomerServiceInterface {
  register(values: CreateCustomerDTO): Promise<void>;
  list(): Promise<Customer[]>;
}

export class BusinessCustomerService
  implements BusinessCustomerServiceInterface {
  // eslint-disable-next-line
  constructor(private _apiService: ApiService) {}

  public async register(values: CreateCustomerDTO): Promise<void> {
    return await this._apiService.post("/business-customer/register", values);
  }

  public async update(values: EditBusinessCustomerDTO): Promise<void> {
    return await this._apiService.put(`/business-customer/${values.id}`, {
      emailAddress: values.email,
      firstname: values.firstname,
      surname: values.surname,
      phone: values.phone,
      notes: values.notes,
      password: values.password ? values.password : null,
      confirmPassword: values.confirmPassword ? values.confirmPassword : null,
      userPassword: values.userPassword ? values.userPassword : null,
      locationId: values.locationId ? values.locationId : null
    });
  }

  public async list(query?: { id: string }): Promise<Customer[]> {
    return await this._apiService
      .get<CustomerDTO[]>("/business-customer/list", pickBy(query, key => key))
      .then(list =>
        list.map((customer: CustomerDTO) => new Customer(customer))
      );
  }
}
