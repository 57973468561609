import io from "socket.io-client";

import {
  RealtimeCommunicationAdapterInterface,
  RealtimeCommunicationEventInterface
} from "../RealtimeCommunication.service";

export interface WebsocketAdapterConfig {
  serverAddress: string;
}

export class WebsocketAdapter implements RealtimeCommunicationAdapterInterface {
  private _socket: SocketIOClient.Socket;

  public constructor(config: WebsocketAdapterConfig) {
    const address = config.serverAddress.endsWith("/")
      ? config.serverAddress
      : `${config.serverAddress}/`;

    this._socket = io(address, {
      transports: ["websocket"]
    });
  }

  public send(event: RealtimeCommunicationEventInterface): void {
    this._socket.emit(event.name, event.data);
  }

  public authenticate(accessToken: string | null): void {
    this.send({
      name: "authenticationRequest",
      data: accessToken
    });
  }

  public subscribe(
    eventName: string,
    cb: (event: RealtimeCommunicationEventInterface) => void
  ) {
    const func = (data: unknown) =>
      cb({
        name: eventName,
        data
      });

    this._socket.on(eventName, func);

    return () => {
      this._socket.off(eventName, func);
    };
  }
}
