import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik-latest';
import * as Yup from 'yup';
import { wire } from 'react-hot-wire';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useSheetsQueue } from '@rootsher/use-sheets-queue';

import { TextField } from '../../../form/field/text-field/input';
import { RepairService } from '../../../../../services/repair/Repair.service';

export type ClientValues = {
  forename?: string;
  surname?: string;
  phone?: string;
  email?: string;
  notes?: string;
};

const ClientCreateSchema = Yup.object().shape(
  {
    forename: Yup.string().required('Required'),
    surname: Yup.string().required('Required'),
    phone: Yup.string().when('email', {
      is: email => !email,
      then: Yup.string().required(),
    }),
    email: Yup.string()
      .email()
      .when('phone', {
        is: phone => !phone,
        then: Yup.string().required(),
      }),
  },
  [['email', 'phone']],
);

export const ClientCreate = ({
  repairService,
  options,
}: {
  repairService: RepairService;
  options: any;
}) => {
  const [, pop] = useSheetsQueue();

  function onSubmit(
    values: ClientValues,
    formikHelpers: FormikHelpers<ClientValues>,
  ) {
    return repairService
      .createClient(values)
      .then(values => {
        options.onSubmit(values);
        pop();
      })
      .catch(() => formikHelpers.setStatus('error'));
  }

  return (
    <Formik
      initialValues={{}}
      validateOnMount={true}
      validationSchema={ClientCreateSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, status }) => (
        <Form>
          <div className="p-4">
            <Typography variant="h5" align="center" className="my-3">
              Tworzenie klienta
            </Typography>
            <TextField
              name="forename"
              label="Imię *"
              placeholder="Wpisz imię..."
            />
            <TextField
              name="surname"
              label="Nazwisko *"
              placeholder="Wpisz nazwisko..."
            />
            <TextField
              name="phone"
              label="Telefon *"
              placeholder="Wpisz telefon..."
            />
            <Typography align="center" className="mt-3">
              i / lub
            </Typography>
            <TextField
              name="email"
              label="E-mail *"
              placeholder="Wpisz e-mail..."
            />
            <TextField
              name="notes"
              label="Notatki"
              placeholder="Wpisz notatki..."
              multiline
              rows={4}
            />
            <div className="mt-4">
              <Button color="primary" className="mr-3" onClick={pop}>
                anuluj
              </Button>
              <Button
                type="submit"
                disabled={!isValid}
                color="primary"
                variant="contained"
              >
                utwórz klienta
              </Button>
            </div>
            {status === 'error' && (
              <Typography className="mt-3">
                Wystąpił błąd podczas tworzenia klienta.
              </Typography>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default wire(['repairService'], ClientCreate);
