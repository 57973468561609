import CreateDevice from '../../device/create/Create';
import CreateDeviceBrand from '../../device/brand/create/Create';
import CreateBrandModel from '../../device/brand/model/create/Create';
import CreateDeviceType from '../../device/type/create/Create';
import EditDeviceType from '../../device/type/edit/Edit';
import CreateService from '../../service/create/Create';
import EditService from '../../service/edit/Edit';
import EditDeviceBrand from '../../device/brand/edit/Edit';
import EditBrandModel from '../..//device/brand/model/edit/Edit';
import EditSettings from '../../application/settings/general/edit-settings';
import ActionEventDetails from '../../application/actionEvent/details/Details';
import EditUser from '../../user/edit/Edit';
import EditUserPassword from '../../user/edit/EditPassword';
import CreateUser from '../../user/create/Create';
import CreateLocation from '../../location/create/Create';
import EditLocation from '../../location/edit/Edit';
import EditCourierSettings from '../../application/settings/courier/edit-courier-settings';

//TODO: types for constructor arguments
export const drawers: {
  [key: string]: new (props: any, context: any) => any;
} = {
  //TODO: activate via component class reference instead of new custom action name
  'edit-user': EditUser,
  'edit-user-password': EditUserPassword,
  'create-user': CreateUser,
  'create-device': CreateDevice,
  'create-service': CreateService,
  'edit-service': EditService,
  'create-device-type': CreateDeviceType,
  'edit-device-type': EditDeviceType,
  'create-device-brand': CreateDeviceBrand,
  'edit-device-brand': EditDeviceBrand,
  'create-brand-model': CreateBrandModel,
  'edit-device-model': EditBrandModel,
  'create-location': CreateLocation,
  'edit-location': EditLocation,
  'edit-application-settings': EditSettings,
  'edit-courier-settings': EditCourierSettings,
  'application-action-event-details': ActionEventDetails,
};
