import {
  LocationFastRepairWorkHours,
  CreateLocationFastRepairWorkHoursDTO,
  LocationFastRepairWorkHoursDTO,
} from './fast-repair-work-hours/fast-repair-work-hours';

export interface LocationDTO {
  id: string;
  name: string;
  canFastRepair: boolean;
  canPickup: boolean;
  fastRepairWorkHours?: LocationFastRepairWorkHoursDTO[];
}

export interface CreateLocationDTO {
  name: string;
  canFastRepair: boolean;
  canPickup: boolean;
  fastRepairWorkHours?: CreateLocationFastRepairWorkHoursDTO[];
}

export interface UpdateLocationDTO extends CreateLocationDTO {
  id: string;
}

export class Location {
  private readonly _id: string;
  private _name: string;
  private _canFastRepair: boolean;
  private _canPickup: boolean;
  private _fastRepairWorkHours: LocationFastRepairWorkHours[];

  constructor(location: LocationDTO) {
    this._id = location.id;
    this._name = location.name;
    this._canFastRepair = Boolean(location.canFastRepair);
    this._canPickup = Boolean(location.canPickup);
    this._fastRepairWorkHours = (location.fastRepairWorkHours || []).map(
      workHour => new LocationFastRepairWorkHours(workHour),
    );
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get canFastRepair(): boolean {
    return this._canFastRepair;
  }

  public get canPickup(): boolean {
    return this._canPickup;
  }

  public get fastRepairWorkHours(): LocationFastRepairWorkHours[] {
    return this._fastRepairWorkHours;
  }

  public set name(name: string) {
    this._name = name;
  }

  public set canFastRepair(canFastRepair: boolean) {
    this._canFastRepair = canFastRepair;
  }

  public set canPickup(canPickup: boolean) {
    this._canPickup = canPickup;
  }
}
