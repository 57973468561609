import React, { Fragment, PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import SimpleForm, {
  SimpleFormProps,
} from '../../../common/form/simple-form/SimpleForm';

export class ActionEventDetails extends PureComponent<{
  simpleFormProps: SimpleFormProps;
}> {
  public render() {
    return (
      <SimpleForm
        title="Szczegóły"
        fields={[
          {
            type: 'text',
            name: 'date',
            label: 'Data',
            readOnly: true,
          },
          {
            type: 'text',
            name: 'controller',
            label: 'Controller',
            readOnly: true,
          },
          {
            type: 'text',
            name: 'action',
            label: 'Cction',
            readOnly: true,
          },
          {
            type: 'text',
            name: 'method',
            label: 'Method',
            readOnly: true,
          },
          {
            type: 'text',
            name: 'ip',
            label: 'Adres IP',
            readOnly: true,
          },
          {
            type: 'text',
            name: 'url',
            label: 'URL',
            readOnly: true,
          },
          {
            type: 'text',
            name: 'responseStatus',
            label: 'Response Status',
            readOnly: true,
          },
          {
            type: 'textarea',
            name: 'body',
            label: 'Body',
            readOnly: true,
          },
          {
            type: 'textarea',
            name: 'response',
            label: 'Reponse',
            readOnly: true,
          },
          {
            type: 'text',
            name: 'responseTime',
            label: 'Response Time (ms)',
            readOnly: true,
          },
          {
            type: 'text',
            name: 'user',
            label: 'User',
            readOnly: true,
          },
        ]}
        render={({
          date,
          controller,
          action,
          method,
          ip,
          url,
          responseStatus,
          responseTime,
          body,
          response,
          user,
        }: any) => (
          <Fragment>
            <div className="d-flex">
              <div className="flex-grow-1">
                {date({ containerClass: 'm-0' })}
              </div>
              <div className="flex-grow-1">
                {method({ containerClass: 'm-0' })}
              </div>
            </div>
            <div className="d-flex">
              <div className="flex-grow-1">
                {controller({ containerClass: 'm-0' })}
              </div>
              <div className="flex-grow-1">
                {action({ containerClass: 'm-0' })}
              </div>
            </div>
            <div>{ip()}</div>
            <div>{url()}</div>
            <div className="d-flex">
              <div className="flex-grow-1">
                {responseStatus({ containerClass: 'm-0' })}
              </div>
              <div className="flex-grow-1 ">
                {responseTime({ containerClass: 'm-0' })}
              </div>
            </div>
            <div>{user()}</div>
            <div>{body()}</div>
            <div>{response()}</div>
          </Fragment>
        )}
        {...this.props.simpleFormProps}
      />
    );
  }
}

export default wire(['deviceBrandService'], ActionEventDetails);
