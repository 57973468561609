import React, { Component } from "react";

import Wrapper from "./Wrapper.template";

class WrapperComponent extends Component {
  state = {
    isOpenSideNav: false
  };

  render() {
    return (
      <Wrapper
        isOpenSideNav={this.state.isOpenSideNav}
        toggleSideNav={event => this.toggleSideNav(event)}
      >
        {this.props.children}
      </Wrapper>
    );
  }

  toggleSideNav(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({ isOpenSideNav: !this.state.isOpenSideNav });
  }
}

export default WrapperComponent;
