import 'react-app-polyfill/ie11';
import 'date-fns';

import './assets/styles/index.scss';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { SheetsProvider } from '@rootsher/use-sheets-queue';
import { ThemeProvider } from '@material-ui/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

import React from 'react';
import ReactDOM from 'react-dom';
import HotWire from 'hot-wire';
import { Provider } from 'react-hot-wire';
import { QueryClient, QueryClientProvider } from 'react-query';

import services from './services';

import AuthLogin from './components/auth/login/Login';
import ResetPassword from './components/auth/resetPassword/ResetPassword';
import RequestResetPassword from './components/auth/resetPassword/RequestResetPassword';
import AuthLogout from './components/auth/logout/Logout';
import Wrapper from './components/common/wrapper/Wrapper';
import RepairList from './components/repair/list/List';
import DeviceTypeList from './components/device/type/list/List';
import BrandList from './components/device/brand/list/List';
import BrandModelList from './components/device/brand/model/list/List';
import ServiceList from './components/service/list/List';
import WarehouseItemsList from './components/warehouse/items/list/List';
import UserList from './components/user/list/list';
import Notification from './components/core/notification/Notification';
import Settings from './components/application/settings/general/settings';
import ActionEventList from './components/application/actionEvent/list/List';
import QueueStatusInfo from './components/queue/status/QueueStatusInfo';
import LocationList from './components/location/list/List';
import PrintRepairDetails from './components/repair/print/print-repair-details';
import PrintRepairWarranty from './components/repair/print/print-repair-warranty';
import CourierSettings from './components/application/settings/courier/courier-settings';
import Sales from './components/sales/sales';

// NTSN:
import NTSNCurrentRepairList from './components/ntsn/repair/list/current/List';
import NTSNHistoryRepairList from './components/ntsn/repair/list/history/List';

import * as serviceWorker from './serviceWorker';
import InitConfiguration from './components/common/init/Configuration';
import InitAuthorization from './components/common/init/Authorization';

import { theme } from './components/repair/theme';

const hotWire = new HotWire();
const queryClient = new QueryClient();

(async () => {
  const resolvedServices = await hotWire.wire({ services });

  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <QueryClientProvider client={queryClient}>
          <Provider services={resolvedServices}>
            <SheetsProvider>
              <InitConfiguration>
                <Router>
                  <Switch>
                    <Route exact path="/auth/login" component={AuthLogin} />
                    <Route
                      exact
                      path="/account/password/reset"
                      component={RequestResetPassword}
                    />
                    <Route
                      exact
                      path="/account/activate/:hash"
                      component={ResetPassword}
                    />
                    <Route
                      exact
                      path="/account/password/reset/:hash"
                      component={ResetPassword}
                    />
                    <InitAuthorization>
                      <Switch>
                        <Route
                          exact
                          path="/repair/print/details/:repairId"
                          component={PrintRepairDetails}
                        />
                        <Route
                          exact
                          path="/repair/print/warranty/:repairId"
                          component={PrintRepairWarranty}
                        />
                        <Route path="/">
                          <Wrapper>
                            <Notification />
                            <Switch>
                              <Redirect exact from="/" to="/repair/list" />
                              <Route exact path="/sales" component={Sales} />
                              <Route
                                exact
                                path="/service/list"
                                component={ServiceList}
                              />
                              <Route
                                exact
                                path="/device/type/list"
                                component={DeviceTypeList}
                              />
                              <Route
                                exact
                                path="/device/brand/list"
                                component={BrandList}
                              />
                              <Route
                                exact
                                path="/device/brand/model/list"
                                component={BrandModelList}
                              />
                              <Route
                                exact
                                path="/repair/list"
                                component={RepairList}
                              />
                              <Route
                                exact
                                path="/location/list"
                                component={LocationList}
                              />
                              <Route
                                exact
                                path="/ntsn/repair/list/current"
                                component={NTSNCurrentRepairList}
                              />
                              <Route
                                exact
                                path="/ntsn/repair/list/history"
                                component={NTSNHistoryRepairList}
                              />
                              <Route
                                exact
                                path="/user/list"
                                component={UserList}
                              />
                              <Route
                                exact
                                path="/warehouse/items/list"
                                component={WarehouseItemsList}
                              />
                              <Route
                                exact
                                path="/application/queue"
                                component={QueueStatusInfo}
                              />
                              <Route
                                exact
                                path="/application/action-event"
                                component={ActionEventList}
                              />
                              <Route
                                exact
                                path="/application/settings"
                                component={Settings}
                              />
                              <Route
                                exact
                                path="/application/settings/courier"
                                component={CourierSettings}
                              />
                              <Route
                                exact
                                path="/auth/logout"
                                component={AuthLogout}
                              />
                            </Switch>
                          </Wrapper>
                        </Route>
                      </Switch>
                    </InitAuthorization>
                  </Switch>
                </Router>
              </InitConfiguration>
            </SheetsProvider>
          </Provider>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
