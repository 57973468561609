export enum NotificationMessageType {
  SUCCESS = "success",
  ERROR = "danger",
  info = "info",
  DEFAULT = "default",
  WARNING = "warning"
}

export interface NotificationInterface {
  message: string;
  type?: NotificationMessageType;
  duration?: number;
}

export interface NotificationSubscriberInterface {
  (event: NotificationInterface): void;
}

export class NotificationService {
  private _subscribes: NotificationSubscriberInterface[] = [];

  public subscribe(subscriber: NotificationSubscriberInterface): void {
    this._subscribes.push(subscriber);
  }

  public notify(notification: NotificationInterface): void {
    this._subscribes.forEach(subscriber => subscriber(notification));
  }
}
