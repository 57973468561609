import React, { ReactElement } from "react";
import { Form, FormikProps, Field } from "formik";
import { isEmpty } from "lodash";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBSpinner,
  MDBInput
} from "mdbreact";

import { SimpleFormProps } from "./SimpleForm";

import { types } from "./field";
import { MDBModalProps } from "../../../../definitions/mdbreact";

export interface Props {
  toggle: Function;
  props: SimpleFormProps;
  formikProps: FormikProps<{ [key: string]: string }>;
  modalProps: MDBModalProps;
}

export default ({
  toggle,
  props: {
    title,
    fields = [],
    submitButton = "Wyslij",
    submittingButton,
    cancelButton = "Anuluj",
    render,
    resetFields
  },
  formikProps,
  formikProps: { isValid, isSubmitting, submitForm, values },
  modalProps
}: Props) => {
  const fieldsToRender: { [key: string]: Function } = {};
  const fieldsArray: ReactElement[] = [];

  fields.forEach(field => {
    const CustomField = types[field.type];

    if (field.requires && isEmpty(values[field.requires])) {
      return null;
    }

    const fieldJsx: ReactElement = (
      <CustomField key={field.name} field={field} formikProps={formikProps} />
    );

    fieldsArray.push(fieldJsx);
    fieldsToRender[field.name] = (props: object) => (
      <CustomField
        key={field.name}
        field={{ ...field, ...props }}
        formikProps={formikProps}
      />
    );
  });

  return (
    <Form>
      <MDBModal
        isOpen={true}
        toggle={toggle}
        position="top"
        size="md"
        fullHeight={false}
        {...modalProps}
      >
        <MDBModalHeader>{title}</MDBModalHeader>
        <MDBModalBody>
          {render ? render(fieldsToRender) : fieldsArray}
        </MDBModalBody>
        <MDBModalFooter
          between={resetFields}
          end={!resetFields}
          className="flex-column flex-sm-row"
        >
          {resetFields && (
            <Field
              name="recreate"
              render={({ field }: { field: any }) => (
                <MDBInput
                  {...field}
                  value={String(field.value)}
                  label="utworz kolejne"
                  labelClass="text-nowrap"
                  type="checkbox"
                  id="recreate"
                />
              )}
            />
          )}
          <div className="d-flex align-items-center">
            {isSubmitting ? (
              <MDBSpinner small crazy />
            ) : (
              <MDBBtn
                color="secondary"
                className="text-nowrap p-3"
                onClick={toggle}
              >
                {cancelButton}
              </MDBBtn>
            )}
            <MDBBtn
              onClick={submitForm}
              color="primary"
              className="p-3"
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? submittingButton : submitButton}
            </MDBBtn>
          </div>
        </MDBModalFooter>
      </MDBModal>
    </Form>
  );
};
