import React from "react";
import { MDBInput, MDBBtn } from "mdbreact";
import { Field, FieldProps as FormikFieldProps, FormikProps } from "formik";

import ErrorMessage from "../../common/form/ErrorMessage";
import ResetPasswordContainer, {
  textStyles,
  titleStyles
} from "./ResetPasswordContainer";

export default ({ isValid }: FormikProps<object>) => (
  <ResetPasswordContainer
    header={
      <div>
        <p style={titleStyles}>Resetuj hasło</p>
        <p style={textStyles}>
          Otrzymasz email z linkiem do ustawienia nowego hasła
        </p>
      </div>
    }
  >
    <Field
      name="email"
      render={({ field }: FormikFieldProps) => (
        <MDBInput
          {...field}
          label="adres e-mail"
          hint="wprowadz adres e-mail"
          outline
        >
          <ErrorMessage name="email" />
        </MDBInput>
      )}
    />
    <MDBBtn type="submit" color="primary" className="w-100" disabled={!isValid}>
      Wyślij
    </MDBBtn>
  </ResetPasswordContainer>
);
