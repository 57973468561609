export enum RepairStatus {
  NEW_REPAIR = 'NEW_REPAIR',

  COURIER_ON_THE_WAY = 'COURIER_ON_THE_WAY',

  WAITING_FOR_FAULT_DIAGNOSIS = 'WAITING_FOR_FAULT_DIAGNOSIS',
  FAULT_DIAGNOSIS = 'FAULT_DIAGNOSIS',
  FAULT_DIAGNOSIS_COMPLETE = 'FAULT_DIAGNOSIS_COMPLETE',

  WAITING_FOR_FAULT_REPAIR = 'WAITING_FOR_FAULT_REPAIR',
  FAULT_REPAIR_IN_PROGRESS = 'FAULT_REPAIR_IN_PROGRESS',
  FAULT_REPAIR_COMPLETE = 'FAULT_REPAIR_COMPLETE',

  DELIVERY_SCHEDULING = 'DELIVERY_SCHEDULING',
  RETURN_ON_THE_WAY = 'RETURN_ON_THE_WAY',
  WAITING_FOR_CUSTOMER_RECEIVE = 'WAITING_FOR_CUSTOMER_RECEIVE',

  REPAIR_COMPLETE = 'REPAIR_COMPLETE',
}

export const RepairStatusTranslation: { [key: string]: string } = {
  [RepairStatus.NEW_REPAIR]: 'Nowa naprawa',

  [RepairStatus.COURIER_ON_THE_WAY]: 'Urządzenie w drodze do serwisu',

  [RepairStatus.WAITING_FOR_FAULT_DIAGNOSIS]: 'Oczekiwanie na diagnozę usterki',
  [RepairStatus.FAULT_DIAGNOSIS]: 'Diagnoza usterki',
  [RepairStatus.FAULT_DIAGNOSIS_COMPLETE]: 'Diagnoza usterki zakończona',

  [RepairStatus.WAITING_FOR_FAULT_REPAIR]: 'Oczekiwanie na naprawę usterki',
  [RepairStatus.FAULT_REPAIR_IN_PROGRESS]: 'Naprawa usterki',
  [RepairStatus.FAULT_REPAIR_COMPLETE]: 'Naprawa usterki zakończona',

  [RepairStatus.DELIVERY_SCHEDULING]: 'Planowanie wysyłki urządzenia',
  [RepairStatus.RETURN_ON_THE_WAY]: 'W drodze do miejsca docelowego',
  [RepairStatus.WAITING_FOR_CUSTOMER_RECEIVE]:
    'Oczekiwanie na odbiór przez klienta',

  [RepairStatus.REPAIR_COMPLETE]: 'Naprawa zakończona',
};
