import React, { Fragment } from 'react';
import { wire } from 'react-hot-wire';
import { useQuery } from 'react-query';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { Box, RepairValues } from '../../create';
import { RepairService } from '../../../../../services/repair/Repair.service';

type DevicePreviewProps = {
  send: (stage: string) => void;
  reset: () => void;
  values: RepairValues;
  repairService: RepairService;
};

export const DevicePreview = ({
  send,
  reset,
  values,
  repairService,
}: DevicePreviewProps) => {
  const { isLoading, data: device } = useQuery(`device-${values.device}`, () =>
    repairService.device({ id: values.device }),
  );

  return (
    <div className="d-flex flex-column my-4">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          <Box>
            <Typography>
              Identyfikator <small>(S/N, IMEI itp.)</small>:{' '}
              <strong>{device?.identifier}</strong>
            </Typography>
            <Typography>
              Hasło do urządzenia:{' '}
              <strong
                title={
                  device?.unlockPassword ? device?.unlockPassword : 'Brak hasła'
                }
              >
                ********
              </strong>
            </Typography>
            <Typography>
              Typ urządzenia: <strong>{device?.model?.type?.name}</strong>
            </Typography>
            <Typography>
              Model:{' '}
              <strong>{`${device?.model?.brand.name} ${device?.model?.name}`}</strong>
            </Typography>
          </Box>
          <div className="mt-4">
            <Button
              color="primary"
              className="mr-3"
              onClick={() => send('PREVIOUS')}
            >
              wstecz
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="mr-3"
              onClick={reset}
            >
              zmień urządzenie
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => send('NEXT')}
            >
              dalej
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default wire(['repairService'], DevicePreview);
