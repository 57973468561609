import React, { CSSProperties, Fragment, PureComponent } from 'react';
import * as Yup from 'yup';
import { wire } from 'react-hot-wire';

import { DeviceBrandService } from '../../../../../services/device/brand/DeviceBrand.service';
import { DeviceTypeService } from '../../../../../services/device/DeviceType.service';
import { BrandModelService } from '../../../../../services/device/brand/model/BrandModel.service';
import { PartnerService } from '../../../../../services/partner/Partner.service';
import {
  NotificationMessageType,
  NotificationService,
} from '../../../../../services/application/Notification.service';

import SimpleForm, {
  SimpleFormProps,
} from '../../../../common/form/simple-form/SimpleForm';
import Spinner from '../../../../common/spinner/Spinner';

import { CreateBrandModelDTO } from '../../../../../models/device/brand/model/BrandModel';
import { Partner } from '../../../../../models/partner/Partner';

export interface CreateBrandModelState {
  loading: boolean;
  partners: Partner[];
}

export interface CreateBrandModelFormInterface {
  name: string;
  brandId: string;
  typeId: string;
  systemMargin: string;
  shopItemsIds: string;
  [key: string]: string;
}

const partnerMarginsStyle: CSSProperties = {
  maxHeight: '200px',
  overflowX: 'auto',
};

export class CreateBrandModel extends PureComponent<
  {
    deviceTypeService: DeviceTypeService;
    deviceBrandService: DeviceBrandService;
    brandModelService: BrandModelService;
    partnerService: PartnerService;
    notificationService: NotificationService;
    simpleFormProps: SimpleFormProps;
  },
  CreateBrandModelState
> {
  public state: CreateBrandModelState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      partners: [],
    };
  }

  public componentDidMount() {
    this.fetchPartners();
  }

  public render() {
    return (
      <SimpleForm
        title="Tworzenie modelu urządzenia"
        submitButton="Utwórz model"
        submittingButton="Trwa tworzenie modelu..."
        onSubmit={(values: CreateBrandModelFormInterface) =>
          this.props.brandModelService
            .create({
              name: values.name,
              brandId: values.brandId,
              typeId: values.typeId,
              shopItemsIds: values.shopItemsIds,
              systemMargin: Number(values.systemMargin),
              partnerMargins: this.state.partners
                .map(partner =>
                  values[`partner_${partner.id}`]
                    ? {
                        partnerId: partner.id,
                        margin: Number(values[`partner_${partner.id}`]),
                      }
                    : null,
                )
                .filter(_ => _),
            } as CreateBrandModelDTO)
            .then(() =>
              this.props.notificationService.notify({
                message: 'Model urządzenia utworzony pomyślnie',
                type: NotificationMessageType.SUCCESS,
              }),
            )
            .catch((error: any) => {
              console.error(error);
              this.props.notificationService.notify({
                message: 'Błąd przy tworzeniu modelu urządzenia',
                type: NotificationMessageType.ERROR,
              });
            })
        }
        resetFields={['name']}
        fields={[
          {
            type: 'select',
            name: 'typeId',
            placeholder: 'Wybierz typ urządzenia',
            fetchOptions: () =>
              this.props.deviceTypeService
                .list()
                .then(types =>
                  types.map(type => ({ value: type.id, content: type.name })),
                ),
            validator: Yup.string().required('required'),
          },
          {
            type: 'select',
            name: 'brandId',
            placeholder: 'Wybierz markę',
            fetchOptions: () =>
              this.props.deviceBrandService
                .list()
                .then(types =>
                  types.map(brand => ({
                    value: brand.id,
                    content: brand.name,
                  })),
                ),
            validator: Yup.string().required('required'),
          },
          {
            type: 'text',
            name: 'name',
            label: 'Nazwa modelu',
            placeholder: 'Wpisz nazwę modelu',
            validator: Yup.string()
              .max(1, 'too-short')
              .max(80, 'too-long')
              .required('required'),
          },
          {
            type: 'text',
            name: 'systemMargin',
            label: 'Marża systemowa (%)',
            placeholder: 'Wpisz marżę',
          },
          {
            type: 'text',
            name: 'shopItemsIds',
            label: 'Id przedmiotów w sklepie (Oddzielone przecinkiem)',
            placeholder:
              'Podaj id przedmiotów ze sklepu - kolejne podawaj po przecinku',
          },
          ...this.state.partners.map(partner => ({
            type: 'text',
            name: `partner_${partner.id}`,
            label: partner.email,
            placeholder: 'Wpisz marżę',
          })),
        ]}
        render={({
          name,
          brandId,
          typeId,
          systemMargin,
          shopItemsIds,
          ...rest
        }: any) => (
          <Fragment>
            {typeId()}
            {brandId()}
            {name()}
            {shopItemsIds()}
            {systemMargin()}
            <div>
              <b>Marże partnerów</b>
              <div style={partnerMarginsStyle}>
                {this.state.loading ? (
                  <div>
                    <Spinner small /> Wczytywanie listy partnerów...
                  </div>
                ) : (
                  this.state.partners.map(partner =>
                    rest[`partner_${partner.id}`](),
                  )
                )}
              </div>
            </div>
          </Fragment>
        )}
        {...this.props.simpleFormProps}
      />
    );
  }

  private async fetchPartners() {
    this.setState({
      partners: await this.props.partnerService.listFetched(),
      loading: false,
    });
  }
}

export default wire(
  [
    'deviceTypeService',
    'deviceBrandService',
    'brandModelService',
    'drawerService',
    'partnerService',
    'notificationService',
  ],
  CreateBrandModel,
);
