import React, { CSSProperties } from 'react';
import { MDBContainer, MDBCard, MDBCardBody, MDBInput, MDBBtn } from 'mdbreact';
import {
  Field,
  FieldProps as FormikFieldProps,
  Form,
  FormikProps,
} from 'formik';
import { Link } from 'react-router-dom';

import ErrorMessage from '../../common/form/ErrorMessage';
import { UE } from '../../common/info/ue/ue';

export const logoStyles: CSSProperties = {
  maxWidth: '217px',
  maxHeight: '56px',
  margin: '38px 79px',
};

export default ({ isValid }: FormikProps<object>) => (
  <MDBContainer>
    <div className="mx-auto" style={{ maxWidth: '440px' }}>
      <Form>
        <MDBCard className="my-3 my-md-5">
          <MDBCardBody className="mx-4 px-1">
            <div className="text-center">
              <div>
                <img src="/logo.svg" style={logoStyles} alt="Dillcom" />
              </div>
              <h3 className="dark-grey-text mt-2">
                <strong>Logowanie</strong>
              </h3>
            </div>
            <Field
              name="email"
              render={({ field }: FormikFieldProps) => (
                <MDBInput
                  {...field}
                  label="adres e-mail"
                  hint="wprowadz adres e-mail"
                  outline
                >
                  <ErrorMessage name="email" />
                </MDBInput>
              )}
            />
            <Field
              name="password"
              render={({ field }: FormikFieldProps) => (
                <MDBInput
                  {...field}
                  type="password"
                  label="haslo"
                  hint="wprowadz haslo"
                  outline
                >
                  <ErrorMessage name="password" />
                </MDBInput>
              )}
            />
            <MDBBtn
              type="submit"
              color="primary"
              className="w-100"
              disabled={!isValid}
            >
              zaloguj
            </MDBBtn>

            <p className="mt-5 text-center">
              <Link to="/account/password/reset">Resetuj hasło</Link>
            </p>
          </MDBCardBody>
        </MDBCard>
      </Form>
    </div>
    <UE />
  </MDBContainer>
);
