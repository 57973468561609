import React, { useEffect, Fragment } from 'react';
import { useFormikContext } from 'formik-latest';
import moment from 'moment';
import { useQuery } from 'react-query';
import { wire } from 'react-hot-wire';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { StepComponentType } from './index';
import { DatePicker } from '../../form/field/date-time/date/date';
import { TimePicker } from '../../form/field/date-time/time/time';
import { TextField } from '../../form/field/text-field/input';
import { ErrorBox, RepairValues } from '../create';
import { RepairService } from '../../../../services/repair/Repair.service';

function Hours({
  weekDay,
  data,
}: {
  weekDay: string;
  data: { [key: string]: { start: number | null; end: number | null } };
}) {
  const { setFieldValue } = useFormikContext<RepairValues>();
  const { start, end } = data[weekDay] || {};

  useEffect(() => {
    setFieldValue('courier.time', {
      start: start && moment(start, 'HH'),
      end: start && moment(start, 'HH').add(3, 'hours'),
    });
  }, [start, setFieldValue, weekDay]);

  return (
    <Fragment>
      <Typography className="mt-3">
        Podaj swoją dostępność godzinową (zakres min. 3h):
      </Typography>
      {!start || !end ? (
        <div className="my-3">
          <ErrorBox>
            <Typography>
              <small>Kurier w tym dniu jest niedostępny.</small>
            </Typography>
          </ErrorBox>
        </div>
      ) : (
        <Fragment>
          <div className="my-3">
            <TimePicker
              name="courier.time.start"
              minTime={moment(start, 'HH')}
              maxTime={moment(end, 'HH')}
              label="Od"
              placeholder="Od..."
            />
          </div>
          <TimePicker
            name="courier.time.end"
            minTime={moment(start, 'HH')}
            maxTime={moment(end, 'HH')}
            label="Do"
            placeholder="Do..."
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export const Courier: StepComponentType<{ repairService: RepairService }> = ({
  send,
  repairService,
}) => {
  const { values, errors, touched } = useFormikContext<RepairValues>();
  const { isLoading, data } = useQuery(`courier`, () =>
    repairService.courierHours(),
  );
  const weekDay = values.courier.date.format('dddd').toUpperCase();

  return (
    <div className="my-4">
      <Typography className="mb-3">
        Zarezerwuj odbiór urządzenia na dowolny kolejny dzień:
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          <DatePicker<RepairValues>
            name="courier.date"
            label="Wybierz datę"
            minDate={moment().add(1, 'day')}
            placeholder="Wybierz datę..."
          />
          <TextField<RepairValues>
            name="courier.address"
            label="Adres odbioru sprzętu"
            placeholder="Podaj adres skąd kurier ma zabrać sprzęt..."
            multiline
            rows={4}
          />
          {data && <Hours weekDay={weekDay} data={data} />}
          <div className="mt-4">
            <Button
              color="primary"
              className="mr-3"
              onClick={() => send('PREVIOUS')}
            >
              wstecz
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!!errors.courier || !touched.courier}
              onClick={() => send('NEXT')}
            >
              dalej
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default wire(['repairService'], Courier);
