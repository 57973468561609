import React from "react";
import { MDBPagination, MDBPageItem, MDBPageNav } from "mdbreact";

interface PaginationPageInterface {
  currentPage: number;
  pageCount: number;
  onPageChange: (page: number) => void;
}

function getPaginationRange(
  current: number,
  { min, total, length }: { min: number; total: number; length: number }
) {
  if (length > total) length = total;

  let start = current - Math.floor(length / 2);
  start = Math.max(start, min);
  start = Math.min(start, min + total - length);

  return Array.from({ length: length }, (el, i) => start + i);
}

const PaginationPage = ({
  currentPage = 1,
  pageCount,
  onPageChange
}: PaginationPageInterface) => {
  const paginationRage =
    pageCount === 0
      ? [currentPage]
      : getPaginationRange(currentPage, {
          min: 1,
          total: pageCount,
          length: 5
        });

  return (
    <MDBPagination>
      <MDBPageItem
        disabled={currentPage === 1}
        onClick={() => currentPage !== 1 && onPageChange(1)}
      >
        <MDBPageNav className="page-link">
          <span>First</span>
        </MDBPageNav>
      </MDBPageItem>

      <MDBPageItem
        disabled={currentPage === 1}
        onClick={() => currentPage !== 1 && onPageChange(currentPage - 1)}
      >
        <MDBPageNav className="page-link" aria-label="Previous">
          &laquo;
        </MDBPageNav>
      </MDBPageItem>

      {paginationRage.map(page => (
        <MDBPageItem key={page} active={page === currentPage}>
          <MDBPageNav
            onClick={() => page !== currentPage && onPageChange(page)}
          >
            {page}
          </MDBPageNav>
        </MDBPageItem>
      ))}

      <MDBPageItem
        disabled={pageCount > 0 && currentPage === pageCount}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <MDBPageNav aria-label="Next">&raquo;</MDBPageNav>
      </MDBPageItem>

      {pageCount > 0 && (
        <MDBPageItem
          disabled={currentPage === pageCount}
          onClick={() => currentPage !== pageCount && onPageChange(pageCount)}
        >
          <MDBPageNav className="page-link">
            <span>Last</span>
          </MDBPageNav>
        </MDBPageItem>
      )}
    </MDBPagination>
  );
};

export default PaginationPage;
