import React, { PureComponent, Fragment } from "react";
import { wire } from "react-hot-wire";

import { drawers } from ".";
import { DrawerService } from "../../../services/drawer/Drawer.service";

export interface Props {
  drawerService: DrawerService;
}

export class DrawerComponent extends PureComponent<Props> {
  private _removeListener: Function = () => {};

  componentDidMount() {
    this._removeListener = this.props.drawerService.addListener(() =>
      this.forceUpdate()
    );
  }

  render() {
    const { drawerService } = this.props;

    return (
      <Fragment>
        {drawerService.drawers.map(drawer => {
          const Drawer = drawers[drawer.name];
          const { simpleFormProps, ...rest } = drawer.props;
          const props = {
            ...rest,
            ...simpleFormProps,
            close: () => drawerService.close(drawer.name)
          };

          return <Drawer key={drawer.name} simpleFormProps={props} />;
        })}
      </Fragment>
    );
  }

  componentWillUnmount() {
    this._removeListener();
  }
}

export default wire(["drawerService"], DrawerComponent);
