import axios, { AxiosInstance, AxiosResponse } from 'axios';
import uuid from 'uuid/v4';
import { includes } from 'lodash';
import { AccessTokenService } from './application/AccessToken.service';
import { NavigationService } from './application/Navigation.service';
import { ErrorMessage } from '../errors/ErrorMessage';

const AllowedMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

type ApplicationConfig = {
  BASE_URL?: string;
};

export interface ApiServiceInterface {
  get(url: string, data?: object): Promise<object>;
  post(url: string, data?: object): Promise<object>;
  put(url: string, data?: object): Promise<object>;
  patch(url: string, data?: object): Promise<object>;
  delete(url: string, data?: object): Promise<object>;
}

export class ApiService implements ApiServiceInterface {
  private static DEFAULT_BASE_URL: string = '/api';

  private _applicationConfig: ApplicationConfig;
  private _accessTokenService: AccessTokenService;
  private _navigationService: NavigationService;
  private _fetch: AxiosInstance;

  constructor(
    applicationConfig: ApplicationConfig,
    accessTokenService: AccessTokenService,
    navigationService: NavigationService,
  ) {
    this._applicationConfig = applicationConfig;
    this._accessTokenService = accessTokenService;
    this._navigationService = navigationService;

    this._fetch = axios.create({
      baseURL: applicationConfig.BASE_URL || ApiService.DEFAULT_BASE_URL,
      timeout: 30000,
      headers: {
        'X-Window-Id': uuid(),
        Authorization: `Bearer ${accessTokenService.accessToken}`,
      },
    });
  }

  public async get<T>(url: string, data?: object): Promise<T> {
    return await this.sendRequest<T>(AllowedMethods.GET, url, data);
  }

  public async post<T>(url: string, data?: object): Promise<T> {
    return await this.sendRequest<T>(AllowedMethods.POST, url, data);
  }

  public async put<T>(url: string, data?: object): Promise<T> {
    return await this.sendRequest<T>(AllowedMethods.PUT, url, data);
  }

  public async patch<T>(url: string, data?: object): Promise<T> {
    return await this.sendRequest<T>(AllowedMethods.PATCH, url, data);
  }

  public async delete<T>(url: string, data?: object): Promise<T> {
    return await this.sendRequest<T>(AllowedMethods.DELETE, url, data);
  }

  private async sendRequest<T>(
    method: string,
    url: string,
    dataRequest: object = {},
  ): Promise<T> {
    const params: object = {};

    if (
      !includes(
        [
          AllowedMethods.POST,
          AllowedMethods.PUT,
          AllowedMethods.PATCH,
          AllowedMethods.DELETE,
        ],
        method,
      )
    ) {
      Object.assign(params, dataRequest);
    }

    return await this._fetch({ method, url, data: dataRequest, params })
      .then(({ data }: { data: T }) => Promise.resolve(data))
      .catch(({ response }: { response: AxiosResponse<ErrorMessage> }) =>
        this.handleReject(response.data),
      );
  }

  private handleReject(data: ErrorMessage) {
    if (data.statusCode === 401) {
      window.location.href = '/auth/login';
    }

    return Promise.reject(data);
  }
}
