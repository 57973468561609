import React, { FC } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useSheetsQueue } from '@rootsher/use-sheets-queue';
import { wire } from 'react-hot-wire';

import RepairsBalance from './repairs/repairs-balance';
import RepairsBalanceLifetime from './repairs/repairs-balance-lifetime';
import CountStatistics from './statistics/count-statistics';
import SalesIdeas from './idea/sales-ideas';

import { SalesIdeaService } from '../../services/sales/idea/sales-idea.service';
import { UserService } from '../../services/user/User.service';
import { UserRoles } from '../../models/user/User';

export interface SalesProps {
  userService: UserService;
  salesIdeaService: SalesIdeaService;
}

export const Sales: FC<SalesProps> = ({ userService, salesIdeaService }) => {
  const [push] = useSheetsQueue();

  const openIdeas = () => {
    push(
      SalesIdeas({
        salesIdeaService,
      }),
      {
        side: 'right',
        size: 50,
      },
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {userService.user.hasRole(UserRoles.admin) && (
          <Button color="primary" variant="contained" onClick={openIdeas}>
            Pomysły
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <RepairsBalance />
      </Grid>
      <Grid item xs={6}>
        <RepairsBalanceLifetime />
      </Grid>
      <Grid item xs={6}>
        <CountStatistics />
      </Grid>
    </Grid>
  );
};

export default wire(['userService', 'salesIdeaService'], Sales);
