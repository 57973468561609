import React, { Fragment, useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { FlightLand, FlightTakeoff } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import { Repair } from '../../../models/repair/Repair';
import { UserRoles } from '../../../models/user/User';

import { RepairService } from '../../../services/repair/Repair.service';
import { UserService } from '../../../services/user/User.service';
import { LocationService } from '../../../services/location/Location.service';
import { ServiceService } from '../../../services/service/Service.service';

import RepairDetailsTab from './repair-details-tab';
import RepairHistoryTab from './repair-history-tab';
import RepairServicesTab from './repair-services-tab';
import RepairNotesTab from './notes/repair-notes-tab';
import RepairPartsTab from './repair-parts-tab';

export interface RepairDetailsProps {
  userService: UserService;
  repairService: RepairService;
  locationService: LocationService;
  serviceService: ServiceService;
  repairId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export const RepairDetails = ({
  userService,
  repairService,
  locationService,
  serviceService,
  repairId,
}: RepairDetailsProps) => {
  const canEditServices: boolean =
    !!userService.user &&
    userService.user.hasOneOfRoles([UserRoles.admin, UserRoles.employee]);
  const [repair, setRepair] = useState<Repair | null>(null);
  const [needReload, setNeedReload] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = React.useState('details');
  const classes = useStyles();
  const shouldShowAdditionalTab = userService.user.hasOneOfRoles([
    UserRoles.admin,
    UserRoles.employee,
  ]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) =>
    setCurrentTab(newValue);

  const onPrintReception = () => {
    window.open(
      `/repair/print/details/${repairId}`,
      'Print',
      'left=200, top=200, width=950, height=500, toolbar=0, resizable=0',
    );
  };
  const onPrintWarranty = () => {
    window.open(
      `/repair/print/warranty/${repairId}`,
      'Print',
      'left=200, top=200, width=950, height=500, toolbar=0, resizable=0',
    );
  };

  useEffect(() => {
    if (needReload) {
      setNeedReload(false);

      return;
    }

    setRepair(null);

    repairService.getOne(repairId).then(setRepair);
  }, [repairService, repairId, needReload]);

  if (!repair) {
    return <CircularProgress />;
  }

  const missingParts = repair.services?.some((service) =>
    service.warehouseItems?.some((item) => !item.quantity),
  );

  return (
    <Fragment>
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Naprawa (Numer: {repair.number})
          </Typography>
          <Button color="inherit" onClick={onPrintReception}>
            <FlightLand />
          </Button>
          <Button color="inherit" onClick={onPrintWarranty}>
            <FlightTakeoff />
          </Button>
        </Toolbar>
      </AppBar>
      <AppBar position="static" color="default">
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Szczegóły" value="details" />
          <Tab label="Usługi" value="services" />
          {shouldShowAdditionalTab && (
            <Tab
              label="Części"
              value="parts"
              icon={missingParts ? <WarningIcon color="error" /> : undefined}
            />
          )}
          {shouldShowAdditionalTab && <Tab label="Notatki" value="notes" />}
          <Tab label="Historia" value="history" />
        </Tabs>
      </AppBar>

      <Paper square>
        <TabPanel value={currentTab} index="details">
          <RepairDetailsTab
            repair={repair}
            repairService={repairService}
            userService={userService}
            locationService={locationService}
            onReload={() => setNeedReload(true)}
          />
        </TabPanel>
        <TabPanel value={currentTab} index="services">
          <RepairServicesTab
            repairService={repairService}
            serviceService={serviceService}
            repair={repair}
            canEdit={canEditServices}
          />
        </TabPanel>
        <TabPanel value={currentTab} index="parts">
          <RepairPartsTab repairService={repairService} repair={repair} />
        </TabPanel>
        <TabPanel value={currentTab} index="notes">
          <RepairNotesTab
            repairService={repairService}
            userService={userService}
            repair={repair}
          />
        </TabPanel>
        <TabPanel value={currentTab} index="history">
          <RepairHistoryTab
            repairService={repairService}
            repairId={repair.id}
          />
        </TabPanel>
      </Paper>
    </Fragment>
  );
};

export default (props: RepairDetailsProps) => () => {
  return <RepairDetails {...props} />;
};
