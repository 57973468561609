import { WebsocketAdapter } from "./Websocket.adapter";

export default {
  websocketAdapter: {
    class: WebsocketAdapter,
    arguments: [
      {
        serverAddress: process.env.REACT_APP_WEBSOCKET_API_URL
      }
    ]
  }
};
