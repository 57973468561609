export interface CustomerProfileDTO {
  id: string;
  firstname: string;
  surname: string;
  phone: string;
  notes: string;
}

export class CustomerProfile {
  private _id: string;
  private _firstname: string;
  private _surname: string;
  private _phone: string;
  private _notes: string;

  constructor(profile: CustomerProfileDTO) {
    this._id = profile.id;
    this._firstname = profile.firstname;
    this._surname = profile.surname;
    this._phone = profile.phone;
    this._notes = profile.notes;
  }

  public get id(): string {
    return this._id;
  }

  public get firstname(): string {
    return this._firstname;
  }

  public get surname(): string {
    return this._surname;
  }

  public get fullName(): string {
    return `${this._firstname} ${this._surname}`;
  }

  public get phone(): string {
    return this._phone;
  }

  public get notes(): string {
    return this._notes;
  }

  public set id(id) {
    this._id = id;
  }

  public set firstname(firstname) {
    this._firstname = firstname;
  }

  public set surname(surname) {
    this._surname = surname;
  }

  public set phone(phone) {
    this._phone = phone;
  }

  public set notes(notes) {
    this._notes = notes;
  }
}
