import React from "react";
import { MDBSpinner } from "mdbreact";

export interface SpinnerInterface {
  small?: boolean;
}

const Spinner = ({ ...props }: SpinnerInterface) => <MDBSpinner {...props} />;

export default Spinner;
