import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';

import { HistoryChangeValueProps } from '../history-change';
import { RepairStatusTranslation } from '../../../../../models/repair/RepairStatus';

export const HistoryStatusChange = ({
  event: { prevValue, newValue },
}: HistoryChangeValueProps) => {
  return (
    <Fragment>
      <div>
        <Typography gutterBottom variant="h6" component="h2">
          Nowa wartość
        </Typography>
        <pre>
          {RepairStatusTranslation[newValue]} ({newValue})
        </pre>
      </div>
      <div>
        <Typography gutterBottom variant="h6" component="h2">
          Poprzednia wartość
        </Typography>
        <pre>
          {RepairStatusTranslation[prevValue]} ({prevValue})
        </pre>
      </div>
    </Fragment>
  );
};

export default HistoryStatusChange;
