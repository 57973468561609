import { Drawer, DrawerProps } from "../../models/drawer/Drawer";

export class DrawerService {
  private _drawers: Array<Drawer> = [];
  private _listeners: Array<Function> = [];

  public addListener(listener: Function) {
    this._listeners.push(listener);

    return () => {
      this._listeners = this._listeners.filter(
        _listener => _listener !== listener
      );
    };
  }

  public get drawers() {
    return this._drawers;
  }

  public activate(name: string, props: DrawerProps) {
    this.drawers.push(new Drawer(name, props));

    this.runListeners();
  }

  public close(name: string) {
    const drawer = this.findDrawer(name);

    if (!drawer) {
      throw new Error(`Not found "${name}" drawer`);
    }

    drawer.close();
    this.runListeners();

    // wait for isOpen=false hide modal animation, and remove drawer
    setTimeout(() => {
      this._drawers = this._drawers.filter(drawer => drawer.name !== name);

      this.runListeners();
    }, 300);
  }

  private runListeners() {
    this._listeners.forEach(listener => listener());
  }

  private findDrawer(name: string) {
    return this._drawers.find(drawer => drawer.name === name);
  }
}
