import { WarehouseService } from "./Warehouse.service";
import { WarehouseItemService } from "./WarehouseItem.service";

export default {
  warehouseService: {
    class: WarehouseService,
    public: true,
    arguments: [{ $ref: "apiService" }]
  },
  warehouseItemService: {
    class: WarehouseItemService,
    public: true,
    arguments: [{ $ref: "apiService" }]
  }
};
