import React, { Fragment } from 'react';
import { useFormikContext } from 'formik-latest';
import { wire } from 'react-hot-wire';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { StepComponentType } from '../index';
import { ErrorBox, RepairValues } from '../../create';
import { RepairService } from '../../../../../services/repair/Repair.service';

import { Autocomplete } from '../../../form/field/autocomplete/autocomplete';

export const Services: StepComponentType<{ repairService: RepairService }> = ({
  send,
  repairService,
}) => {
  const { values } = useFormikContext<RepairValues>();

  return (
    <div className="my-4">
      {values.device ? (
        <Fragment>
          <Autocomplete
            name="services"
            label="Wybierz usługi..."
            fetch={async () => {
              const device = await repairService.device({ id: values.device });

              return repairService.services({ model: device.model.id });
            }}
            multiple={true}
          />
        </Fragment>
      ) : (
        <ErrorBox>
          <Typography>
            <small>
              Zanim wybierzesz usługi -{' '}
              <strong onClick={() => send('PREVIOUS')}>
                wybierz urządzenie
              </strong>
              .
            </small>
          </Typography>
        </ErrorBox>
      )}
      <div className="mt-4">
        <Button
          color="primary"
          className="mr-3"
          onClick={() => send('PREVIOUS')}
        >
          wstecz
        </Button>
        {values.services && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => send('NEXT')}
          >
            dalej
          </Button>
        )}
      </div>
    </div>
  );
};

export default wire(['repairService'], Services);
