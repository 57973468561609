export interface ConfigDTO {
  name?: string;
  logo?: string;
  systemMargin?: number;
  enabledModules: string[];
  serviceMargin?: boolean;
  enableMobileApp?: boolean;
  enableMobileAppRegister?: boolean;
  enableMobileAppLogin?: boolean;
  enableMobileAppPassReset?: boolean;
  enableNewRepair?: boolean;
  enableRepairCourier?: boolean;
}

export interface UpdateConfigDTO {
  name: string;
  logo?: string;
  systemMargin?: number;
  enabledModules?: string[];
  serviceMargin: boolean;
  enableMobileApp?: boolean;
  enableMobileAppRegister?: boolean;
  enableMobileAppLogin?: boolean;
  enableMobileAppPassReset?: boolean;
  enableNewRepair?: boolean;
  enableRepairCourier?: boolean;
}

export interface ConfigInterface {
  readonly name?: string;
  readonly logo?: string;
  readonly enabledModules?: string[];
}

export class Config implements ConfigInterface {
  private _name?: string;
  private _logo?: string;
  private _systemMargin?: number;
  private _enabledModules: string[] = [];
  private _serviceMargin: boolean;
  private _enableMobileApp: boolean;
  private _enableMobileAppRegister: boolean;
  private _enableMobileAppLogin: boolean;
  private _enableMobileAppPassReset: boolean;
  private _enableNewRepair: boolean;
  private _enableRepairCourier: boolean;

  constructor(config: ConfigDTO = { enabledModules: [] }) {
    this._name = config.name;
    this._logo = config.logo;
    this._systemMargin = config.systemMargin;
    this._serviceMargin = config.serviceMargin || false;
    this._enableMobileApp = config.enableMobileApp || false;
    this._enableMobileAppRegister = config.enableMobileAppRegister || false;
    this._enableMobileAppLogin = config.enableMobileAppLogin || false;
    this._enableMobileAppPassReset = config.enableMobileAppPassReset || false;
    this._enableNewRepair = config.enableNewRepair || false;
    this._enableRepairCourier = config.enableRepairCourier || false;
    this._enabledModules = config.enabledModules || [
      'sales',
      'device',
      'device-type',
      'device-brand',
      'device-model',
      'service',
      'service-list',
      'location',
      'location-list',
      'ntsn',
      'ntsn-current-list',
      'ntsn-history-list',
      'user',
      'user-list',
      'partner',
      'partner-list',
      'customer',
      'customer-list',
      'application',
      'application-action-event',
      'application-queue',
      'application-settings',
      'warehouse',
      'warehouse-items',
      'repairs',
      'repairs-list',
    ];
  }

  public get name(): string | undefined {
    return this._name;
  }

  public get logo(): string | undefined {
    return this._logo;
  }

  public get systemMargin(): number | undefined {
    return this._systemMargin;
  }

  public get enabledModules(): string[] {
    return this._enabledModules;
  }

  public get isServiceMarginEnabled(): boolean {
    return this._serviceMargin;
  }

  public get isMobileAppEnabled(): boolean {
    return this._enableMobileApp;
  }

  public get isMobileAppRegisterEnabled(): boolean {
    return this._enableMobileAppRegister;
  }

  public get isMobileAppLoginEnabled(): boolean {
    return this._enableMobileAppLogin;
  }

  public get isMobileAppPasswordResetEnabled(): boolean {
    return this._enableMobileAppPassReset;
  }

  public get isRepairCourierEnabled(): boolean {
    return this._enableRepairCourier;
  }

  public get isNewRepairEnabled(): boolean {
    return this._enableNewRepair;
  }

  public isModuleVisible(moduleName: string) {
    return this._enabledModules.includes(moduleName);
  }
}
