import React, { CSSProperties, PureComponent } from "react";
import { wire } from "react-hot-wire";

import { DeviceTypeService } from "../../../../services/device/DeviceType.service";
import { DrawerService } from "../../../../services/drawer/Drawer.service";
import { UserService } from "../../../../services/user/User.service";

import SimpleList, {
  SimpleListProps,
  SimpleListRow
} from "../../../common/list/simple-list/SimpleList";

import { DeviceType } from "../../../../models/device/DeviceType";
import { PartnerMarginDTO } from "../../../../models/partner/PartnerMargin";

const customCellStyles: CSSProperties = {
  display: "flex",
  justifyContent: "center"
};

const customColStyles: CSSProperties = {
  ...customCellStyles
};

export class DeviceTypeList extends PureComponent<{
  deviceTypeService: DeviceTypeService;
  drawerService: DrawerService;
  userService: UserService;
}> {
  public render() {
    const {
      userService: { user }
    } = this.props;

    const props: SimpleListProps = {
      title: "Lista typów",
      description: "Tutaj możesz zarzadząć typami urządeń",
      createButtonText: "dodaj typ",
      columns: [
        {
          label: "Typ",
          name: "name"
        },
        {
          label: (
            <span style={{ ...customColStyles }}>Marża systemowa (%)</span>
          ),
          name: "systemMargin"
        }
      ],
      fetchList: () =>
        this.props.deviceTypeService.list().then(list =>
          list.map(deviceType => ({
            id: deviceType.id,
            name: deviceType.name,
            partnerMargins: deviceType.partnerMargins,
            systemMargin: (
              <span
                data-value={deviceType.systemMargin}
                style={{ ...customCellStyles }}
              >
                {typeof deviceType.systemMargin === "number"
                  ? deviceType.systemMargin
                  : "-"}
              </span>
            )
          }))
        )
    };

    if (user!.isAdmin()) {
      props.editHandler = ({
        onEdit,
        row
      }: {
        onEdit: Function;
        row: SimpleListRow;
      }) => {
        this.props.drawerService.activate("edit-device-type", {
          simpleFormProps: {
            initialValues: {
              id: row.id,
              name: row.name,
              systemMargin: row.systemMargin.props["data-value"],
              ...(row.partnerMargins || []).reduce(
                (
                  obj: { [key: string]: number },
                  partnerMargin: PartnerMarginDTO
                ) => {
                  obj[`partner_${partnerMargin.id}`] = partnerMargin.margin;

                  return obj;
                },
                {}
              )
            },
            onResolved: onEdit
          }
        });
      };

      props.deleteHandler = ({
        onDelete,
        row
      }: {
        onDelete: Function;
        row: DeviceType;
      }) => this.props.deviceTypeService.remove(row.id).then(() => onDelete());

      props.createHandler = ({ onCreate }: { onCreate: Function }) =>
        this.props.drawerService.activate("create-device-type", {
          simpleFormProps: {
            onResolved: onCreate
          }
        });
    }

    return <SimpleList {...props} />;
  }
}

export default wire(
  ["deviceTypeService", "drawerService", "userService"],
  DeviceTypeList
);
