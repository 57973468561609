export interface GuestCustomerDTO {
  id: string;
  emailAddress: string;
  phone: string;
  firstname: string;
  surname: string;
}

export class GuestCustomer {
  private _id: string;
  private _email: string;
  private _phone: string;
  private _firstname: string;
  private _surname: string;

  constructor(user: GuestCustomerDTO) {
    this._id = user.id;
    this._email = user.emailAddress;
    this._phone = user.phone;
    this._firstname = user.firstname;
    this._surname = user.surname;
  }

  public get id() {
    return this._id;
  }

  public get email(): string {
    return this._email;
  }

  public get phone(): string {
    return this._phone;
  }

  public get firstname(): string {
    return this._firstname;
  }

  public get surname(): string {
    return this._surname;
  }

  public get fullName(): string {
    return `${this._firstname} ${this._surname}`;
  }
}
