import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Select,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ValueFormatterParams } from '@material-ui/data-grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import SimpleTwoColTable, {
  SimpleTwoColTableRow,
} from '../../common/table/simple-two-col/simple-two-col-table';

import {
  Repair,
  RepairCourierOrder,
  RepairExpress,
} from '../../../models/repair/Repair';
import { CustomerProfile } from '../../../models/customer/CustomerProfile';
import { BrandModel } from '../../../models/device/brand/model/BrandModel';
import { User, UserRoles } from '../../../models/user/User';
import {
  RepairStatus,
  RepairStatusTranslation,
} from '../../../models/repair/RepairStatus';
import { RepairService } from '../../../services/repair/Repair.service';
import { UserService } from '../../../services/user/User.service';
import { LocationService } from '../../../services/location/Location.service';
import { Location } from '../../../models/location/Location';
import { dateToDatetimeLocal } from '../../../helpers/time/dateToDatetimeLocal';
import {
  RepairOrigin,
  RepairOriginTranslation,
} from '../../../models/repair/repair-origin';
import { GuestCustomer } from '../../../models/user/guest-customer';

export interface RepairDetailsTabProps {
  userService: UserService;
  repairService: RepairService;
  locationService: LocationService;
  repair: Repair;
  onReload: () => void;
}

const getGeneralRows = ({
  user,
  handleDateChange,
  selectedCreatedAtDate,
  onSaveCreatedAtSave,
}: {
  user: User | null;
  selectedCreatedAtDate: Date;
  handleDateChange: (date: Date | null) => void;
  onSaveCreatedAtSave: () => void;
}): Array<SimpleTwoColTableRow<Repair>> => [
  {
    field: 'id',
    name: 'ID',
  },
  {
    field: 'number',
    name: 'Numer',
  },
  {
    field: 'createdAt',
    name: 'Data utworzenia',
    ...(user && user.hasRole(UserRoles.admin)
      ? {
          renderCell: (value: Date) => (
            <Box display="flex" justifyContent="flex-end">
              {value.toLocaleString() !==
                selectedCreatedAtDate.toLocaleString() && (
                <Box mr={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSaveCreatedAtSave}
                  >
                    Zapisz
                  </Button>
                </Box>
              )}
              <TextField
                type="datetime-local"
                defaultValue={dateToDatetimeLocal(value)}
                onChange={(
                  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                ) => {
                  handleDateChange(new Date(event.target.value));
                }}
              />
            </Box>
          ),
        }
      : {
          valueFormatter: (value: Date) => value.toLocaleString(),
        }),
  },
  {
    field: 'reporter',
    name: 'Utworzył',
    valueFormatter: (value: User | undefined) =>
      value ? value.profile.viewName : '-',
  },
  {
    field: 'origin',
    name: 'Źródło utworzenia',
    valueFormatter: (value: RepairOrigin) =>
      RepairOriginTranslation[value] || value,
  },
  {
    field: 'location',
    name: 'Punkt',
    valueFormatter: (value: Location | undefined) => (value ? value.name : '-'),
  },
  {
    field: 'contactEmail',
    name: 'Email kontaktowy',
  },
  {
    field: 'contactPhone',
    name: 'Telefon kontaktowy',
  },
  {
    field: 'courierOrder',
    name: 'Odbiór od klienta',
    renderCell: (value: RepairCourierOrder | undefined) =>
      value ? (
        <div>
          <p>
            Tak ({value.hourFrom.toLocaleDateString()} od{' '}
            {value.hourFrom.getHours()}:
            {value.hourFrom.getMinutes() > 9 ? '' : '0'}
            {value.hourFrom.getMinutes()} do {value.hourTo.getHours()}:
            {value.hourTo.getMinutes() > 9 ? '' : '0'}
            {value.hourTo.getMinutes()})
          </p>
          <p>Adres: {value.address}</p>
        </div>
      ) : (
        <div>Nie</div>
      ),
  },
  {
    field: 'express',
    name: 'Naprawa ekspresowa',
    renderCell: (value: RepairExpress | undefined) =>
      value ? (
        <div>
          <p>Tak ({value.date.toLocaleString()})</p>
          <p>Lokalizacja: {value.locationName}</p>
        </div>
      ) : (
        <div>Nie</div>
      ),
  },
];

const customerRows: Array<SimpleTwoColTableRow<Repair>> = [
  {
    key: 'firstname',
    field: 'customer',
    name: 'Imię',
    valueFormatter: (value: CustomerProfile) => value.firstname,
  },
  {
    key: 'surname',
    field: 'customer',
    name: 'Nazwisko',
    valueFormatter: (value: CustomerProfile) => value.surname,
  },
  {
    key: 'phone',
    field: 'customer',
    name: 'Numer telefonu',
    valueFormatter: (value: CustomerProfile) => value.phone,
  },
];

const guestCustomerRows: Array<SimpleTwoColTableRow<Repair>> = [
  {
    key: 'firstname',
    field: 'guestCustomer',
    name: 'Imię',
    valueFormatter: (value: GuestCustomer) => value.firstname,
  },
  {
    key: 'surname',
    field: 'guestCustomer',
    name: 'Nazwisko',
    valueFormatter: (value: GuestCustomer) => value.surname,
  },
];

const deviceRows: Array<SimpleTwoColTableRow<Repair>> = [
  {
    field: 'device.identifier',
    name: 'Identyfikator',
  },
  {
    field: 'device.model',
    name: 'Model',
    valueFormatter: (value: BrandModel) => value.name,
  },
  {
    key: 'brand',
    field: 'device.model',
    name: 'Marka',
    valueFormatter: (value: BrandModel) => value.brand.name,
  },
  {
    key: 'type',
    field: 'device.model',
    name: 'Typ urządzenia',
    valueFormatter: (value: BrandModel) => (value.type ? value.type.name : ''),
  },
  {
    field: 'device.unlockPassword',
    name: 'Hasło',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '40%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      textAlign: 'right',
      flexBasis: '60%',
      flexGrow: 0,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    primaryTextColor: {
      color: theme.palette.text.primary,
    },
  }),
);

export const RepairDetailsTab = ({
  repair,
  repairService,
  userService,
  onReload,
}: RepairDetailsTabProps) => {
  const canEditStatus: boolean =
    !!userService.user &&
    userService.user.hasOneOfRoles([
      UserRoles.admin,
      UserRoles.receptionist,
      UserRoles.business_customer,
    ]);
  const canEditTechnician: boolean =
    !!userService.user &&
    userService.user.hasOneOfRoles([UserRoles.admin, UserRoles.receptionist]);
  const canEditDeviceReceivedFromClientStatus: boolean =
    !!userService.user &&
    userService.user.hasOneOfRoles([
      UserRoles.admin,
      UserRoles.employee,
      UserRoles.partner,
    ]);
  const [status, setStatus] = useState(repair.status);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [technicianListLoading, setTechnicianListLoading] = useState(
    canEditTechnician,
  );
  const [technicians, setTechnicians] = useState<User[]>([]);
  const [selectedTechnician, setSelectedTechnician] = useState<User>();
  const [
    selectedCreatedAtDate,
    setSelectedCreatedAtDate,
  ] = React.useState<Date>(repair.createdAt);
  const [
    loadingDeviceReceivedFromClientStatus,
    setLoadingDeviceReceivedFromClientStatus,
  ] = useState(false);
  const [
    deviceReceivedFromClientStatus,
    setDeviceReceivedFromClientStatus,
  ] = useState(repair.isDeviceReceivedFromClient);
  const classes = useStyles();

  useEffect(() => {
    if (canEditTechnician) {
      setTechnicianListLoading(true);

      userService.getAllTechnicians().then((list) => {
        setTechnicians(list);

        if (repair.assignedTechnician) {
          setSelectedTechnician(
            list.find((user) => user.id === repair.assignedTechnician!.id),
          );
        }

        setTechnicianListLoading(false);
      });
    }
  }, [userService, canEditTechnician, repair.assignedTechnician]);

  const statusRows: Array<SimpleTwoColTableRow<Repair>> = useMemo<
    Array<SimpleTwoColTableRow<Repair>>
  >(() => {
    const handleStatusChange = async (
      event: React.ChangeEvent<{ name?: string; value: unknown }>,
    ) => {
      setLoadingStatus(true);

      if (
        await repairService.setStatus(
          repair.id,
          event.target.value as RepairStatus,
        )
      ) {
        setStatus(event.target.value as RepairStatus);
      }

      setLoadingStatus(false);
    };
    const handleTechnicianChange = async (
      event: any,
      newValue: User | null,
    ) => {
      setTechnicianListLoading(true);

      if (
        newValue &&
        (await repairService.setAssignedTechnician(repair.id, newValue.id))
      ) {
        setSelectedTechnician(newValue);
      }

      setTechnicianListLoading(false);
    };
    const changeDeviceReceivedFromClientStatus = (status: boolean) => {
      if (status === deviceReceivedFromClientStatus) {
        return;
      }

      setLoadingDeviceReceivedFromClientStatus(true);

      repairService
        .changeDeviceReceivedFromClientStatus(repair.id, status)
        .then(() => {
          setDeviceReceivedFromClientStatus(status);
          setLoadingDeviceReceivedFromClientStatus(false);
        })
        .catch((e) => {
          setLoadingDeviceReceivedFromClientStatus(false);
          console.error(e);
        });
    };

    return [
      {
        field: 'status',
        name: 'Status',
        ...(canEditStatus
          ? {
              renderCell: () =>
                loadingStatus ? (
                  <CircularProgress />
                ) : (
                  <FormControl variant="outlined">
                    <Select value={status} onChange={handleStatusChange}>
                      {Object.values(RepairStatus).map((value: string) => (
                        <option key={value} value={value}>
                          {RepairStatusTranslation[value]}
                          {[
                            RepairStatus.FAULT_DIAGNOSIS_COMPLETE,
                            RepairStatus.WAITING_FOR_CUSTOMER_RECEIVE,
                          ].includes(value as RepairStatus) && ' (SMS)'}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                ),
            }
          : {
              valueFormatter: (params: ValueFormatterParams) =>
                RepairStatusTranslation[params.getValue('status') as string],
            }),
      },
      {
        field: 'assignedTechnician',
        name: 'Technik',
        valueFormatter: (value: User) => (value ? value.profile.viewName : '-'),
        ...(canEditTechnician
          ? {
              renderCell: () =>
                technicianListLoading ? (
                  <CircularProgress />
                ) : (
                  <Autocomplete
                    id="combo-box-demo"
                    options={technicians}
                    getOptionLabel={(option: User) => option.profile.viewName}
                    value={selectedTechnician}
                    onChange={handleTechnicianChange}
                    autoHighlight
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Przypisz technika"
                        variant="outlined"
                      />
                    )}
                  />
                ),
            }
          : {}),
      },
      {
        field: 'isDeviceReceivedFromClient',
        name: 'Urządzenie odebrane od klienta',
        ...(canEditDeviceReceivedFromClientStatus
          ? {
              renderCell: () =>
                loadingDeviceReceivedFromClientStatus ? (
                  <CircularProgress />
                ) : (
                  <FormControlLabel
                    key="isDeviceReceivedFromClient"
                    control={
                      <Checkbox
                        color="primary"
                        name="isDeviceReceivedFromClient"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked: boolean,
                        ) => changeDeviceReceivedFromClientStatus(checked)}
                      />
                    }
                    label={''}
                    checked={deviceReceivedFromClientStatus}
                  />
                ),
            }
          : {
              valueFormatter: (params: ValueFormatterParams) =>
                params.value ? 'Odebrano' : 'Nie',
            }),
      },
    ];
  }, [
    loadingStatus,
    status,
    canEditStatus,
    technicianListLoading,
    technicians,
    canEditTechnician,
    selectedTechnician,
    canEditDeviceReceivedFromClientStatus,
    deviceReceivedFromClientStatus,
    loadingDeviceReceivedFromClientStatus,
    repair.id,
    repairService,
  ]);

  const generalRows = useMemo(
    () =>
      getGeneralRows({
        selectedCreatedAtDate,
        user: userService.user,
        handleDateChange: (date: Date | null) => {
          if (date instanceof Date) {
            setSelectedCreatedAtDate(date);
          }
        },
        onSaveCreatedAtSave: () => {
          setLoading(true);
          repairService
            .setCreationDate(repair.id, selectedCreatedAtDate)
            .then(() => {
              onReload();
              setLoading(false);
            });
        },
      }),
    [userService, selectedCreatedAtDate, repair.id, onReload, repairService],
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Fragment>
      <Box py={2}>
        <SimpleTwoColTable data={repair} rows={generalRows} />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Przesyłka</Typography>
            <Typography
              className={`${classes.secondaryHeading} ${classes.primaryTextColor}`}
            >
              {repair.returnAddress ? 'Tak' : 'Nie'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>Adres: {repair.returnAddress}</pre>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              Lista akcesoriów
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {(repair.accessoriesList || '').substr(0, 70)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{repair.accessoriesList}</pre>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Stan urządzenia</Typography>
            <Typography className={classes.secondaryHeading}>
              {(repair.deviceStatusDescription || '').substr(0, 70)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{repair.deviceStatusDescription}</pre>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Notatki</Typography>
            <Typography className={classes.secondaryHeading}>
              {(repair.description || '').substr(0, 70)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{repair.description}</pre>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Divider />
      <Box py={2}>
        <SimpleTwoColTable
          data={repair}
          rows={statusRows}
          columns={['Status']}
        />
      </Box>
      <Divider />
      <Box py={2}>
        {repair.customer && (
          <SimpleTwoColTable
            data={repair}
            rows={customerRows}
            columns={['Dane klienta']}
          />
        )}
        {repair.guestCustomer && (
          <SimpleTwoColTable
            data={repair}
            rows={guestCustomerRows}
            columns={['Dane klienta (gość)']}
          />
        )}
      </Box>
      <Divider />
      <Box py={2}>
        <SimpleTwoColTable
          data={repair}
          rows={deviceRows}
          columns={['Urządzenie']}
        />
      </Box>
    </Fragment>
  );
};

export default RepairDetailsTab;
