export interface PartnerProfileDTO {
  id: string;
  nickname: string;
  sex: string;
  externalId: string;
  notes: string;
}

export class PartnerProfile {
  private _id: string;
  private _nickname: string;
  private _sex: string;
  private _externalId: string;
  private _notes: string;

  constructor(profile: PartnerProfileDTO) {
    this._id = profile.id;
    this._nickname = profile.nickname || "---";
    this._sex = profile.sex;
    this._externalId = profile.externalId;
    this._notes = profile.notes || "---";
  }

  public get nickname() {
    return this._nickname;
  }

  public get externalId() {
    return this._externalId;
  }

  public get notes() {
    return this._notes;
  }
}
