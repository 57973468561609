import React, { Fragment, PureComponent } from 'react';
import * as Yup from 'yup';
import { wire } from 'react-hot-wire';

import {
  NotificationMessageType,
  NotificationService,
} from '../../../services/application/Notification.service';

import SimpleForm, {
  SimpleFormProps,
} from '../../common/form/simple-form/SimpleForm';

import { LocationService } from '../../../services/location/Location.service';
import { CreateLocationDTO } from '../../../models/location/Location';
import { Weekday } from '../../../models/common/Weekday';

export interface CreateLocationState {
  loading: boolean;
}

export interface CreateLocationFormInterface {
  name: string;
  canPickup: boolean;
  canFastRepair: boolean;
  [key: string]: string | boolean;
}

export class CreateLocation extends PureComponent<
  {
    locationService: LocationService;
    notificationService: NotificationService;
    simpleFormProps: SimpleFormProps;
  },
  CreateLocationState
> {
  public state: CreateLocationState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  public render() {
    return (
      <SimpleForm
        title="Tworzenie punktu"
        submitButton="Utwórz punkt"
        submittingButton="Trwa tworzenie punktu..."
        onSubmit={(values: CreateLocationFormInterface) =>
          this.props.locationService
            .create({
              name: values.name,
              canPickup: values.canPickup,
              canFastRepair: values.canFastRepair,
              fastRepairWorkHours: Object.values(Weekday)
                .map(weekday => ({
                  weekday: weekday,
                  start: Number(values[`${weekday}_start`]),
                  end: Number(values[`${weekday}_end`]),
                }))
                .filter(weekday => weekday.end > 0),
            } as CreateLocationDTO)
            .then(() =>
              this.props.notificationService.notify({
                message: 'Lokacja utworzona pomyślnie',
                type: NotificationMessageType.SUCCESS,
              }),
            )
            .catch((error: any) => {
              console.error(error);
              this.props.notificationService.notify({
                message: 'Błąd przy tworzeniu lokacji',
                type: NotificationMessageType.ERROR,
              });
            })
        }
        resetFields={['name']}
        fields={[
          {
            type: 'text',
            name: 'name',
            label: 'Nazwa punktu',
            placeholder: 'Wpisz nazwę punktu',
            validator: Yup.string()
              .max(1, 'too-short')
              .max(80, 'too-long')
              .required('required'),
          },
          {
            type: 'checkbox',
            name: 'canPickup',
            label: 'Możliwość zostawienia urządzenia w punkcie',
          },
          {
            type: 'checkbox',
            name: 'canFastRepair',
            label: 'Wykonuje szybkie naprawy',
          },
          ...this.getFastRepairWorkHoursFields(),
        ]}
        render={({ name, canPickup, canFastRepair, ...rest }: any) => (
          <Fragment>
            {name()}
            {canPickup()}
            {canFastRepair()}
            <hr />
            <div>
              <p className="font-weight-bold">Godziny szybkich napraw</p>
              <p>
                Gdy godzina zakończenia to 17, ostatnią godziną na którą można
                zamówić naprawe ekrepresową to 16
              </p>
              <div>
                <Fragment>
                  {[
                    { label: 'Poniedziałek', weekday: Weekday.MONDAY },
                    { label: 'Wtorek', weekday: Weekday.TUESDAY },
                    { label: 'Środa', weekday: Weekday.WEDNESDAY },
                    { label: 'Czwartek', weekday: Weekday.THURSDAY },
                    { label: 'Piątek', weekday: Weekday.FRIDAY },
                    { label: 'Sobota', weekday: Weekday.SATURDAY },
                    { label: 'Niedziela', weekday: Weekday.SUNDAY },
                  ].map(day => (
                    <div className="d-flex">
                      <div className="w-25 mr-3" style={{ marginTop: '34px' }}>
                        {day.label}:
                      </div>

                      <div className="w-25 mr-3">
                        {rest[`${day.weekday}_start`]({
                          containerClass: 'm-0 ',
                        })}
                      </div>
                      <div className="mr-3" style={{ marginTop: '34px' }}>
                        -
                      </div>
                      <div className="w-25">
                        {rest[`${day.weekday}_end`]({ containerClass: 'm-0' })}
                      </div>
                    </div>
                  ))}
                </Fragment>
              </div>
            </div>
          </Fragment>
        )}
        {...this.props.simpleFormProps}
      />
    );
  }

  private getFastRepairWorkHoursFields() {
    const optionsStart = Array.from({ length: 23 }).map((v, k) => ({
      value: k,
      content: `${k}`,
    }));
    const optionsEnd = Array.from({ length: 23 }).map((v, k) => ({
      value: k + 1,
      content: `${k + 1}`,
    }));

    return Object.values(Weekday)
      .map(weekday => [
        {
          type: 'select',
          label: 'Start',
          placeholder: 'Start',
          name: `${weekday}_start`,
          options: [{ value: undefined, content: '-' }, ...optionsStart],
        },
        {
          type: 'select',
          label: 'Koniec',
          placeholder: 'Koniec',
          name: `${weekday}_end`,
          options: [{ value: undefined, content: '-' }, ...optionsEnd],
        },
      ])
      .flat();
  }
}

export default wire(['locationService', 'notificationService'], CreateLocation);
