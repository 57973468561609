import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';

import { HistoryChangeValueProps } from '../history-change';

export const HistoryDefaultValue = ({
  event: { prevValue, newValue },
}: HistoryChangeValueProps) => {
  return (
    <Fragment>
      <div>
        <Typography gutterBottom variant="h6" component="h2">
          Nowa wartość
        </Typography>
        <pre>{newValue}</pre>
      </div>
      <div>
        <Typography gutterBottom variant="h6" component="h2">
          Poprzednia wartość
        </Typography>
        <pre>{prevValue}</pre>
      </div>
    </Fragment>
  );
};

export default HistoryDefaultValue;
