import React, { CSSProperties, Fragment, PureComponent } from 'react';
import * as Yup from 'yup';
import { wire } from 'react-hot-wire';

import { DeviceBrandService } from '../../../services/device/brand/DeviceBrand.service';
import { DeviceTypeService } from '../../../services/device/DeviceType.service';
import { BrandModelService } from '../../../services/device/brand/model/BrandModel.service';
import { ServiceService } from '../../../services/service/Service.service';
import {
  NotificationMessageType,
  NotificationService,
} from '../../../services/application/Notification.service';
import { PartnerService } from '../../../services/partner/Partner.service';

import SimpleForm, {
  SimpleFormProps,
} from '../../common/form/simple-form/SimpleForm';
import Spinner from '../../common/spinner/Spinner';

import { CreateServiceDTO } from '../../../models/service/Service';
import { Partner } from '../../../models/partner/Partner';
import { UserRoles } from '../../../models/user/User';
import { UserService } from '../../../services/user/User.service';
import { ApplicationService } from '../../../services/application/Application.service';

export interface CreateServiceState {
  loading: boolean;
  partners: Partner[];
}

const partnerMarginsStyle: CSSProperties = {
  maxHeight: '200px',
  overflowX: 'auto',
};

export interface CreateServiceFormInterface {
  name: string;
  margin: string;
  systemMargin: string;
  priceNet: string;
  tax: string;
  duration: string;
  modelId: string;
  [key: string]: string;
}

export class CreateService extends PureComponent<
  SimpleFormProps & {
    deviceTypeService: DeviceTypeService;
    deviceBrandService: DeviceBrandService;
    brandModelService: BrandModelService;
    serviceService: ServiceService;
    partnerService: PartnerService;
    simpleFormProps: SimpleFormProps;
    notificationService: NotificationService;
    userService: UserService;
    applicationService: ApplicationService;
  },
  CreateServiceState
> {
  public state: CreateServiceState;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      partners: [],
    };
  }

  public componentDidMount() {
    this.fetchPartners();
  }

  public render() {
    return (
      <SimpleForm
        title="Tworzenie usługi"
        submitButton="Utwórz usługę"
        submittingButton="Trwa tworzenie usługi..."
        onSubmit={async (values: CreateServiceFormInterface) =>
          this.props.serviceService
            .create({
              modelId: values.modelId,
              name: values.name,
              margin: this.shouldUseMargin() ? Number(values.margin) : null,
              systemMargin: Number(values.systemMargin),
              priceNet: Number(values.priceNet),
              tax: Number(values.tax),
              duration: Number(values.duration),
              partnerMargins: this.state.partners
                .map(partner =>
                  values[`partner_${partner.id}`]
                    ? {
                        partnerId: partner.id,
                        margin: Number(values[`partner_${partner.id}`]),
                      }
                    : null,
                )
                .filter(_ => _),
              warehouseItemsIds: values.warehouseItemsIds,
            } as CreateServiceDTO)
            .then(() =>
              this.props.notificationService.notify({
                message: 'Usługa utworzona pomyślnie',
                type: NotificationMessageType.SUCCESS,
              }),
            )
            .catch((error: any) => {
              console.error(error);
              this.props.notificationService.notify({
                message: 'Błąd przy tworzeniu usługi',
                type: NotificationMessageType.ERROR,
              });
            })
        }
        resetFields={['name', 'price']}
        fields={[
          {
            type: 'model',
            name: 'modelId',
            validator: Yup.string().required('required'),
          },
          {
            type: 'text',
            name: 'name',
            label: 'Nazwa usługi',
            placeholder: 'Wpisz nazwę usługi',
            validator: Yup.string()
              .min(1, 'too-short')
              .max(80, 'too-long')
              .required('required'),
          },
          {
            type: 'text',
            name: 'warehouseItemsIds',
            label: 'Id cześci magazynowych (Oddzielone przecinkiem)',
            placeholder: 'Podaj id części - kolejne podawaj po przecinku',
          },
          {
            type: 'text',
            name: 'priceNet',
            label: 'Cena NETTO naprawy (zł)',
            placeholder: 'Wpisz cenę',
            validator: Yup.string().required('required'),
          },
          {
            type: 'select',
            name: 'tax',
            label: 'Podatek',
            placeholder: 'Podatek',
            options: [
              { value: '23', content: '23%' },
              { value: '8', content: '8%' },
              { value: '5', content: '5%' },
            ],
          },
          {
            type: 'text',
            name: 'duration',
            label: 'Czas naprawy (min)',
            placeholder: 'Wpisz czas',
          },
          ...(this.shouldUseMargin()
            ? [
                {
                  type: 'text',
                  name: 'margin',
                  label: 'Marża (zł)',
                  placeholder: 'Wpisz marżę',
                  validator: Yup.string().required('required'),
                },
              ]
            : []),
          {
            type: 'text',
            name: 'systemMargin',
            label: 'Marża systemowa (%)',
            placeholder: 'Wpisz marżę',
          },
          ...this.state.partners.map(partner => ({
            type: 'text',
            name: `partner_${partner.id}`,
            label: partner.email,
            placeholder: 'Wpisz marżę',
          })),
        ]}
        render={({
          modelId,
          name,
          margin,
          priceNet,
          tax,
          duration,
          systemMargin,
          warehouseItemsIds,
          ...rest
        }: any) => (
          <Fragment>
            {modelId()}
            {name()}
            {warehouseItemsIds()}
            {this.shouldUseMargin() ? (
              <div className="d-flex">
                <div className="flex-grow-1">
                  {margin({ containerClass: 'm-0' })}
                </div>
              </div>
            ) : null}
            <div className="d-flex">
              <div className="flex-grow-1">
                {priceNet({ containerClass: 'm-0 mr-3' })}
              </div>
              <div className="flex-grow-1">
                {systemMargin({ containerClass: 'm-0' })}
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="flex-grow-1">
                {duration({ containerClass: 'm-0' })}
              </div>
            </div>
            <div className="d-flex">
              <div className="flex-grow-1">
                {tax({ containerClass: 'm-0' })}
              </div>
            </div>
            <div>
              <b>Marże partnerów</b>
              <div style={partnerMarginsStyle}>
                {this.state.loading ? (
                  <div>
                    <Spinner small /> Wczytywanie listy partnerów...
                  </div>
                ) : (
                  this.state.partners.map(partner =>
                    rest[`partner_${partner.id}`](),
                  )
                )}
              </div>
            </div>
          </Fragment>
        )}
        {...this.props.simpleFormProps}
        initialValues={{
          margin: this.shouldUseMargin() ? '' : 0,
          tax: '23',
        }}
      />
    );
  }

  private async fetchPartners() {
    this.setState({
      partners: await this.props.partnerService.listFetched(),
      loading: false,
    });
  }

  private shouldUseMargin() {
    return (
      this.props.applicationService.config!.isServiceMarginEnabled &&
      this.props.userService.user!.hasOneOfRoles([
        UserRoles.admin,
        UserRoles.partner,
      ])
    );
  }
}

export default wire(
  [
    'deviceTypeService',
    'deviceBrandService',
    'brandModelService',
    'serviceService',
    'partnerService',
    'drawerService',
    'notificationService',
    'applicationService',
    'userService',
  ],
  CreateService,
);
