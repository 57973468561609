import React, { PureComponent } from "react";
import { wire } from "react-hot-wire";

import SimpleList, {
  SimpleListProps
} from "../../../common/list/simple-list/SimpleList";

import { WarehouseItemService } from "../../../../services/warehouse/WarehouseItem.service";
import { WarehouseService } from "../../../../services/warehouse/Warehouse.service";
import { MDBBtn } from "mdbreact";
import Spinner from "../../../common/spinner/Spinner";
import { RealtimeCommunicationService } from "../../../../services/application/communication/RealtimeCommunication.service";
import { JobsStatus } from "../../../queue/status/JobsStatus";

export interface WarehouseItemsListState {
  loading: boolean;
  warehouseId: string;
  pageCount: number;
  processing: boolean;
  jobsStatues: any;
}

export class WarehouseItemsList extends PureComponent<
  {
    warehouseService: WarehouseService;
    warehouseItemService: WarehouseItemService;
    realtimeCommunicationService: RealtimeCommunicationService;
  },
  WarehouseItemsListState
> {
  public state: WarehouseItemsListState;
  private unsubscribeSyncStart: () => void;
  private unsubscribeSyncFinish: () => void;
  private unsubscribeSyncError: () => void;
  private unsubscribeSyncFailed: () => void;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      warehouseId: "",
      pageCount: 1,
      processing: true,
      jobsStatues: null
    };

    this.unsubscribeSyncStart = this.subscribeToSyncStart();
    this.unsubscribeSyncFinish = this.subscribeToSyncFinish();
    this.unsubscribeSyncError = this.subscribeToSyncError();
    this.unsubscribeSyncFailed = this.subscribeToSyncFailed();
  }

  componentDidMount(): void {
    this.fetchWarehouseId();
    this.fetchJobsStatues();
  }

  componentWillUnmount() {
    this.unsubscribeSyncStart();
    this.unsubscribeSyncFinish();
    this.unsubscribeSyncError();
    this.unsubscribeSyncFailed();
  }

  public render() {
    const props: SimpleListProps = {
      title: "Produkty w magazynie SystemDillcom",
      pageCount: this.state.pageCount,
      columns: [
        {
          label: "Nazwa",
          name: "name"
        },
        {
          label: "Ilość",
          name: "quantity"
        },
        {
          label: "Cena Brutto (szt.)",
          name: "priceGross"
        },
        {
          label: "Cena Netto (szt.)",
          name: "priceNet"
        },
        {
          label: "Zewnętrzne Id",
          name: "externalId"
        },
        {
          label: "Data dodania",
          name: "createdAt"
        },
        {
          label: "Data aktualizacji",
          name: "updatedAt"
        }
      ],
      fetchList: (filters, page) =>
        this.props.warehouseItemService
          .list(this.state.warehouseId, page)
          .then(response => {
            this.setState({
              pageCount: Math.ceil(response.total / response.limit)
            });

            return response.items.map(item => ({
              id: item.id,
              name: item.name,
              quantity: item.quantity,
              priceGross: item.priceGross,
              priceNet: item.priceNet,
              externalId: item.externalId,
              createdAt: item.createdAt.toLocaleString(),
              updatedAt: item.updatedAt.toLocaleString()
            }));
          })
    };

    return (
      <div>
        <div className="d-flex align-items-center">
          <MDBBtn
            color="primary"
            className="p-3"
            disabled={this.state.processing}
            style={{ width: "130px" }}
            onClick={() => this.synchronize()}
          >
            {this.state.processing ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Synchronizuj"
            )}
          </MDBBtn>
          <div>
            {this.state.jobsStatues ? (
              <JobsStatus statues={this.state.jobsStatues} />
            ) : (
              ""
            )}
          </div>
        </div>

        {this.state.loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <SimpleList {...props} />
        )}
      </div>
    );
  }

  private async fetchWarehouseId() {
    const warehouse = await this.props.warehouseService.getWarehouseByName(
      "SystemDillcom"
    );

    if (warehouse && warehouse.id) {
      this.setState({
        warehouseId: warehouse.id,
        loading: false
      });
    }
  }

  private async fetchJobsStatues() {
    const status = await this.props.warehouseService.getStatus();

    this.setState({
      jobsStatues: status,
      processing: !!status.active.length
    });
  }

  private async synchronize() {
    this.setState({
      processing: true
    });

    await this.props.warehouseService.synchronize();
  }

  private subscribeToSyncStart(): () => void {
    return this.props.realtimeCommunicationService.subscribe(
      "warehouse_integration_sync_start",
      () => this.fetchJobsStatues()
    );
  }

  private subscribeToSyncFinish(): () => void {
    return this.props.realtimeCommunicationService.subscribe(
      "warehouse_integration_sync_finish",
      () => this.onJobEnd()
    );
  }

  private subscribeToSyncError(): () => void {
    return this.props.realtimeCommunicationService.subscribe(
      "warehouse_integration_sync_error",
      () => this.onJobEnd()
    );
  }

  private subscribeToSyncFailed(): () => void {
    return this.props.realtimeCommunicationService.subscribe(
      "warehouse_integration_sync_failed",
      () => this.onJobEnd()
    );
  }

  private onJobEnd() {
    this.setState({
      processing: false
    });

    this.fetchJobsStatues();
    this.setState({
      loading: true
    });

    setTimeout(
      () =>
        this.setState({
          loading: false
        }),
      500
    );
  }
}

export default wire(
  ["warehouseService", "warehouseItemService", "realtimeCommunicationService"],
  WarehouseItemsList
);
