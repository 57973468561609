import React from 'react';
import { Formik, Form } from 'formik-latest';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';
import { useMutation } from 'react-query';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { TextField } from '../../repair/form/field/text-field/input';

import { SalesIdeaService } from '../../../services/sales/idea/sales-idea.service';

export interface AddSalesIdeaProps {
  salesIdeaService: SalesIdeaService;
  onIdeaAdded?: (value: FormValues) => void;
}

export interface FormValues {
  content: string;
}

const ideaContentMaxLength = 30000;

const RepairAddNoteSchema = Yup.object().shape({
  content: Yup.string().min(1).max(ideaContentMaxLength).required(),
});

export const AddSalesIdea = ({
  onIdeaAdded,
  salesIdeaService,
}: AddSalesIdeaProps) => {
  const { isLoading, error, mutateAsync } = useMutation<
    void,
    Error,
    FormValues
  >(
    ({ content }) =>
      salesIdeaService.addSalesIdea({
        idea: {
          content,
        },
      }),
    {
      onSuccess: (data, values) => onIdeaAdded?.(values),
    },
  );

  return (
    <Box>
      {error && (
        <Box>
          <Typography>Wystąpił błąd</Typography>
          <pre>
            {JSON.stringify(error.message ? error.message : error, null, 2)}
          </pre>
        </Box>
      )}
      <Formik
        initialValues={{
          content: '',
          sendToClient: false,
        }}
        validationSchema={RepairAddNoteSchema}
        onSubmit={(values: FormValues, formikHelpers) => {
          !isLoading &&
            mutateAsync(values).then(() => formikHelpers.resetForm());
        }}
      >
        {({ values }) => {
          return (
            <Form>
              <TextField
                name="content"
                label="Pomysł"
                placeholder="Treść pomysłu"
                helperText={`${values.content.length}/${ideaContentMaxLength}`}
                multiline
                autoFocus
                rows={4}
              />
              <Button color="primary" variant="contained" type="submit">
                {isLoading ? (
                  <CircularProgress size={24} style={{ color: grey[50] }} />
                ) : (
                  'Wyślij'
                )}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddSalesIdea;
