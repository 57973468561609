import { ApiService } from "../Api.service";
import { Warehouse, WarehouseDTO } from "../../models/warehouse/Warehouse";
import { QueueStatus, QueueStatusDTO } from "../../models/queue/QueueStatus";
import { QueueJob } from "../../models/queue/QueueJob";

export interface WarehouseServiceInterface {
  synchronize(): Promise<void>;
}

export class WarehouseService implements WarehouseServiceInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private _apiService: ApiService) {}

  public async getWarehouseByName(name: string): Promise<Warehouse> {
    return this._apiService
      .get<WarehouseDTO>(`/warehouse/name/${name}`)
      .then(warehouseData => new Warehouse(warehouseData));
  }

  public async synchronize(): Promise<void> {
    await this._apiService.post("/warehouse/integration/synchronize");
  }

  public async getStatus(): Promise<QueueStatus> {
    return this._apiService
      .get<QueueStatusDTO>("/warehouse/integration/status")
      .then(status => ({
        name: status.name,
        waiting: status.waiting.map(job => new QueueJob(job)),
        active: status.active.map(job => new QueueJob(job)),
        delayed: status.delayed.map(job => new QueueJob(job)),
        completed: status.completed.map(job => new QueueJob(job)),
        failed: status.failed.map(job => new QueueJob(job))
      }));
  }
}
