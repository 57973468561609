import { AccessTokenService } from "./AccessToken.service";
import { ApplicationService } from "./Application.service";
import { LogService } from "./Log.service";
import { NavigationService } from "./Navigation.service";
import { NotificationService } from "./Notification.service";
import { RealtimeCommunicationService } from "./communication/RealtimeCommunication.service";
import RealtimeCommunicationAdapters from "./communication/adapters";
import { ActionEventService } from "./ActionEvent.service";

export default {
  accessTokenService: {
    class: AccessTokenService,
    public: true,
    arguments: [{ $ref: "logService" }, "applicationAccessToken"]
  },
  applicationService: {
    class: ApplicationService,
    public: true,
    arguments: [{ $ref: "apiService" }, { $ref: "userService" }]
  },
  logService: {
    class: LogService,
    public: true
  },
  navigationService: {
    class: NavigationService,
    public: true
  },
  notificationService: {
    class: NotificationService,
    public: true
  },
  ...RealtimeCommunicationAdapters,
  realtimeCommunicationService: {
    class: RealtimeCommunicationService,
    public: true,
    arguments: [
      { $ref: "accessTokenService" },
      { $ref: "logService" },
      [{ $ref: "websocketAdapter" }]
    ]
  },
  actionEventService: {
    class: ActionEventService,
    public: true,
    arguments: [{ $ref: "apiService" }]
  }
};
