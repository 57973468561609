import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { wire } from 'react-hot-wire';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBIcon,
} from 'mdbreact';

const NavBar = ({ toggleSideNav, applicationService }) => (
  <MDBNavbar double expand="md" fixed="top" scrolling>
    <div>
      <NavLink
        to="/admin"
        onClick={toggleSideNav}
        style={{ fontSize: '1.4em' }}
      >
        <MDBIcon icon="bars" color="white" />
      </NavLink>
    </div>
    <div className="d-none d-md-inline ml-3">
      <h3 className="m-0">
        <Link to="/">
          <img
            src={applicationService.config.logo}
            alt={applicationService.config.name}
            style={{ height: '35px' }}
          />
        </Link>
      </h3>
    </div>
    <MDBNavbarNav right className="flex-row">
      {/*<MDBNavItem active>
        <MDBNavLink to="#!">
          <MDBIcon icon="envelope" className="d-inline-inline" />{" "}
          <div className="d-none d-md-inline">Contact</div>
        </MDBNavLink>
      </MDBNavItem>
      <MDBNavItem>
        <MDBNavLink to="#!">
          <MDBIcon far icon="comments" className="d-inline-inline" />{" "}
          <div className="d-none d-md-inline">Support</div>
        </MDBNavLink>
      </MDBNavItem>
      <MDBNavItem>
        <MDBNavLink to="#!">
          <MDBIcon icon="user" className="d-inline-inline" />{" "}
          <div className="d-none d-md-inline">Account</div>
        </MDBNavLink>
      </MDBNavItem>*/}
      <MDBNavItem>
        <MDBDropdown>
          <MDBDropdownToggle nav caret>
            <div className="d-none d-md-inline">Moje konto</div>
          </MDBDropdownToggle>
          <MDBDropdownMenu right>
            {/*<Link to="/admin" className="dropdown-item">*/}
            {/*  Ustawienia*/}
            {/*</Link>*/}
            <Link to="/auth/logout" className="dropdown-item">
              Wyloguj
            </Link>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavItem>
    </MDBNavbarNav>
  </MDBNavbar>
);

export default wire(['applicationService'], NavBar);
