import { UserProfile, UserProfileDTO } from './Profile/UserProfile';
import { PartnerProfile } from './Profile/PartnerProfile';
import { Location, LocationDTO } from '../location/Location';

export interface UserDTO {
  id: string;
  emailAddress: string;
  profiles: UserProfileDTO[];
  roles: string[];
  location: LocationDTO | null;
}

export interface EditUserDTO {
  id: string;
  emailAddress: string;
  nickname: string;
  firstname: string;
  surname: string;
}

export interface EditUserPasswordDTO {
  id: string;
  userPassword?: string;
  password?: string;
  confirmPassword?: string;
}

export enum UserRoles {
  su = 'SUPERADMIN_ROLE',
  admin = 'ADMIN_ROLE',
  partner = 'PARTNER_ROLE',
  customer = 'CUSTOMER_ROLE',
  business_customer = 'BUSINESS_CUSTOMER_ROLE',
  pickup_point_employee = 'PICKUP_POINT_EMPLOYEE_ROLE',
  employee = 'EMPLOYEE_ROLE',
  technician = 'TECHNICIAN_ROLE',
  receptionist = 'RECEPTIONIST_ROLE',
  carrier = 'CARRIER_ROLE',
}

export const UserDisplayRoles: { [key: string]: string } = {
  [UserRoles.su]: 'Super admin',
  [UserRoles.admin]: 'Admin',
  [UserRoles.partner]: 'Partner',
  [UserRoles.customer]: 'Klient',
  [UserRoles.business_customer]: 'Klient biznesowy',
  [UserRoles.technician]: 'Technik',
  [UserRoles.pickup_point_employee]: 'Pracownik punktu odbioru',
  [UserRoles.employee]: 'Pracownik',
  [UserRoles.receptionist]: 'Recepcjonista(-ka)',
  [UserRoles.carrier]: 'Przewoźnik',
};

export class User {
  private static ROLES = UserRoles;

  private _id: string;
  private _email: string;
  private _profiles: Array<UserProfile | PartnerProfile>;
  private _roles: string[];
  private _isSu: boolean;
  private _location: Location | null;

  constructor(user: UserDTO) {
    this._id = user.id;
    this._email = user.emailAddress;
    this._profiles = (user.profiles || []).map((profile: UserProfileDTO) =>
      this.loadProfile(profile),
    );
    this._roles = user.roles || [];
    this._isSu = this._roles.includes(UserRoles.su);
    this._location = user.location ? new Location(user.location) : null;
  }

  private loadProfile(profile: any): UserProfile {
    switch (profile.type) {
      case 'PartnerProfile':
        return new PartnerProfile(profile);
      case 'UserProfile':
      default:
        return new UserProfile(profile);
    }
  }

  public get id() {
    return this._id;
  }

  public get email() {
    return this._email;
  }

  public get profile(): UserProfile | PartnerProfile {
    return this._profiles[0];
  }

  public get type(): string {
    let type = UserDisplayRoles[this._roles[0]];

    if (UserDisplayRoles[this._roles[1]]) {
      type += ` (${UserDisplayRoles[this._roles[1]]})`;
    }

    return type;
  }

  public get role(): string {
    return this._roles[0];
  }

  public get roles(): string[] {
    return this._roles;
  }

  public get location(): Location | null {
    return this._location;
  }

  public get isSu(): boolean {
    return this._isSu;
  }

  public hasRole(role: UserRoles): boolean {
    if (role === UserRoles.admin && this.isSu) {
      return true;
    }

    return this._roles.includes(role);
  }

  public hasOneOfRoles(roles: UserRoles[]): boolean {
    return roles.some(role => this.hasRole(role));
  }

  public isAdmin(): boolean {
    return this.isSu || this._roles.includes(User.ROLES.admin);
  }

  public isPartner(): boolean {
    return this._roles.includes(User.ROLES.partner);
  }
}
