import { LogService } from "./Log.service";

export interface AccessTokenServiceInterface {
  setAccessToken(accessToken: string): void;
  hasAccessToken(): boolean;
}

export type AccessToken = string | null;

export class AccessTokenService implements AccessTokenServiceInterface {
  private static STORAGE_PROPERTY_NAME: string = "accessToken";

  private _storage: Storage;
  private _storagePropertyName: string;
  private _changeListeners: Array<(accessToken: string | null) => void>;
  private _accessToken: AccessToken;

  constructor(logService: LogService, storagePropertyName?: string) {
    this._storage = window.localStorage;
    this._storagePropertyName =
      storagePropertyName || AccessTokenService.STORAGE_PROPERTY_NAME;
    this._changeListeners = [];

    this._accessToken = this._storage.getItem(this._storagePropertyName);

    if (!this._accessToken) {
      logService.warn(
        `localStorage does not contain "${this._storagePropertyName}" property`
      );
    }
  }

  public get accessToken(): AccessToken {
    return this._accessToken;
  }

  public setAccessToken(accessToken: string | null): void {
    if (accessToken === this._accessToken) {
      return;
    }

    if (!accessToken) {
      this._storage.removeItem(this._storagePropertyName);
    } else {
      this._storage.setItem(this._storagePropertyName, accessToken);
    }

    this._accessToken = accessToken;
    this._changeListeners.forEach(changeListener =>
      changeListener(accessToken)
    );
  }

  public hasAccessToken(): boolean {
    return !!this._accessToken;
  }

  public subscribe(listener: (accessToken: string | null) => void): void {
    this._changeListeners.push(listener);
  }
}
