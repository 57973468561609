import React, { useEffect, useState, Fragment } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { wire } from 'react-hot-wire';

import { LocationService } from '../../../../services/location/Location.service';
import { UserService } from '../../../../services/user/User.service';

import { UserRoles } from '../../../../models/user/User';

import Spinner from '../../../common/spinner/Spinner';
import NewPagination, {
  NewPaginationProps,
} from '../../../common/pagination/new-pagination';

export interface UserListTableHeaderProps extends NewPaginationProps {
  onSearchValueChange: (event: string) => void;
  onTypeValueChange: (event: UserRoles | undefined) => void;
  onLocationChange: (event: string | undefined) => void;
  onCreateClick: () => void;
  locationService: LocationService;
  userService: UserService;
}

const useStyles = makeStyles({
  root: {
    margin: '10px',
    height: '70px',
    width: '100%',
    '& .MuiInputLabel-formControl': {
      backgroundColor: 'transparent',
    },
  },
  textInput: {
    height: '70px',
  },
  fullHeight: {
    height: '100%',
  },
});

export const UserListTableHeader = ({
  onSearchValueChange,
  onTypeValueChange,
  onLocationChange,
  onCreateClick,
  page,
  pageCount,
  setPage,
  locationService,
  userService,
}: UserListTableHeaderProps) => {
  const [locations, setLocations] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [locationsLoading, setLocationsLoading] = useState<boolean>(true);

  const classes = useStyles();

  useEffect(() => {
    locationService
      .simpleList()
      .then(list => {
        setLocations(list.items);
        setLocationsLoading(false);
      })
      .catch(console.error);
  }, [userService, locationService]);

  return (
    <Grid
      container
      className={classes.root}
      spacing={2}
      alignItems="flex-start"
    >
      <Grid item container alignItems="center" xs={1}>
        <Button
          color="primary"
          variant="contained"
          className="text-uppercase"
          onClick={onCreateClick}
        >
          Dodaj użytkownika
        </Button>
      </Grid>
      <Grid item xs={3}>
        <TextField
          variant="outlined"
          placeholder="Wyszukaj (minimum 3 znaki)"
          helperText="imię, nazwisko, numer telefonu, email"
          onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
            onSearchValueChange(event.target.value)
          }
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl variant="outlined">
          <InputLabel>Typ/Rola</InputLabel>
          <Select
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              onTypeValueChange(event.target.value as UserRoles | undefined)
            }
            label="Status"
          >
            <MenuItem value={undefined}>
              <em>-</em>
            </MenuItem>
            {Object.entries({
              ADMIN_ROLE: 'Administrator',
              EMPLOYEE_ROLE: 'Pracownik',
              TECHNICIAN_ROLE: 'Pracownik: Technik',
              RECEPTIONIST_ROLE: 'Pracownik: Recepcionista/ka',
              CARRIER_ROLE: 'Pracownik: Kurier',
              PARTNER_ROLE: 'Partner',
              BUSINESS_CUSTOMER_ROLE: 'Klient Biznesowy',
              CUSTOMER_ROLE: 'Klient',
            }).map(([key, value]) => (
              <MenuItem value={key}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Fragment>
        <Grid item xs={3}>
          {locationsLoading ? (
            <Spinner small />
          ) : (
            <FormControl variant="outlined">
              <InputLabel>Punkt</InputLabel>
              <Select
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  onLocationChange(event.target.value as string | undefined)
                }
                label="Punkt"
              >
                <MenuItem value={undefined}>
                  <em>-</em>
                </MenuItem>
                {locations.map(location => (
                  <MenuItem value={location.id}>{location.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Fragment>

      <Grid
        item
        container
        xs
        justify="flex-end"
        alignItems="center"
        className={classes.fullHeight}
      >
        <NewPagination page={page} pageCount={pageCount} setPage={setPage} />
      </Grid>
    </Grid>
  );
};

export default wire(['locationService', 'userService'], UserListTableHeader);
