import React from 'react';
import { Field, FieldProps } from 'formik-latest';

import MuiTextField from '@material-ui/core/TextField';

export type InputProps = {
  name: string;
  label: string;
  placeholder: string;
  multiline?: boolean;
  rows?: number;
  autoFocus?: boolean;
  helperText?: string;
};

export function TextField<T>(props: InputProps) {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps<T>) => (
        <MuiTextField
          margin="normal"
          variant="outlined"
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
          fullWidth
          {...field}
          {...props}
        />
      )}
    </Field>
  );
}
