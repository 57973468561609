import { ApiService } from "../Api.service";
import {
  ActionEvent,
  ActionEventDTO
} from "../../models/application/ActionEvent";
import { ListResponse } from "../../models/common/ListResponse";

export class ActionEventService {
  private _apiService: ApiService;

  constructor(apiService: ApiService) {
    this._apiService = apiService;
  }

  public async list(page: number): Promise<ListResponse<ActionEvent>> {
    return await this._apiService
      .get<ListResponse<ActionEventDTO>>(
        `/application/action-event/list?limit=100&offset=${(page - 1) * 100}`
      )
      .then(response => ({
        ...response,
        items: response.items.map(event => new ActionEvent(event))
      }));
  }
}
