export interface WarehouseDTO {
  id: string;
  name: string;
}

export class Warehouse {
  private _id: string;
  private _name: string;

  constructor(item: WarehouseDTO) {
    this._id = item.id;
    this._name = item.name;
  }

  public get id(): string {
    return this._id;
  }

  public get name() {
    return this._name;
  }
}
