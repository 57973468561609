import React, { Fragment, PureComponent } from 'react';
import { wire } from 'react-hot-wire';

import { UserService } from '../../../../services/user/User.service';
import {
  NotificationMessageType,
  NotificationService,
} from '../../../../services/application/Notification.service';
import SimpleForm, {
  SimpleFormProps,
} from '../../../common/form/simple-form/SimpleForm';
import { CourierService } from '../../../../services/courier/Courier.service';

import { Weekday } from '../../../../models/common/Weekday';
import { CourierWorkingHoursDTO } from '../../../../models/courier/CourierConfiguration';

export interface UpdateSettingsFormInterface {
  [key: string]: string | boolean | undefined;
}

export interface EditCourierSettingsState {
  initialValues: {
    [key: string]: string | number | boolean;
  };
}

export class EditCourierSettings extends PureComponent<
  {
    userService: UserService;
    courierService: CourierService;
    notificationService: NotificationService;
    simpleFormProps: SimpleFormProps;
  },
  EditCourierSettingsState
> {
  public state: EditCourierSettingsState;

  constructor(props: any) {
    super(props);

    this.state = {
      initialValues: this.getCourierWorkHoursFieldsData(
        props.simpleFormProps.initialValues.workingHours,
      ),
    };
  }

  public render() {
    return (
      <SimpleForm
        title="Godziny pracy kuriera"
        submitButton="Zapisz zmiany"
        submittingButton="Trwa zapisywanie zmian..."
        onSubmit={(values: UpdateSettingsFormInterface) =>
          this.props.courierService
            .setWorkingHoursConfiguration(
              Object.values(Weekday)
                .map(weekday => ({
                  weekday: weekday,
                  start: Number(values[`${weekday}_start`]),
                  end: Number(values[`${weekday}_end`]),
                }))
                .filter(weekday => weekday.end > 0),
            )
            .then(() =>
              this.props.notificationService.notify({
                message: 'Ustawienia zaktualizowane',
                type: NotificationMessageType.SUCCESS,
              }),
            )
            .catch((error: any) => {
              console.error(error);
              this.props.notificationService.notify({
                message: 'Błąd przy aktualizacji ustawień',
                type: NotificationMessageType.ERROR,
              });
            })
        }
        fields={this.getCourierWorkHoursFields()}
        render={(fields: any) => (
          <div>
            <Fragment>
              {[
                { label: 'Poniedziałek', weekday: Weekday.MONDAY },
                { label: 'Wtorek', weekday: Weekday.TUESDAY },
                { label: 'Środa', weekday: Weekday.WEDNESDAY },
                { label: 'Czwartek', weekday: Weekday.THURSDAY },
                { label: 'Piątek', weekday: Weekday.FRIDAY },
                { label: 'Sobota', weekday: Weekday.SATURDAY },
                { label: 'Niedziela', weekday: Weekday.SUNDAY },
              ].map(day => (
                <div className="d-flex">
                  <div className="w-25 mr-3" style={{ marginTop: '34px' }}>
                    {day.label}:
                  </div>

                  <div className="w-25 mr-3">
                    {fields[`${day.weekday}_start`]({
                      containerClass: 'm-0 ',
                    })}
                  </div>
                  <div className="mr-3" style={{ marginTop: '34px' }}>
                    -
                  </div>
                  <div className="w-25">
                    {fields[`${day.weekday}_end`]({ containerClass: 'm-0' })}
                  </div>
                </div>
              ))}
            </Fragment>
          </div>
        )}
        {...{
          ...this.props.simpleFormProps,
          initialValues: {
            ...this.props.simpleFormProps.initialValues,
            ...this.state.initialValues,
          },
        }}
      />
    );
  }

  private getCourierWorkHoursFieldsData(
    workingHours: CourierWorkingHoursDTO[],
  ) {
    return Object.values(Weekday).reduce((fieldsData, weekday) => {
      const weekdayData = workingHours.find(data => data.weekday === weekday);

      if (weekdayData) {
        return {
          ...fieldsData,
          [`${weekday}_start`]: weekdayData.start,
          [`${weekday}_end`]: weekdayData.end,
        };
      }

      return fieldsData;
    }, {});
  }

  private getCourierWorkHoursFields() {
    const optionsStart = Array.from({ length: 23 }).map((v, k) => ({
      value: k,
      content: `${k}`,
    }));
    const optionsEnd = Array.from({ length: 23 }).map((v, k) => ({
      value: k + 1,
      content: `${k + 1}`,
    }));

    return Object.values(Weekday)
      .map(weekday => [
        {
          type: 'select',
          label: 'Start',
          placeholder: 'Start',
          name: `${weekday}_start`,
          options: [{ value: undefined, content: '-' }, ...optionsStart],
        },
        {
          type: 'select',
          label: 'Koniec',
          placeholder: 'Koniec',
          name: `${weekday}_end`,
          options: [{ value: undefined, content: '-' }, ...optionsEnd],
        },
      ])
      .flat();
  }
}
export default wire(
  ['userService', 'courierService', 'notificationService'],
  EditCourierSettings,
);
