import React, { PureComponent } from 'react';
import { Formik, FormikActions } from 'formik';
import * as Yup from 'yup';
import { each, includes } from 'lodash';

import SimpleForm from './SimpleForm.template';
import { MDBModalProps } from '../../../../definitions/mdbreact';
import { Field } from './field';

export interface SimpleFormProps {
  title?: string;
  submitButton?: string;
  submittingButton?: string;
  cancelButton?: string;
  fields?: Array<Field>;
  modalProps?: MDBModalProps;
  onSubmit?: Function;
  onResolved?: Function;
  close?: Function;
  initialValues?: any;
  render?: Function;
  resetFields?: string[];
}

type ValidationSchema = {
  [key: string]: Yup.Schema<string | undefined>;
};

export class SimpleFormComponent extends PureComponent<SimpleFormProps> {
  public render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        validationSchema={this.getValidationSchema()}
        onSubmit={(values, actions) => this.onSubmit(values, actions)}
        render={(props) => (
          <SimpleForm
            toggle={() => this.close()}
            props={this.props}
            formikProps={props}
            modalProps={{ ...this.props.modalProps }}
          />
        )}
      />
    );
  }

  private getValidationSchema() {
    const validationSchema: ValidationSchema = {};

    (this.props.fields || []).forEach((field: Field) => {
      if (field.validator) {
        validationSchema[field.name] = field.validator;
      }
    });

    return Yup.object().shape(validationSchema);
  }

  private onSubmit(
    values: { [key: string]: string },
    actions: FormikActions<{ [key: string]: string }>,
  ) {
    const { onSubmit, onResolved, resetFields } = this.props;
    const newValues: { [key: string]: string } = {};

    setTimeout(() => {
      if (onSubmit) {
        onSubmit(values).then(() => {
          if (onResolved) {
            onResolved();
          }
        });
      }

      if (values.recreate) {
        each(values, (value, name) => {
          newValues[name] = includes(resetFields, name) ? '' : value;
        });

        actions.resetForm(newValues);
      } else {
        this.close();
      }
    }, 500);
  }

  private close(): void {
    if (this.props.close) {
      this.props.close();
    }
  }
}

export default SimpleFormComponent;
