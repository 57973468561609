import { ApiService } from "../Api.service";
import { AccessTokenService } from "../application/AccessToken.service";

export interface AuthServiceInterface {
  login({
    email,
    password
  }: {
    email: string;
    password: string;
  }): Promise<void>;
  logout(): Promise<void>;
}

export class AuthService implements AuthServiceInterface {
  private _apiService: ApiService;
  private _accessTokenService: AccessTokenService;

  constructor(apiService: ApiService, accessTokenService: AccessTokenService) {
    this._apiService = apiService;
    this._accessTokenService = accessTokenService;
  }

  public async login({
    email,
    password
  }: {
    email: string;
    password: string;
  }): Promise<void> {
    return await this._apiService
      .post<{ token: string }>("/panel/login", {
        emailAddress: email,
        password
      })
      .then(({ token }: { token: string }) => {
        this._accessTokenService.setAccessToken(token);
        window.location.href = "/";
      });
  }

  public logout(): Promise<void> {
    return this._apiService.post("/auth/logout").then(() => {
      this._accessTokenService.setAccessToken(null);
      window.location.href = "/auth/login";
    });
  }

  public checkResetPasswordHash(
    hash: string
  ): Promise<{ isActive: boolean; emailAddress: string } | boolean> {
    return this._apiService
      .get<{ isActive: boolean; emailAddress: string }>(
        `/auth/user/password/reset/check/${hash}`
      )
      .then((results: { isActive: boolean; emailAddress: string }) => results)
      .catch(err => {
        console.error(err);

        return false;
      });
  }

  public async resetPassword({
    hash,
    newPassword,
    confirmNewPassword
  }: {
    hash: string;
    newPassword: string;
    confirmNewPassword: string;
  }): Promise<boolean> {
    return await this._apiService
      .post<{ token: string }>(`/auth/user/password/reset/${hash}`, {
        newPassword,
        confirmNewPassword
      })
      .then(() => true)
      .catch(err => {
        console.error(err);

        return false;
      });
  }

  public async requestPasswordReset({
    email
  }: {
    email: string;
  }): Promise<boolean> {
    return await this._apiService
      .post("/auth/user/password/reset", {
        emailAddress: email
      })
      .then(() => true)
      .catch(err => {
        console.error(err);

        return false;
      });
  }
}
