import React, { Fragment } from "react";

import SideNav from "./section/SideNav";
import NavBar from "./section/NavBar";
import Main from "./section/Main";
import Drawer from "../drawer/Drawer";

export default ({ isOpenSideNav, toggleSideNav, children }) => (
  <Fragment>
    <SideNav isOpenSideNav={isOpenSideNav} toggleSideNav={toggleSideNav} />
    <NavBar toggleSideNav={toggleSideNav} />
    <Main>{children}</Main>
    <Drawer />
  </Fragment>
);
