import React from 'react';
import { useQuery } from 'react-query';
import { Box, CircularProgress, Paper } from '@material-ui/core';

import { SalesIdea } from '../../../models/sales/idea/sales-idea';
import { SalesIdeaService } from '../../../services/sales/idea/sales-idea.service';

export interface SalesIdeasListProps {
  salesIdeaService: SalesIdeaService;
}

export const SalesIdeasList = ({ salesIdeaService }: SalesIdeasListProps) => {
  const { isLoading, error, data } = useQuery<SalesIdea[], Error>(
    'sales-ideas-list',
    () =>
      salesIdeaService
        .getSalesIdeas()
        .then((list) =>
          list.sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf()),
        ),
    { cacheTime: 5 * 1000 },
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <div>
        <p>
          <b>Wystąpił błąd</b>
        </p>
        <pre>
          {JSON.stringify(error.message ? error.message : error, null, 2)}
        </pre>
      </div>
    );
  }

  if (!data || !data.length) {
    return <div>Brak pomysłów :(</div>;
  }

  return (
    <Box display="flex" flexDirection="column">
      {data.map((idea) => {
        return (
          <Box
            key={idea.id}
            component={Paper}
            p={1}
            my={1}
            display="flex"
            flexDirection="column"
          >
            <Box style={{ whiteSpace: 'pre-wrap' }}>{idea.content}</Box>
            <Box alignSelf="flex-end">
              <strong>{idea.user.profile.viewName}</strong>{' '}
              {idea.createdAt.toLocaleString()}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SalesIdeasList;
