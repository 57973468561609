import React, { Fragment, useState } from 'react';
import { Field, FieldProps } from 'formik-latest';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';

import MuiAutocomplete from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

export type AutocompleteOption = {
  value: string;
  content: string;
};

export type AutocompleteProps = {
  name: string;
  label?: string;
  multiple?: boolean;
  fetch: (value: string) => Promise<AutocompleteOption[]>;
};

export function Autocomplete({
  name,
  fetch,
  multiple,
  ...props
}: AutocompleteProps) {
  const [value, setValue] = useState<string>('');
  const onInputChange = debounce((_event, value) => setValue(value), 300);
  const { isLoading, data: options = [] } = useQuery<AutocompleteOption[]>(
    [name, value],
    () => fetch(value),
  );

  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <MuiAutocomplete
          multiple={multiple}
          loading={isLoading}
          defaultValue={fieldProps.field.value}
          onChange={(_event, value) =>
            fieldProps.form.setFieldValue(name, value)
          }
          onInputChange={onInputChange}
          options={options}
          noOptionsText="Brak wyników"
          getOptionSelected={(option, selectedOption) =>
            option.value === selectedOption.value
          }
          getOptionLabel={(option) => option.content}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.label}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      )}
    </Field>
  );
}
