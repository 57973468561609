import { DeviceBrand, DeviceBrandDTO } from '../DeviceBrand';
import { DeviceType, DeviceTypeDTO } from '../../DeviceType';
import { MaybeNull } from '../../../../definitions/MaybeNull';
import {
  CreatePartnerMarginDTO,
  PartnerMarginDTO,
} from '../../../partner/PartnerMargin';

export interface BrandModelDTO {
  id: string;
  name: string;
  brand: DeviceBrandDTO;
  type: DeviceTypeDTO;
  systemMargin: MaybeNull<number>;
  partnerMargins: MaybeNull<PartnerMarginDTO[]>;
  shopItemsIds: string[];
}

export interface CreateBrandModelDTO {
  name: string;
  brandId: string;
  typeId: string;
  systemMargin: MaybeNull<number>;
  partnerMargins: CreatePartnerMarginDTO[];
  shopItemsIds: string;
}

export interface UpdateBrandModelDTO extends CreateBrandModelDTO {
  id: string;
}

export class BrandModel {
  private _id: string;
  private _name: string;
  private _brand: DeviceBrand;
  private _type: MaybeNull<DeviceType>;
  private _systemMargin: MaybeNull<number>;
  private _partnerMargins: MaybeNull<PartnerMarginDTO[]>;
  private _shopItemsIds: string[];

  constructor(model: BrandModelDTO) {
    this._id = model.id;
    this._name = model.name;
    this._brand = new DeviceBrand(model.brand);
    this._type = model.type && new DeviceType(model.type);
    this._systemMargin = model.systemMargin;
    this._partnerMargins = model.partnerMargins;
    this._shopItemsIds = model.shopItemsIds;
  }

  public get id(): string {
    return this._id;
  }

  public get shopItemsIds(): string[] {
    return this._shopItemsIds;
  }

  public get name(): string {
    return this._name;
  }

  public get brand(): DeviceBrand {
    return this._brand;
  }

  public get type(): MaybeNull<DeviceType> {
    return this._type;
  }

  public get systemMargin(): MaybeNull<number> {
    return this._systemMargin;
  }

  public get partnerMargins(): MaybeNull<PartnerMarginDTO[]> {
    return this._partnerMargins;
  }

  public set id(id: string) {
    this._id = id;
  }

  public set name(name: string) {
    this._name = name;
  }

  public set brand(brand: DeviceBrand) {
    this._brand = brand;
  }

  public set type(type: MaybeNull<DeviceType>) {
    this._type = type;
  }

  public set systemMargin(systemMargin: MaybeNull<number>) {
    this._systemMargin = systemMargin;
  }

  public set partnerMargins(partnerMargins: MaybeNull<PartnerMarginDTO[]>) {
    this._partnerMargins = partnerMargins;
  }
}
